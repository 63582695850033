import { CanActivate, Router } from '@angular/router';

import { CommonService } from './commonservice.service';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
  })
  export class HomeGuardService implements CanActivate {
	constructor(
	  public auth: CommonService,
	  public router: Router
	) {}
	canActivate(): boolean {
	  if (!this.auth.getToken()) {
		this.router.navigateByUrl('/home');
		return true;
	  }
	  this.router.navigateByUrl('/dashboard');
	  return false;
	}
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: CommonService,
    public router: Router
  ) {}
  canActivate(): boolean {
    if (!this.auth.getToken()) {
      this.router.navigateByUrl('/auth/login');
      return false;
    }
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserAuthActivityService implements CanActivate {
  constructor(
    public auth: CommonService,
    public router: Router
  ) {}
  getCookies(name: string): any {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        const cookieValue = c.substring(nameEQ.length, c.length);
        try {
          // Try to parse the cookie value, return null if parsing fails
          return cookieValue ? JSON.parse(cookieValue) : null;
        } catch (e) {
          console.error('Error parsing cookie:', e);
          return null;
        }
      }
    }
    return null;
  }

  canActivate(): boolean {
    // console.log("this.getCookies('expiryTime')>>>>",this.getCookies('expiryTime'));
    const expiryTime = this.getCookies('expiryTime') || '';
    const currentTime = new Date().getTime();
    // console.log("currentTime>>>>>Auth Service", currentTime);
    // console.log("expiryTime>>>>>Auth Service", expiryTime);
    if (!expiryTime) {
      // If no expiryTime is found in cookies, auto logout
      this.onLogout(expiryTime);
      return false;
    }

    if (currentTime > Number(expiryTime)) {
      // If current time is greater than expiry time, logout
      this.onLogout(expiryTime);
      return false;
    } else {
      // Set current time and new expiry time
      const newExpiryTime = currentTime + 3 * 60 * 60 * 1000; // 3 hours expiry
      this.setCookie('loginTime', currentTime.toString(), 1);
      this.setCookie('expiryTime', newExpiryTime.toString(), 1);
    }
    return true;
  }
  clearCookie(name: string,time:string) {
    document.cookie = `${name}=; expires=${time}; path=/;`;
  }
  onLogout(expiryTime:any) {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.clearCookie('LastUserActivity',expiryTime);
    this.clearCookie('loginTime',expiryTime);
    this.clearCookie('expiryTime',expiryTime);
    this.router.navigateByUrl('/auth/login')
  }
  setCookie(name: string, value: any, days: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    const serializedValue = JSON.stringify(value);
    document.cookie = `${name}=${serializedValue || ''}${expires}; path=/;`;
  }
}
