import { ActivatedRoute, Router } from '@angular/router';
import { SharedserviceService } from 'src/app/services';
import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiRequestService } from 'src/app/services/api.service';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { VehicleTransportService } from 'src/app/services/vehicletransportservice.service';

declare var $: any;

@Component({
  selector: 'app-vehicle-transportation',
  templateUrl: './vehicle-transportation.component.html',
  styleUrls: ['./vehicle-transportation.component.scss'],
})
export class VehicleTransportationComponent implements OnInit {

  searchFlagReset: boolean = false;
  fitFareruleListData:any;
  vehicleTransportData: any;
  public setVehicleTransportationForm: FormGroup;submitted = false;
  public fitDefaultFareRuleForm: FormGroup;
  isAirlinesList:any=[
    {'name':"Select ALL","id":0,"code":""},
    {'name':"Air India","id":15,"code":"AI"},
    {'name':"AirAsia India","id":25,"code":"I5"},
    {'name':"GoFirst","id":72,"code":"G8"},
    {'name':"IndiGo","id":80,"code":"6E"},
    {'name':"SpiceJet","id":124,"code":"SG"},
  ]
  userdata: any;
  isMarkupList: any;
  isSelectGroup: any;
  isGroupListData: any;
  isNameListId: any;
  isNameId: any;
  isApiPartnerId: any;
  isNameData: any;
  isSelectStatus: number;
  isDeleteId: any;
  isGroupShow: boolean=false;
  userList: boolean=false;
  acc_type: any;
  acc_id: any;
  selectedFile: File = null;
  imagename: any;
  imageurl: any;
  constructor(
    public vehicleService:VehicleTransportService,
    private fb: FormBuilder,
    public sharedService: SharedserviceService,public apiService: ApiRequestService,public manageData:ManagedataService,
    public router: Router,private toastr: ToastrManager,private activatedRoute: ActivatedRoute)
    {
      this.userdata = JSON.parse(localStorage.getItem("user"));
      this.apiService.onGroupListResponse();
    }
  ngOnInit() {
    this.GetVehicleTransportationData();

    this.setVehicleTransportationForm = this.fb.group({
      vehicle_name: [''],
      pax_capacity: [''],
      image: [''],
    })

  }

  get validate() { return this.setVehicleTransportationForm.controls; }


  GetVehicleTransportationData(){
    var requestData = {
      status : "1"
    }
    this.vehicleService.post('get_vehicle_types',requestData).subscribe((res:any) => {
      if (res.replyCode == 'success') {
        this.vehicleTransportData = res.data.results.sort(function (a: any, b: any) { return b.id - a.id });
        this.imageurl = res.data.siteUrl;
      }else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  onAddNewVehicleModal(){
    $('#addNewVehicleModal').modal('show');
  }

  closeVehicleModel(){
    $('#addNewVehicleModal').modal('hide');
    this.setVehicleTransportationForm.reset();
  }

  addVehicleData(){
    this.submitted = true;
    if(this.setVehicleTransportationForm.invalid){
      return;
    }else{
      var requestData ={
        vehicle_name : this.setVehicleTransportationForm.value.vehicle_name,
        pax_capacity : this.setVehicleTransportationForm.value.pax_capacity,
        attachment : this.imagename,
      }
      this.vehicleService.postRequest('create_vehicle_type', requestData).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          this.setVehicleTransportationForm.reset();
          this.submitted = false;
          this.toastr.successToastr(res.replyMsg, 'Success');
          this.GetVehicleTransportationData();
          $('#addNewVehicleModal').modal('hide');
        } else {
          this.submitted = false;
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, error => {
        this.submitted = false;
        this.toastr.errorToastr("Oops! Something went wrong!");
      });
    }

  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    var image = this.selectedFile.name;
    const attachment = new FormData();
    attachment.append('attachment', this.selectedFile, this.selectedFile.name);

    this.vehicleService.postRequest('upload_file', attachment).subscribe((res: any) => {
      if (res.status == true) {
         this.imagename = res.name;
      } else {
        this.submitted = false;
        this.toastr.errorToastr(res.msg, 'Error');
      }
    }, error => {
      this.submitted = false;
      this.toastr.errorToastr("Oops! Something went wrong!");
    });
  }

  VehicleStatus_onOff(event){
    if(event == true){

    }else{

    }

  }



//---------Edit
  closeEditVehicleModel(){
    $('#editVehicleModal').modal('hide');
  }

  onEditVehicle(data){
    $('#editVehicleModal').modal('show');
  }

  onEditVehicleForm(){

  }
//---------end


// -------Delete Vehicle
  onDeleteVehicle(data){
    $('#deleteRequestModal').modal('show');
  }

  onCloseDeleteModal(){
    $('#deleteRequestModal').modal('hide');
  }

  onDeleteVehicledata(){
    var requestData = {
      'id' : this.acc_id,
    }
    this.sharedService.postRequest('v1/user_smo/delete_user_smo_settings', requestData).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Success');
        this.GetVehicleTransportationData();
        $('#deleteRequestModal').modal('hide');
      } else {
        this.submitted = false;
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, error => {
      this.submitted = false;
      this.toastr.errorToastr("Oops! Something went wrong!");
    });
  }

// ------------End



}

