import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedserviceService, CommonService } from 'src/app/services';
import { DatePipe} from '@angular/common';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DateAdapter } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {Location} from '@angular/common';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-book-summery',
  templateUrl: './book-summery.component.html',
  styleUrls: ['./book-summery.component.scss'],
  providers: [DatePipe, NgxUiLoaderService]
})
export class BookSummeryComponent implements OnInit {
  public environment = environment;
  public dm = 'in';
  public cancelTicket : FormGroup;
  public CancelTicketForm : FormGroup;
  public AddNewInfantForm : FormGroup;
  isAuto:boolean=false;
  chng_pnr_bookingid:any;
  chng_pnr_pnr:any;

  dataCount:any;
  checkTraveler: any = [];
  date:any = new Date();
  passport_expire_date:any='';
  endatdate: any = new Date((new Date().getTime() - 86400));
  maxlastDate:Date|any;
  minlastDate:Date|any;
  form_data: any;

  passport_issue_date:any;
  nationality:any;
  country_of_issue:any;
  wheelchair:any;
  meal:any;
  edit_amount:any;
  traveller_age:any;

  id:any='';
    gender: any='';
    first_name:any='';
    middle_name:any='';
    last_name: any='';
    dob:any='';
    passport_no:any='';
    editt:any;
    clickedindex:any;

  i = 1;
  userList: any = {};
  sort_departure_date: any = "desc";
  sort_reference_id: any = "desc";
  sort_booking_date: any = "desc";
  sort_seller_name: any = "desc";
  sort_buyer_name: any = "desc";
  sort_flight_sector: any = "desc";
  sort_type: any = "desc";
  sort_total_book_seats: any = "desc";
  sort_total_amount: any = "desc";
  nodatafound: boolean = false;
  cancelticket: any;
  return_d_date: any;
  rreturn_d_date: any;
  delBox: boolean = false;
  sortedBook: any[];
  ccData: any;
  sectordata: any;
  totalpage: any;

  profileImg: any;

  imgPath: any;
  bookData: any;
  userdata: any;
  flightData: any;
  returnFlight: any; flt_bokg_dtl: any;

  loadData: any; data: any; keys: any; public page: number = 1; start = 0; msg: any;
  public createForm: FormGroup;
  submitted = false; btnsubmitted: boolean = false;
  editFormFlag: boolean = false; uploadedImagesData: any;
  selectedFile: File = null; status: any; EditFormData: any; FormId: any;
  bodydata: any = {}; TotalCount: any; PackageStatus: any;
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
  searchFlagReset: boolean = false;
  EditValuSummery: boolean = false;

  bannerTrue: any;
  imageTrue: any;
  bgBannerPath: any;
  myDate = new Date();
  p: number = 1;
  todate: any = "";
  formdate: any = "";
  sector: any = "";
  type: any = "";
  filterstatus: any = "";
  Id: any;
  imgUrl: any;
  userid: any;
  element: any = {};
  summary: any;
  fd_basefare: any;
  fd_feetaxes: any;
  fd_discount: any;
  fd_amount: any;
  booking_id: any;
  contactDtl: boolean;
  bgBner:boolean= true;
  headBner:boolean= true;
  themeClr1:boolean= true;
  themeClr2:boolean= false;
  themeClr3:boolean= false;
  sellerDtl: boolean = false;
  summaryDtl: boolean = false;

  myColorChange: any;
  changeColorAirline: any;
  imgpathAgency: any;
  edittravelBox: boolean =false;
  edittravelindex:any;

  totalAddSeat: any;
  change_p_fromdate: any;
  delete_t_fromdate: any;
  delete_ticket_detail:any;
  checkedRefundAmount = true ;
  checkedCancelSeat:boolean = true ;
  flightnoandtime:any;
  total_amount: any;
  amount: number;
  avg_pricePerTicket: number;
  refundAmount: number;
  refund_c:boolean=false;
  show_msg:boolean=false;

  ticketdetailindexdata:any={};
  dateclicked:boolean=false

  baseUrl: any;
  aval_seat: any;
  new_booking_dateselect: boolean = false;
  myFilter: any;
  myFilter1: any;
  myFilter2: any;
  myFilter3: any;
  myFilter4: any;

  flight_inventory_date_wise: any;
  flight_inventory_date_wisedata: any;
  flight_inventory_date_details: any;
  flight_inventory_date_details_infant: any;
  editticketform1: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  delete_ticket_form: FormGroup;
  flight_booking_id: any;
  flight_id: any;
  delFlight_id: any;
  deleteconfirm: boolean =false;
  flightbooking_id: any;
  pdfTable: any;
  totalPassenger: any;
  barcodes: any;
  barcode_split: any;
  arrivaldate: any;
  changeType: any;
  isValidate: boolean=false;
  constructor(private _location: Location,
    private ngxLoader: NgxUiLoaderService,
    private dateAdapter: DateAdapter<Date>,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public router: Router,
    public commonService: CommonService,
    public activatedRoute: ActivatedRoute,
    private toastr: ToastrManager) {
    this.activatedRoute.params.forEach((urlParams) => {
      this.Id = urlParams['id'];
      this.userid = this.Id;
    })
    this.dateAdapter.setLocale('en-GB');
    this.sortedBook = this.ccData;
    this.userdata = JSON.parse(localStorage.getItem("user"));
    this.booking_id = JSON.parse(sessionStorage.getItem("booking_id"));
    this.delFlight_id = JSON.parse(localStorage.getItem("inventory_flight_id"));

    if(JSON.parse(localStorage.getItem("ContactShow")))
    {  this.contactDtl = JSON.parse(localStorage.getItem("ContactShow")); }else{  this.contactDtl = false  }

    if(JSON.parse(localStorage.getItem("SummeryShow")))
    {  this.summaryDtl = JSON.parse(localStorage.getItem("SummeryShow")); }else{  this.summaryDtl = false  }

    if(JSON.parse(localStorage.getItem("SellerShow")))
    {  this.sellerDtl = JSON.parse(localStorage.getItem("SellerShow")); }else{  this.sellerDtl = false  }

    if(JSON.parse(localStorage.getItem("BgbannerShow")))
    {  this.bgBner = JSON.parse(localStorage.getItem("BgbannerShow")); }else{  this.bgBner = false  }

    if(JSON.parse(localStorage.getItem("HeadBnnerShow")))
    {  this.headBner = JSON.parse(localStorage.getItem("HeadBnnerShow")); }else{  this.headBner = false  }
    this.baseUrl = window.location.origin;

  }

  ngOnInit() {

    this.CancelTicketForm = this.fb.group({
      booking_id: [''],
      refund: [''],
      new_price: [''],
      new_seat_qty: [''],
      narration: ['']
    })

    this.AddNewInfantForm = this.fb.group({
      booking_id: [''],
      gender: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      dob: ['', Validators.required],
      age: [1],
      buy_price: ['0', [ Validators.pattern('^[0-9]*$')]],
      sell_price: ['0', [ Validators.pattern('^[0-9]*$')]],
      narration: ['', Validators.required]
    })

    this.imgUrl = this.sharedService.serviceBase;
    this.profileImg = this.imgUrl + 'uploads/userprofile'+'/'+this.userdata.image;

    this.cancelTicket = this.fb.group({
      id:[''],
      narration:['', Validators.required],
      price:[''],
      refund:[''],
      create_new:this.isAuto,
      refund_type:['',Validators.required],
      prefix:[''],
      credit_note_no:[''],
    })
    this.viewDetail();

    this.ngxLoader.start();
    this.summary = this.fb.group({
      name: [''],
      email: [''],
      mobile: [''],
      password: [''],
    })

    this.element = {
      page_limit: 10,
      page_no: 1,
      search: "",
      status: this.filterstatus,
      flight_type: this.type,
      sector: this.sector,
      start_date: this.formdate,
      end_date: this.todate,
      order_key: "departure_date",
      order_value: "desc",
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB"
    }
    this.element['page_no'] = 1;
    this.PackageStatus = '';
    this.ngxLoader.stop();
    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this.fb.group({
      thirdCtrl: ['', Validators.required]
    });

    this.editticketform1 = this.fb.group({
      plan_1: ['', Validators.required],
      plan_2: ['', Validators.required],
      plan_3: ['', Validators.required],
      plan_4: ['', Validators.required]
    });
    this.delete_ticket_form = this.fb.group({
      qty: ['', Validators.required],
      plan1: ['1'],
      plan2: ['1'],
      plan3: ['1'],
      plan4: ['1'],
    });

  }

  get formValidate() { return this.AddNewInfantForm.controls; }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  subtractYears(date:any, years:any) {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(dateCopy.getFullYear() - years);
    return dateCopy;
  }

  checkHeader(event) {
    if (event.checked == true) {
      this.headBner = true;
      localStorage.setItem('HeadBnnerShow','true')
    } else {
      this.headBner = false;
      localStorage.setItem('HeadBnnerShow','false')
    }
  }

  imgBanner(event) {
    if (event.checked == true) {
      this.bgBner = true;
      localStorage.setItem('BgbannerShow','true')
    } else {
      this.bgBner = false;
      localStorage.setItem('BgbannerShow','false')
    }
  }

  sellerInfo(event) {
    if (event.checked == true) {
      this.sellerDtl = true;
      localStorage.setItem('SellerShow','true')
    } else {
      this.sellerDtl = false;
      localStorage.setItem('SellerShow','false')
    }
  }

  summaryShow(event) {
    if (event.checked == true) {
      this.summaryDtl = true;
      localStorage.setItem('SummeryShow','true')
    } else {
      this.summaryDtl = false;
      localStorage.setItem('SummeryShow','false')
    }
  }
  contactShow(event) {
    if (event.checked == true) {
      this.contactDtl = true;
      localStorage.setItem('ContactShow','true')
    } else {
      this.contactDtl = false;
      localStorage.setItem('ContactShow','false')
    }
  }

  delCheckBox() {
    if (this.delBox == false) {
      this.delBox = true;
    } else {
      this.delBox = false;
    }
  }

  fbdCheck(i, event) {
    if (event.srcElement.checked == false) {
      this.checkTraveler.forEach((element, index) => {
        if (element.id == i) this.checkTraveler.splice(index, 1);
      });
    } else {

      this.checkTraveler.push({ id: i })
    }

  }

  // deleteTraveler(flight) {
  //   var requestdata = {
  //     booking_id: flight.id,
  //     flight_id: flight.flight_id,
  //     delete_passgeners_id: this.checkTraveler
  //   }
  //   this.sharedService.post('v1/flight/cancel_book_ticket', requestdata).subscribe((res) => {
  //     if (res.replyCode == 'success') {
  //       $('#bookSummeryReport').modal('hide');
  //     }
  //   }, error => {
  //     this.toastr.errorToastr(error.error.replyMsg, "Error");
  //   });
  // }

  closeTicket(){
    $('#delConfirm').modal('hide');
  }

  deletConfirm(i,flight){
    $('#delConfirm').modal('show');
    // if (event.srcElement.checked == false) {
      this.checkTraveler.forEach((element, index) => {
        if (element.id == i) this.checkTraveler.splice(index, 1);
      });
    // } else {
      this.checkTraveler.push({ id: i })
    // }
    this.flightbooking_id = flight;
    $('#delConfirm').modal('hide');
  }

  deleteTraveler(){
     // console.log("delete traveler flight::::::::::",i);
    // old data
     // var requestdata = {
      // booking_id: this.flightbooking_id.flight.id,
      // flight_id: this.flightbooking_id.flight.flight_id,
      // delete_passgeners_id: this.checkTraveler
      // }
    // old data
    this.submitted = true;
    if (this.cancelTicket.invalid) {
      return;
    } else {
      var requestdata = {
        "booking_id":Number(this.checkTraveler[0].id),
        "refund": Number(this.cancelTicket.value.refund),   // amount to be refunded
        "narration": this.cancelTicket.value.narration,
        "create_new": this.isAuto,  // true or false
        "price":Number(this.cancelTicket.value.price),    // amount for the new ticket created
        "refund_type":this.cancelTicket.value.refund_type,
        "prefix":this.cancelTicket.value.prefix,
        "credit_note_no":this.cancelTicket.value.credit_note_no

      }
      // console.log("check request data==========>>>>>>",requestdata);
      // return false;

      this.totalAddSeat = this.checkTraveler.length ;
      this.sharedService.post('v1/accounting/cancel_ticket', requestdata).subscribe((res) => {
      // this.sharedService.post('v1/flight/cancel_book_ticket', requestdata).subscribe((res) => {

        if (res.replyCode == 'success') {
            this.toastr.successToastr(res.replyMsg, "Success");
            this.delete_ticket_detail=this.flightbooking_id;
            this.delete_ticket_detail.index=this.flightbooking_id.index;
            this.total_amount = this.flightbooking_id.amount;
            this.avg_pricePerTicket = this.total_amount / this.flightbooking_id.total_book_seats;
            this.refundAmount = this.avg_pricePerTicket * this.checkTraveler.length
            var detaildata = {
              user_id: this.flightbooking_id.user_id,
              flight_id: this.flightbooking_id.flight_id,
              ticket_date: this.flightbooking_id.departure_date
            }
            setTimeout(() => {
              // this.showdetailinv(detaildata)
              // this.ticketdetails(this.ticketdetailindexdata.flight,this.ticketdetailindexdata.index,this.ticketdetailindexdata.status)
              // $('#edittravelll').modal('hide');
              $('#delConfirm').modal('hide');
              window.location.reload();
              // $('#refundticket').modal('show');
            }, 1000);

        }
      }, error => {
        this.toastr.errorToastr(error.error.replyMsg, "Error");
      });
    }
  }

  showdetailinv(flightdata) {
    // console.log('flightdata =============== ', flightdata);

    if(this.dateclicked == true){
      var clickeddate=flightdata.ticket_date;
      var changeurl = (this.router.url).split('#');
      var host=window.location.origin;
      this._location.go(changeurl[0]+'#'+clickeddate);
    }
    // this.showingloader = true;
    var d_date;
    if (flightdata.ticket_departure_date) {
      d_date = flightdata.ticket_departure_date
    } else {
      d_date = flightdata.ticket_date
    }
    var requestdata = {
      user_id: this.userdata.id,
      flight_id: flightdata.flight_id,
      departure_date: d_date
    }

    this.sharedService.post('v1/flight/flight_date_ticket_details', requestdata).subscribe((res) => {
      if (res.replyCode == 'success') {
        // this.showingloader = false;
        this.flight_inventory_date_details = res.data;
        this.flight_inventory_date_details_infant = res.infant_booking_data;


        if (this.flight_inventory_date_details_infant.length > 0) {
          for (var i = 0; i < this.flight_inventory_date_details_infant.length; i++) {
            this.flight_inventory_date_details.push({
              "flight_booking_id": this.flight_inventory_date_details_infant[i].flight_booking_id,
              "flight_id": this.flight_inventory_date_details_infant[i].flight_id,
              "payment_status": this.flight_inventory_date_details_infant[i].payment_status,
              "purchase_by": this.flight_inventory_date_details_infant[i].purchase_by,
              "purchase_by_company_name": this.flight_inventory_date_details_infant[i].purchase_by_company_name,
              "reference_id": this.flight_inventory_date_details_infant[i].reference_id,
              "ticket_cancel_at": this.flight_inventory_date_details_infant[i].ticket_cancel_at,
              "ticket_cancel_status": this.flight_inventory_date_details_infant[i].ticket_cancel_status,
              "ticket_sell_date": this.flight_inventory_date_details_infant[i].ticket_sell_date,
              "ticket_book_status": 1,
              "showoption" : flightdata.nooption

            });
          }
        }
        this.flight_inventory_date_details.forEach(element => {
          if (element.ticket_cancel_status == 2) {
            var canceldate = (element.ticket_cancel_at).split(' ')
            element.ticketcanceldate = this.datePipe.transform(canceldate[0], 'dd/MM/yyyy');
            element.ticketcanceltime = canceldate[1];
          }
          if (element.ticket_book_status == 0 || element.ticket_book_status == 2) {
            element.noedit = true;
            this.editticketform1.patchValue({
              plan_1: element.plan_1.toString(),
              plan_2: element.plan_2.toString(),
              plan_3: element.plan_3.toString(),
              plan_4: element.plan_4.toString()
            });
          }

          // if (element.ticket_book_status == 0) {
          //   element.noedit = true;
          // } else if (element.ticket_book_status == 2) {
          //   element.noedit = true;
          // }
          Object.assign(element, { edit_flag: false, showoption : flightdata.nooption })
        });
        // this.flight_inventory_date_details.forEach(element => {
        //   var obj = element;
        //   Object.assign(obj, { edit_flag: false, showoption : flightdata.nooption })
        // });
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    });
  }

  deleteProducts() {
    alert("Are you sure delete this inventory")
  }
  cancel(flight) {

    this.cancelticket = flight;
    $('#delete').modal('show');
  }

  confirmcancel(flight) {

    var requestdata = {
      flight_id: flight.flight_id,
      ticket_id: flight.ticket_id,
      ticket_date: flight.booking_date
    }
    this.sharedService.post('v1/flight/cancel_book_ticket', requestdata).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr("Ticket Cancelled Successfully", "Ticket Cancelled")
        $('#delete').modal('hide');
        $('#bookSummeryReport').modal('hide');
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }

  durationdiff(dep_time, arr_time, dep_date, type) {
    //
    var dep_hr = parseInt(dep_time.split(':')[0]);
    var arr_hr = parseInt(arr_time.split(':')[0]);
    //
    if (arr_hr < dep_hr) {
      var nwdate;
      if (!dep_date) {
        nwdate = new Date();
      } else {
        nwdate = new Date(dep_date);
      }

      var newdate = nwdate.setDate(nwdate.getDate() + 1);
      if (type == 'oneway') {
        this.return_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
      } else {
        this.rreturn_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
      }

    } else {
      this.return_d_date = dep_date;
      this.rreturn_d_date = dep_date;
    }
    //
    var start_date = dep_date + " " + dep_time;
    var end_date = this.return_d_date + " " + arr_time;
    //
    return this.getDataDiff(new Date(start_date.toString()), new Date(end_date.toString()));

  }

  EditValu() {
    this.EditValuSummery = !this.EditValuSummery;
  }
  onAddUser() {
    this.userList = {
      basefare: this.fd_basefare,
      feetexs: this.fd_feetaxes,
      dis: this.fd_discount,
      amt: this.fd_amount
    };
    $('#EditValuSummery').modal('hide');
    $('#baseFareOld').hide();
    $('#baseFareOld1').hide();
    $('#baseFareOld2').hide();
    $('#baseFareOld3').hide();
    this.EditValuSummery = false;
  }

  closeEdit() {
    this.EditValuSummery = false;
    // this.userList = {}
  }
  backClose(){
    sessionStorage.removeItem('booking_id');
    this._location.back();
    window.close();
  }



  changepnrpopup(data){
    this.chng_pnr_bookingid=this.booking_id
    this.chng_pnr_pnr=data
    $('#changepnrpop').modal('show');
  }

  pnr_change(){
    var request_data ={
      "booking_id": this.booking_id,
      "pnr": this.chng_pnr_pnr
    };
    this.sharedService.post('v1/accounting/change_pnr',request_data).subscribe((res:any)=>{
      if(res.replyCode == 'succes' || res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg,'Success')
        $('#changepnrpop').modal('hide');
        this.viewDetail();
        window.location.reload();
      }
    },err =>{
      this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }

  // ---------------Download PDF -------------------
  // generatePDF1() {
  //   var data = document.getElementById('contentToConvert1');
  //   this.ngxLoader.start();
  //     html2canvas(data).then(canvas => {
  //       var imgWidth = 200;
  //       // var imgHeight = canvas.height * imgWidth / canvas.width;
  //       var imgHeight = canvas.height * imgWidth / canvas.width;
  //       const contentDataURL = canvas.toDataURL('image/png')
  //       let pdf = new jspdf('p', 'mm', 'a4');
  //       var position = 5;
  //       pdf.addImage(contentDataURL, 'PNG', 5, position, imgWidth, imgHeight)
  //       pdf.save('download.pdf');
  //     });
  //     this.ngxLoader.stop();
  // }

  printpreview() {
    var printW = window.open("");
    var html = document.getElementById('contentToConvert1').innerHTML;



    printW.document.write('<html><head><title>Booking Details</title>');
    printW.document.write(`<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" type="text/css" />`);
    printW.document.write(`<style>
     .col-2p {-webkit-box-flex: 0;flex: 0 0 20%;max-width: 20%;}
     .col-3 {-webkit-box-flex: 0;flex: 0 0 25%;max-width: 25%;}
     .col-4p {-webkit-box-flex: 0;flex: 0 0 33.33%;max-width: 33.33%;}
     .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
     .col-5p {-webkit-box-flex: 0;flex: 0 0 40%;max-width: 40%;}
     .text-lp {text-align:left !important}
     .text-cp {text-align:center !important}
     .text-rp {text-align:right !important}
     .dark-theme-clr{color: #000;font-weight:500;background-color:#ddd !important;}
     table td{border-bottom:1px solid#ddd;padding:5px;border-collapse: collapse;} table th {border-collapse: collapse;border-bottom:1px solid#ddd;padding:5px;font-weight: 600;color: #000;} table{ width: 100%; border-spacing:1px;border-collapse: separate;}.row{display: flex;flex-wrap: wrap;width:100%;}.col-md-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}.img-box {width: 50px;height: 50px;border: 1px solid #ddd;object-fit:cover;}
     .border {border: 1px solid #dee2e6!important;}
     table{margin-bottom:5px !important;}
     .ph-text{height:220px !important;font-size:10px !important;line-height:13px !important;}
     .row {display: -webkit-box;display: flex;flex-wrap: wrap;}
     .text-left {text-align: left!important;}
     .img-profile {width: 100%;height: auto;-o-object-fit: contain;object-fit: contain;max-height: 70px;}
      .text-center {text-align: center!important;}
      .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
      .head-clr {color: #ff6a00;}.fw-500 {font-weight: 500;}
      .m-0 {margin: 0!important;}.w-100 {width: 100%!important;}.text-center {text-align: center!important;}
      .bg-white {background-color: #fff!important;}
      table {border-collapse: collapse;}
      .table {width: 100%;color: #212529;}
      .col-lg-4 {-webkit-box-flex: 0;flex: 0 0 33.333333%;max-width: 33.333333%;}
      .wp-100{width:100%;} .wp-30{width:30% !important;}
      .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        margin-bottom:.5rem !important;font-weight: 500;line-height: 1.2;}
      .mytbl td {padding: 0.5rem;margin-bottom: 0rem !important;border-bottom:1px solid#ddd;}
      .mytbl td {padding: 0.5rem !important;vertical-align: middle;padding-left: 0.75rem !important;}
      .table td, .table th {padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;}
      .border-bottom {border-bottom: 1px solid #dee2e6!important;}
      .img-box-fix {width: 50px;height: 30px;-o-object-fit: contain;object-fit: contain;}
      img {vertical-align: middle;border-style: none;}
      .pv-none{display:none;}
      .textclr {color: #828282;}
      .ft-12 {font-size: 12px !important;}
      .align-self-center {align-self: center!important;}
      .table-responsive {display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
      .d-none {display: none!important;}.text-dark {color: #343a40!important;}.h6, h6 {font-size: 1rem;}
      .form-control:disabled, .form-control[readonly] {background-color: #e9ecef !important;opacity: 1;}
      .form-control {display: block;width: 100%;height: calc(1.5em + .75rem + 2px);padding: .375rem .75rem;font-size: 1rem;
        font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: .25rem;-webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
      .prnt-h{line-height:32px !important;}
      .form-control-1{border:1px solid#ddd;border-radius:5px;background-color:#e9ecef;color:#303030;padding:8px;}
      .w-10pr{width:10%;}.w-14pr{width:14%;}.w-25pr{width:25%;}
      .pmt-10{margin-top:10px;}
      .mytbl tr td{padding:2px;}
      .p-Size{font-size:12px;}.pmb-1{margin-bottom:10px !important;}.pmb-2{margin-bottom:15px !important; }
      .mytbl tr td{padding:2px;}.pmt-1{margin-bottom:10px !important;}.pmt-2{margin-bottom:15px !important; }
      .pr-dnone{display:none !important;}
      .baggage-detail{padding:15px 20px;border-radius: 50px;border:1px dashed; border-color: #a9a5a5;}
      .img-set-img{width:100px !important;height:100px;border-radius:10px;}
      .box-user{padding: 20px;border-radius: 20px;color: #fff;height: auto;width:auto;}
      .myPrintCls{border: 3px solid;border-radius: 10px;width: 130px;margin: auto;padding: 5px;}
      </style>`);
    printW.document.write('</head><body >');
    printW.document.write(html);
    printW.document.write('</body></html>');

    printW.document.close();
    printW.focus();
    setTimeout(() => {
    printW.print();
  }, 500);

    // if(this.commonService.osname != "Mac OS"){
    //   printW.close();
    // }else{
    //   console.log("not Windows os")
    // }

  }

  getDataDiff(s_date, e_date) {
    var diff = e_date.getTime() - s_date.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return hours + " hrs " + ": " + minutes + " min";
  }


  // viewDetail(flight,index,status) {
  viewDetail() {
    // this.flight_booking_id = flight.flight_booking_id
    // this.ticketdetailindexdata.flight=flight
    // this.ticketdetailindexdata.index=index
    // this.ticketdetailindexdata.status=status
    // console.log("flight,index,status ::::::::::",this.ticketdetailindexdata)
    // this.delBox = false;
    // this.checkTraveler = [];
    // var requestdata = {
    //   user_id: this.userdata.id.toString(),
    //   flight_booking_id: flight.flight_booking_id
    // }


    this.userList = {}
    this.fd_basefare = ''
    this.fd_feetaxes = ''
    this.fd_discount = '';
    this.fd_amount = '';
    var self = this;
    var Objects = {
      user_id: this.userdata.id,
      // booking_id: 31545
      booking_id: this.booking_id
      // booking_id: element.flight_booking_id
    }
    this.sharedService.post('v1/accounting/view_booking', Objects).subscribe((res) => {
      if (res.replyCode == 'success') {
        var counter = 0;
        this.bgBannerPath = res.bannerimgBasePath;
        this.bookData=res;
        this.flt_bokg_dtl = res.passenger;
        this.totalPassenger = res.passenger.length;
        this.barcodes = res.barcodes;

        if(this.bookData.flight.next_day==0){
          this.arrivaldate = this.bookData.flight.departure_date;
        }else{
          var d = new Date(this.bookData.flight.departure_date);
          d.setDate(d.getDate() + this.bookData.flight.next_day);
          this.arrivaldate = this.datePipe.transform(d, 'yyyy-MM-dd');
        }

        setTimeout(() => {
          this.imgPath = res.imgBasePath;
          // res.flight.forEach(element => {
          //   if (element.ticket_cancel_at == null) {
          //     element.t_status = 'booked'
          //   } else {
          //     counter += 1;
          //     element.t_status = 'cancelled'
          //   }
          // });
          // if (counter == res.flight.total_book_seats) {
          //   res.data.ticket_status = "cancelled"
          // } else {
          //   res.data.ticket_status = "booked"
          // }
          this.myColorChange = res.flight.airline_name;

            if(this.myColorChange ==  'GoFirst'){this.changeColorAirline = '#161cb2'}
            else if(this.myColorChange ==  'Air Canada'){this.changeColorAirline = '#ff0c24'}
            else if(this.myColorChange ==  'LOT Polish Airlines'){this.changeColorAirline = '#1a3171'}
            else if(this.myColorChange ==  'AirAsia India'){this.changeColorAirline = '#e42021'}
            else if(this.myColorChange ==  'SpiceJet'){this.changeColorAirline = '#c41230'}
            else if(this.myColorChange ==  'Lufthansa'){this.changeColorAirline = '#c58b05'}
            else if(this.myColorChange ==  'AirAsia X'){this.changeColorAirline = '#ed3024'}
            else if(this.myColorChange ==  'IndiGo Airlines'){this.changeColorAirline = '#031c96'}
            else if(this.myColorChange ==  'AirIndiaExpress' || this.myColorChange == 'Air India Express'){this.changeColorAirline = '#e42d55'}
            else if(this.myColorChange ==  'Air India'){this.changeColorAirline = '#e87626'}
            else if(this.myColorChange ==  'AirAsia'){this.changeColorAirline = '#e32526'}
            else if(this.myColorChange ==  'Etihad'){this.changeColorAirline = '#c89900'}
            else if(this.myColorChange ==  'Vistara'){this.changeColorAirline = '#541a49'}
            else if(this.myColorChange ==  'Oman Air'){this.changeColorAirline = '#b5985a'}
            else if(this.myColorChange ==  'Singapore Airlines'){this.changeColorAirline = '#f69b00'}
            else if(this.myColorChange ==  'Turkish Airlines'){this.changeColorAirline = '#c10a0c'}
            else if(this.myColorChange ==  'Virgin Atlantic'){this.changeColorAirline = '#591866'}
            else {this.changeColorAirline = '#303030'}

          this.bookData = res;



          // this.bookData.cal_base_fare = ((this.bookData.flightData.flight_fare_taxes[0].base_fare * (this.bookData.adult + this.bookData.children)) + (this.bookData.flightData.infant_base_price * this.bookData.infant));
          // this.bookData.cal_fees_and_taxes = (this.bookData.flightData.flight_fare_taxes[0].fee_taxes * (this.bookData.adult + this.bookData.children));
          // this.bookData.servicecharge = (this.bookData.total_amount - (this.bookData.cal_base_fare) - (this.bookData.cal_fees_and_taxes));
          // this.bookData.ticket_id = element.flight_booking_id;
          // this.flightData = res.data;
          // this.returnFlight = res.data.flightData.return_flight;
          this.flt_bokg_dtl = res.passenger;

          var selectedText = this.bookData.departure_date;
          var selectedDate = new Date(selectedText);
          var now = new Date();
          var newdate = now.setDate(now.getDate() - 1);
          if (selectedDate < new Date(newdate)) {

            this.bookData.nooption = 1;
          } else {
            this.bookData.nooption = 0;
          }

        }, 100);

      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
    // $('#bookSummeryReport').modal('show');
    $('#baseFareOld').show();
    $('#baseFareOld1').show();
    $('#baseFareOld2').show();
    $('#baseFareOld3').show();
  }

  edittravel(i,fbd){
    // console.log(fbd,"-------------fbd---885*-*-*-*--");

    this.edittravelBox=true;
    this.edittravelindex=i;
    this.editt=fbd;
    this.id=this.editt.id;
    this.gender=this.editt.gender;
    this.first_name=this.editt.first_name;
    this.middle_name=this.editt.middle_name;
    this.last_name=this.editt.last_name;
    this.dob=this.editt.dob;
    this.passport_expire_date=this.editt.passport_expire_date;
    this.passport_no=this.editt.passport_no;
    this.passport_issue_date=this.editt.passport_issue_date;
    this.country_of_issue=this.editt.country_of_issue;
    this.nationality=this.editt.nationality;
    this.wheelchair=this.editt.wheelchair;
    this.meal=this.editt.meal;
    this.type=this.editt.type;
    this.traveller_age = this.editt.age;
    // this.amount=this.editt.ticket_price;
    // $('#edittravelll').modal('show');
    // this.viewDetail();
    $('#edittravelll').modal('show');
  }
  Savetravel(){
    var reuestdata={
      id: this.id?this.id:'',
      gender: this.gender?this.gender:"",
      first_name: this.first_name?this.first_name:"",
      middle_name: this.middle_name?this.middle_name:"",
      last_name: this.last_name?this.last_name:"",
      dob: this.dob?this.dob:"",
      passport_expire_date: this.passport_expire_date?this.passport_expire_date:"",
      passport_no: this.passport_no?this.passport_no:"",

      passport_issue_date: this.passport_issue_date?this.passport_issue_date:"",
      country_of_issue: this.country_of_issue?this.country_of_issue:"",
      nationality: this.nationality?this.nationality:"",
      wheelchair: this.wheelchair?this.wheelchair:0,
      meal: this.meal?this.meal:"",
      type: this.type?this.type:"",
      amount: this.edit_amount?Number(this.edit_amount):""
    }
    this.sharedService.post('v1/flight/edit_traveller',reuestdata).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.edittravelBox=false;
        $('#edittravelll').modal('hide');
        this.edit_amount = '';
        this.toastr.successToastr(res.replyMsg, "Success");
        }
       }, error => {
      this.toastr.errorToastr(error.error.replyMsg, "Error");
    });
  }

  EditTravellers(data){
  if(this.bookData.flight.is_international==1){
    // if(this.id && this.gender && this.first_name && this.last_name && this.dob && this.passport_expire_date && this.passport_no){
    if(this.id && this.gender && this.first_name && this.last_name ){
      this.edittravelBox=false;
      this.edittravelindex=-1;
      var reuestdata={
        id: this.id?this.id:'',
        gender: this.gender?this.gender:"",
        first_name: this.first_name?this.first_name:"",
        middle_name: this.middle_name?this.middle_name:"",
        last_name: this.last_name?this.last_name:"",
        dob: this.dob?this.datePipe.transform(this.dob, 'yyyy-MM-dd'):"",
        // dob: this.dob?this.dob:"",
        // passport_expire_date: this.passport_expire_date?this.passport_expire_date:"",
        passport_expire_date: this.passport_expire_date? this.datePipe.transform(this.passport_expire_date, 'yyyy-MM-dd'):"",
        passport_no: this.passport_no?this.passport_no:"",
        age:this.traveller_age,

        passport_issue_date: this.passport_issue_date? this.datePipe.transform(this.passport_issue_date, 'yyyy-MM-dd'):"",
        // passport_issue_date: this.passport_issue_date?this.passport_issue_date:"",
        country_of_issue: this.country_of_issue?this.country_of_issue:"",
        nationality: this.nationality?this.nationality:"",
        wheelchair: this.wheelchair?this.wheelchair:0,
        meal: this.meal?this.meal:"",
        type: this.type?this.type:"",
        amount: this.edit_amount?Number(this.edit_amount):""
      }

      this.sharedService.post('v1/flight/edit_traveller',reuestdata).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.edittravelBox=false;
          $('#edittravelll').modal('hide');
          this.edit_amount = '';
          this.viewDetail();
          this.toastr.successToastr(res.replyMsg, "Success");
          }
        }, error => {
        this.toastr.errorToastr(error.error.replyMsg, "Error");
      });
    }else{
      this.toastr.errorToastr("Submitted Form Is Invalid.Please Fill The Details....","Error")
    }

  }else{
    if(this.id && this.gender && this.first_name && this.last_name ){
      this.edittravelBox=false;
      this.edittravelindex=-1;
      var reuestdata={
        id: this.id?this.id:'',
        gender: this.gender?this.gender:"",
        first_name: this.first_name?this.first_name:"",
        middle_name: this.middle_name?this.middle_name:"",
        last_name: this.last_name?this.last_name:"",
        dob: this.dob?this.datePipe.transform(this.dob, 'yyyy-MM-dd'):"",
        passport_expire_date: this.passport_expire_date? this.datePipe.transform(this.passport_expire_date, 'yyyy-MM-dd'):"",
        passport_no: this.passport_no?this.passport_no:"",
        age:this.traveller_age,

        passport_issue_date: this.passport_issue_date? this.datePipe.transform(this.passport_issue_date, 'yyyy-MM-dd'):"",
        country_of_issue: this.country_of_issue?this.country_of_issue:"",
        nationality: this.nationality?this.nationality:"",
        wheelchair: this.wheelchair?this.wheelchair:0,
        meal: this.meal?this.meal:"",
        type: this.type?this.type:"",
        amount: this.edit_amount?Number(this.edit_amount):""
      }
      this.sharedService.post('v1/flight/edit_traveller',reuestdata).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.edittravelBox=false;
          $('#edittravelll').modal('hide');
          this.edit_amount = '';
          this.viewDetail();
          this.toastr.successToastr(res.replyMsg, "Success");
          }
        }, error => {
        this.toastr.errorToastr(error.error.replyMsg, "Error");
      });
    }else{
      this.toastr.errorToastr("Submitted Form Is Invalid.Please Fill The Details....","Error")
    }
  }
  }

  onCloseEditTravelModal(){
    $('#edittravelll').modal('hide');
  }
  themeClrChange(val){
    var myVall = val;
    if(myVall == 1){
      this.themeClr1 = true;
      this.themeClr2 = false;
      this.themeClr3 = false;
    }else if(myVall == 2){
      this.themeClr1 = false;
      this.themeClr2 = true;
      this.themeClr3 = false;
    }else if(myVall == 3){
      this.themeClr1 = false;
      this.themeClr2 = false;
      this.themeClr3 = true;
    }
  }

  // downloadAsPDF() {
  //   const doc = new jsPDF();

  //   const specialElementHandlers = {
  //     '#editor': function (element, renderer) {
  //       return true;
  //     }
  //   };

  //   const pdfTable = this.pdfTable.nativeElement;

  //   doc.fromHTML(pdfTable.innerHTML, 15, 15, {
  //     width: 190,
  //     'elementHandlers': specialElementHandlers
  //   });

  //   doc.save('tableToPdf.pdf');
  // }

  createTicketStatus(event){
    if (event == 1) {
      this.isAuto = true;
    } else {
      this.isAuto = false;
    }
  }

  onCancelTicket(){
    $('#cancelTicket').modal('show');
  }

  onCancelTicketSubmit(){
    this.submitted = true;
    this.CancelTicketForm.value.booking_id = this.booking_id;
    if (this.CancelTicketForm.invalid) {
      return;
    } else {
        this.sharedService.post('v1/flight/cancel_ticket', this.CancelTicketForm.value).subscribe((res) => {
          if (res.error == 'success') {
            this.toastr.successToastr(res.message, 'Success');
            $("#cancelTicket").modal('hide');
            this.CancelTicketForm.reset();
            this.submitted = false;
            this.viewDetail();
          } else {
            this.toastr.errorToastr(res.message, 'Error');

          }
        });
    }
  }

  onCloseCancelTicketModal(){
    $('#cancelTicket').modal('hide');
    this.CancelTicketForm.reset();
  }

  onAddNewInfant(){
    const currentYear = new Date().getFullYear();
    let d = new Date(this.subtractYears(this.bookData.flight.departure_date, 2));
    d.setDate(d.getDate() + 1);
    this.minlastDate = d;
    // this.maxlastDate = new Date(this.bookData.flight.departure_date);
    this.maxlastDate = new Date();

    $('#addNewInfant').modal('show');
  }

  onAddInfantFormSubmit(){
    this.submitted = true;
    this.AddNewInfantForm.value.booking_id = this.booking_id;
    this.AddNewInfantForm.value.dob = this.convert(this.AddNewInfantForm.value.dob);
    this.AddNewInfantForm.value.buy_price =   (this.AddNewInfantForm.value.buy_price!='')?this.AddNewInfantForm.value.buy_price:0;
    this.AddNewInfantForm.value.sell_price = (this.AddNewInfantForm.value.sell_price!='')?this.AddNewInfantForm.value.sell_price:0;
    // this.AddNewInfantForm.value.buy_price = this.bookData.price[0].buying_price;
    // this.AddNewInfantForm.value.sell_price = this.bookData.price[0].selling_price;
    if (this.AddNewInfantForm.invalid) {
      return;
    } else {
      console.log(this.AddNewInfantForm.value,"-----------this.AddNewInfantForm.value----1132-**-*-");

        this.sharedService.post('v1/booking_change/add_infant', this.AddNewInfantForm.value).subscribe((res) => {
          if (res.replyCode == 'success') {
            this.toastr.successToastr(res.replyMsg, 'Success');
            $("#addNewInfant").modal('hide');
            this.AddNewInfantForm.reset();
            this.submitted = false;
            this.viewDetail();
          } else {
            this.toastr.errorToastr(res.replyMsg, 'Error');

          }
        });
    }
  }

  onCloseInfantModal(){
    this.AddNewInfantForm.reset();
    this.AddNewInfantForm.controls['age'].setValue(1)
    this.AddNewInfantForm.controls['buy_price'].setValue(0)
    this.AddNewInfantForm.controls['sell_price'].setValue(0)
    $('#addNewInfant').modal('hide') ;
  }
  onChangeType(event: any) {
    this.changeType = event.target.value;
    const prefixControl = this.CancelTicketForm.get('prefix');
    const creditNoteControl = this.CancelTicketForm.get('credit_note_no');
    if (this.changeType === 'credit_note') {
      this.isValidate =true;
      if (prefixControl) {
        prefixControl.setValidators([Validators.required]);
      }
      if (creditNoteControl) {
        creditNoteControl.setValidators([Validators.required]);
      }
    } else {
      this.isValidate =false;
      if (prefixControl) {
        prefixControl.clearValidators();
      }
      if (creditNoteControl) {
        creditNoteControl.clearValidators();
      }
    }
  }
}
