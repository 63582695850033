import { ActivatedRoute, Router } from '@angular/router';
import { SharedserviceService } from 'src/app/services';
import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiRequestService } from 'src/app/services/api.service';
import { ManagedataService } from 'src/app/services/manage-data.service';

declare var $: any;

@Component({
  selector: 'app-smo_setting',
  templateUrl: './smo_setting.component.html',
  styleUrls: ['./smo_setting.component.scss'],
})
export class SmoSettingComponent implements OnInit {

  searchFlagReset: boolean = false;
  fitFareruleListData:any;
  smoSettingData: any;
  public setSMOSettingForm: FormGroup;submitted = false;
  public fitDefaultFareRuleForm: FormGroup;
  isAirlinesList:any=[
    {'name':"Select ALL","id":0,"code":""},
    {'name':"Air India","id":15,"code":"AI"},
    {'name':"AirAsia India","id":25,"code":"I5"},
    {'name':"GoFirst","id":72,"code":"G8"},
    {'name':"IndiGo","id":80,"code":"6E"},
    {'name':"SpiceJet","id":124,"code":"SG"},
  ]
  userdata: any;
  isMarkupList: any;
  isSelectGroup: any;
  isGroupListData: any;
  isNameListId: any;
  isNameId: any;
  isApiPartnerId: any;
  isNameData: any;
  isSelectStatus: number;
  isDeleteId: any;
  isGroupShow: boolean=false;
  userList: boolean=false;
  acc_type: any;
  acc_id: any;
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedserviceService,public apiService: ApiRequestService,public manageData:ManagedataService,
    public router: Router,private toastr: ToastrManager,private activatedRoute: ActivatedRoute)
    {
      this.userdata = JSON.parse(localStorage.getItem("user"));
      this.apiService.onGroupListResponse();
    }
  ngOnInit() {
    this.GetUserSmoSettingData();

    this.setSMOSettingForm = this.fb.group({
      access_token: ['', Validators.required],
      account_name: ['', Validators.required],
      app_id: ['', Validators.required],
      app_secret: ['', Validators.required],
      page_id: [''],
      ig_user_id: [''],
    })

  }
  get validate() { return this.setSMOSettingForm.controls; }


  GetUserSmoSettingData(){
    this.sharedService.get('v1/user_smo/get_user_smo_settings').subscribe((res:any) => {
      if (res.replyCode == 'success') {
        this.smoSettingData = res.data;
      }else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  onAddNewSMOSettingModal(){
    $('#addSmoSettingModal').modal('show');
  }

  closeSmoModel(){
    $('#addSmoSettingModal').modal('hide');
    this.setSMOSettingForm.reset();
  }

  addSmoSettingData(){
    this.submitted = true;
    if(this.setSMOSettingForm.invalid){
      return;
    }else{
      this.sharedService.postRequest('v1/user_smo/add_user_smo', this.setSMOSettingForm.value).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          this.setSMOSettingForm.reset();
          this.submitted = false;
          this.toastr.successToastr(res.replyMsg, 'Success');
          this.GetUserSmoSettingData();
          $('#addSmoSettingModal').modal('hide');
        } else {
          this.submitted = false;
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, err => {
        this.toastr.errorToastr(err.error.replyMsg, 'Error');
      });
    }

  }

  onChangeFilter(event){
    if(event == 'instagram'){
      this.acc_type = 1;
    }else{
      this.acc_type = 2;
    }

  }

  onDeleteSMOSetting(data){
    this.acc_id = data.id;
    $('#deleteRequestModal').modal('show');
  }

  onCloseDeleteModal(){
    $('#deleteRequestModal').modal('hide');
  }

  onDeleteSettingdata(){
    var requestData = {
      'id' : this.acc_id,
    }
    this.sharedService.postRequest('v1/user_smo/delete_user_smo_settings', requestData).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Success');
        this.GetUserSmoSettingData();
        $('#deleteRequestModal').modal('hide');
      } else {
        this.submitted = false;
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, error => {
      this.submitted = false;
      this.toastr.errorToastr("Oops! Something went wrong!");
    });
  }



}

