import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ApiRequestService } from 'src/app/services/api.service';
import { ManagedataService } from 'src/app/services/manage-data.service';

declare var $:any;
@Component({
  selector: 'app-custom-departure-select-box',
  templateUrl: './custom-departure-select-box.component.html',
  styleUrls: ['./custom-departure-select-box.component.scss']
})
export class CustomDepartureSelectBoxComponent implements OnInit{
  public text: String;
  isDepartureSelectBoxFlag:boolean=false;
  public isTripValue: any = 'one_way';
  public isMasterOptions: any = '1';
  isDummyDepartureData:any=[];
  isDummyArrivalData:any=[];
  isStaffLogin:any;
  public departureDataMaster: any; departureArrayMaster: any = []; departureArrayResponseMaster: any = [];departureArrayAllResponseMaster: any = []; departureArrayResponseStatus0: any = [];departureArrayResponseStatus1: any = []; SelectedDepartureDataMaster: any = {};

  departureData: any = []; departureArray: any = [];departureArrayAllData: any = []; departureArrayResponse: any = []; SelectedDepartureData: any = {};
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.eRef.nativeElement.contains(event.target)) {
      this.text = "clicked inside";
    } else {
      this.manageDataService.SetDepartureSelectBox(false);
      this.text = "clicked outside";
    }
  }
  constructor(private eRef: ElementRef,public manageDataService:ManagedataService,public apiService: ApiRequestService){
  }
  ngOnInit(): void {
    this.manageDataService.GetLoggedInUserDetails().subscribe((data:any)=>{ this.isStaffLogin=data;})
    this.manageDataService.GetDepartureSelectBox().subscribe((flag:boolean)=> this.isDepartureSelectBoxFlag=flag);
    this.manageDataService.GetTripValue().subscribe((data: any) => {
      if (data) {
        this.isTripValue = data;
      }
    })
 
    this.manageDataService.GetDepartureArray().subscribe((data: any) => {
      this.departureArrayResponse = [];
      this.departureArrayResponseMaster = [];
      this.departureArray = [];
      if (data.length > 0) {
        if(this.isMasterOptions=='2'){
          this.departureArrayResponse = data;
          this.departureArrayAllData = JSON.stringify(data);
        }else{
          this.departureArrayResponseMaster = data;
          this.departureArrayAllResponseMaster = JSON.stringify(data);
          this.departureArrayResponseMaster.forEach((element: any) => {
            this.departureArray.push(element);
            if(this.isStaffLogin.search==0){
              this.departureDataMaster ={};
            }else{
              this.departureDataMaster = this.departureArray;
            }
          });
        }
      }
    })
    this.manageDataService.GetSelectedDepartureData().subscribe((data: any) => {
      if (Object.keys(data).length > 0) {
        this.SelectedDepartureData = data;
        // console.log("SelectedDepartureData>>>>>>",this.SelectedDepartureData);
      }else{
        this.SelectedDepartureData = {};
      }
    })
    this.manageDataService.GetMasterTripOptions().subscribe((data: any) => {
      if (data) {
        this.isMasterOptions = data;
      }
    })
  }
  onOpenCloseDeparture(){
    this.isDepartureSelectBoxFlag=!this.isDepartureSelectBoxFlag;
    this.manageDataService.SetDepartureSelectBox(this.isDepartureSelectBoxFlag);
    this.apiService.onDepartureArrivalFlightList({ city_name: '' });
    setTimeout(() => {
      $('#box1').find('input[type="search"]').focus();
    }, 200);
  }
  onSelectDepartureCity(departureObject:any){
    
    this.manageDataService.SetSelectedDepartureData(departureObject);
    this.manageDataService.SetSelectedArrivalData({})
    // console.log("departureArrayAllData>>>>",JSON.parse(this.departureArrayAllData));
    this.departureArrayResponse=JSON.parse(this.departureArrayAllData);
    if (this.isMasterOptions == 2) {
      var datasssss:any=this.departureArrayResponse;
      this.isDummyArrivalData=datasssss;
      this.isDummyArrivalData.splice(this.isDummyArrivalData.findIndex((item: any) => item.departure_city_id == departureObject.departure_city_id), 1);
      // console.log("isDummyArrivalData>>>>",this.isDummyArrivalData);
      this.manageDataService.SetArrivalArray(this.isDummyArrivalData);
    }else{
      this.apiService.onArrivalFlightList({
        flight_type: this.isTripValue,
        airport_code: departureObject.airport_code,
        airport_id: departureObject.airport_id,
        airport_name: departureObject.airport_name,
        departure_airport_id: departureObject.departure_airport_id,
        departure_city_code: departureObject.departure_city_code,
        departure_city_id: departureObject.departure_city_id,
        departure_city_name: departureObject.departure_city_name,
        shared_inventory_plan_Id: departureObject.shared_inventory_plan_Id,
        vendor_share_plan: departureObject.vendor_share_plan,
      });
    }
    this.manageDataService.SetDepartureSelectBox(false);
    this.manageDataService.SetArrivalSelectBox(true);
    setTimeout(function(){
      $('body').find('input.myInputCssClick').trigger('click')
        setTimeout(function(){
          $('body').find('#box2 input[type="search"]').focus();
      },100)
    },200)

  }

  onSelect1Changed(event: any) {
    // Check if a value was selected in select1
    if (event.value) {
      // Programmatically open select2 when select1 value changes
      this.openSelect2();
    }
  }
  openSelect2() {


    // Programmatically open select2
    // if (this.select2) {
    //   this.select2.open(); // This triggers the opening of select2
    // }
  }
  onDepartureSearchFilter(event:any){
    var eventValue = event.target.value.toLowerCase();
    this.departureArrayResponse=JSON.parse(this.departureArrayAllData).filter((item:any) => (item.departure_city_name.toLocaleLowerCase().includes(eventValue)) || (item.departure_city_code.toLocaleLowerCase().includes(eventValue)));
  }
  onSelectDepartureMaster(event: any): void {
    // alert(event)
    console.log('event=====<<<<<<',event)
    if(event){
      this.departureArrayResponseMaster.forEach((element:any) => Object.assign(element,{selected_status:0}));
      this.departureArrayResponse=this.departureArrayResponseMaster;
      // console.log("departureArrayResponse>>>>>",this.departureArrayResponseMaster);
      var filterResult = [];
      filterResult = this.departureArrayResponseMaster.filter((x: any) => x.departure_city_id == event.departure_city_id);
      console.log("filterResult>>>>>",filterResult);
      if (filterResult.length > 0) {
        filterResult[0].selected_status=1;
        var datasssss:any=filterResult;
        // console.log("datasssss===>>>",datasssss);
        this.isDummyDepartureData=datasssss[0];
        var bodyOjectData = {
          flight_type: this.isTripValue,
          airport_code: this.isDummyDepartureData.airport_code,
          airport_id: this.isDummyDepartureData.airport_id,
          airport_name: this.isDummyDepartureData.airport_name,
          departure_airport_id: this.isDummyDepartureData.departure_airport_id,
          departure_city_code: this.isDummyDepartureData.departure_city_code,
          departure_city_id: this.isDummyDepartureData.departure_city_id,
          departure_city_name: this.isDummyDepartureData.departure_city_name,
          shared_inventory_plan_Id: this.isDummyDepartureData.shared_inventory_plan_Id,
          vendor_share_plan: this.isDummyDepartureData.vendor_share_plan,
        }
        console.log("bodyOjectData====",bodyOjectData);
        this.apiService.onArrivalFlightList(bodyOjectData);
        this.manageDataService.SetSelectedDepartureData(bodyOjectData);
      }
    }
  }
}