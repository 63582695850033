import { Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { AmountPatternValidator } from 'src/app/services/amount-pattern-validator';
import { DatePipe } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedserviceService } from 'src/app/services/sharedservice.service';
import { Sort } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';


declare var $: any;

@Component({
   selector: 'app-supplierlist',
   templateUrl: './supplierlist.component.html',
   styleUrls: ['./supplierlist.component.scss'],
   providers: [Ng2SearchPipeModule,DatePipe]
})
export class SupplierlistComponent implements OnInit {
   creditVal: any=3;
   public environment = environment;
   @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
   sortedUser: any[]; submitted = false;
   crediBalance:any='';
   ccData: any;
   regForm: FormGroup;
   public page1: number = 1;
   userData: any;
   userdata: any;
   searchFlagReset: boolean = false;
   public addaccountValue: FormGroup;
   isAuto:boolean=false;
   bodydata: any = {}; TotalCount: any; data: any; keys: any;
   start = 0; msg: any; status: any; FormId: any; flag: boolean = false; searchText:any;

   add_b_sel_op:any=[
      { title: "Transaction Type", value: 3 },
      { title: "Give Credit", value: 0 },
      { title: "Add Balance", value: 1 },
    ]
    isAutoChange: any;
    refIdData: any;
    createApiData: any;
    myRefId: any;
   listidSupplier: any;

   public supplierMargin: FormGroup;marginsubmitted = false; marginbtnsubmitted: boolean = false;public partnerMargin: FormGroup;public agencyMargin: FormGroup;
   constructor(
      private formBuilder: FormBuilder,
      private ngxLoader: NgxUiLoaderService,
      public sharedService: SharedserviceService,
      private toastr: ToastrManager,private datePipe: DatePipe,
      public router: Router,public fb:FormBuilder
   ) {
      this.userdata = JSON.parse(localStorage.getItem("user"))
      this.sortedUser = this.ccData;
   }

   //*********shorting Start*********** */
   sortData(sort: Sort) {
      const data = this.ccData;
      if (!sort.active || sort.direction === '') {
         this.sortedUser = data;
         return;
      }
      this.sortedUser = data.sort((a, b) => {
         const isAsc = sort.direction === 'asc';
         switch (sort.active) {
            case 'name': return compare(a.name, b.name, isAsc);
            case 'email': return compare(a.email, b.email, isAsc);
            case 'mobile': return compare(a.mobile, b.mobile, isAsc);
            case 'state': return compare(a.state, b.state, isAsc);
            default: return 0;
         }
      });
      function compare(a: number | string, b: number | string, isAsc: boolean) {
         return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      }
   }
   applyFilter(event: Event) {
      $("#myInput").on("keyup", function () {
         var value = $(this).val().toLowerCase();
         $("#myTable1 tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         });
      });
   }

   ngOnInit() {
      this.bodydata['role_id'] = '2';
      this.bodydata['page'] = '1';
      this.bodydata['page_limit'] = 10;
      this.addaccountValue = this.formBuilder.group({
         supplier_id: ['', Validators.required],
         amount: ['', Validators.required],  narration: ['', Validators.required],
       })
      this.bodydata = {
         role_id: "2",
         page_limit: 10,
         page: this.bodydata['page'] = '1',
         search: ""
      }
      this.loaddata(this.bodydata);
      this.addaccountValue = this.formBuilder.group({
         "supplier_id": ['',Validators.required],
         "amount": ['',Validators.required],
         "narration": ['',Validators.required],
         "account_type": ['',Validators.required],
         "is_auto_reverse": ['',Validators.required]
      });
      this.viewDiscount();

  this.supplierMargin = this.fb.group({
    client_supplier_margin: ['', [Validators.required,AmountPatternValidator(/^[0-9]*(\.[0-9]{0,8})?$/)]],
  })
  this.partnerMargin = this.fb.group({
    partner_supplier_margin: ['', [Validators.required,AmountPatternValidator(/^[0-9]*(\.[0-9]{0,8})?$/)]],
  })
  this.agencyMargin = this.fb.group({
    agency_supplier_margin: ['', [Validators.required,AmountPatternValidator(/^[0-9]*(\.[0-9]{0,8})?$/)]],
  })
   }
   get fMargin() { return this.supplierMargin.controls; }
   get fPartnerMargin() { return this.partnerMargin.controls; }
   get fAgencyMargin() { return this.agencyMargin.controls; }


   loaddata(bodydata) {
      this.ngxLoader.start();
      // this.sharedService.post('v1/users/supplier_list', bodydata).subscribe((res) => {
      this.sharedService.post('v1/super/list', bodydata).subscribe((res) => {
         if (res.replyCode == 'success') {
            this.ccData = res.data;
            this.TotalCount = res.totalRecords;
            this.ngxLoader.stop();
            if (res.data.length > 0) {
               this.data = res.data;
               // this.data = res.data['rows'];
               this.keys = Object.keys(this.data[0]);
              //  this.TotalCount = res.data['count']
               this.ngxLoader.stop();

            } else {
               this.ngxLoader.stop();
               this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            }
         } else {
            this.ngxLoader.stop();
            this.flag = true;
            this.toastr.errorToastr(res.replyMsg, 'Error');
         }
      }, err => {
         this.ngxLoader.stop();
         this.toastr.errorToastr(err['error']['message'], 'Error');
      });
   }


   updateStatus(id, value) {

      if (value === true) {
         this.status = 1;
      } else {
         this.status = 0;
      }
      var self = this;
      var Object = {
         "supplier_id": id,
         "status": self.status.toString()
      }
      // self.sharedService.post('v1/users/active_supplier_status', Object).subscribe((res: any) => {
      self.sharedService.post('v1/super/change_status', Object).subscribe((res: any) => {
         if (!res.error) {
            self.loaddata(self.bodydata);
            self.toastr.successToastr(res.message, 'Success');
         } else {
            self.toastr.errorToastr(res.error.replyMsg, 'Error');
         }
      }, error => {
         self.toastr.errorToastr("Oops! Something went wrong!");
      });
   }


   userDtl(data) {
      this.userData = data;
      $('#viewUserList').modal('show');
   }


   onDelete(event) {
      this.FormId = event;
      $('#delete').modal('show');
   }

   onDeleteSubmit() {
      var self = this;
      const uploadData = new FormData();
      uploadData.append('id', this.FormId);
      var Object = {
         "supplier_id": this.FormId
      }
      // var url = 'v1/users/user_delete';
      var url = 'v1/super/user_delete';
      self.sharedService.post(url, Object).subscribe((res: any) => {
         if (!res.error) {
            self.loaddata(this.bodydata);
            self.toastr.successToastr(res.message, 'Success');
            $('#delete').modal('hide');
         } else {
            self.toastr.errorToastr(res.error.replyMsg, 'Error');
         }
      }, error => {
         self.toastr.errorToastr("Oops! Something went wrong!");
      });
   }


   onSearch() {
      this.searchFlagReset = true;
      this.bodydata['search'] = String(this.inputSearch.nativeElement.value);
      this.loaddata(this.bodydata);
   }

   onReset() {
      this.inputSearch.nativeElement.value = '';
      this.searchFlagReset = false;
      this.bodydata['search'] = '';
      this.loaddata(this.bodydata);
   }

   onPageChange(event) {
      this.bodydata['page'] = event;
      // console.log("event check value===========>>>",event);
      this.loaddata(this.bodydata);
   }

   clAccount() {
      $('#addBalance').modal('hide');
      $('#reverseList').modal('hide');
      this.addaccountValue.reset();
      this.submitted = false;
    }

    get ab() { return this.addaccountValue.controls }
    accountShow(list) {
      this.isAuto = false;
      // console.log("check value account list=========215", list);
      // var data = JSON.parse(sessionStorage.getItem("account_detail_data"))
      $('#addBalance').modal('show');
      // this.old_credit_limit = data.amount.toString()
      // this.old_available_balance = data.amount.toString()
      this.listidSupplier = list.id
      this.addaccountValue.patchValue({
       supplier_id:list.id,
      })
    }

    addPayment() {
      this.ngxLoader.start();
      var self = this;
      self.submitted = true;
      // if (self.addaccountValue.invalid) {
      //   return;
      // } else {
      //   if(this.submitted = true){
      //     return;
      //   }else{

          var data = self.addaccountValue.value;
          var request_data:any={
            "user_id":this.listidSupplier,
            "amount":data.amount,
            "account_type":"balance",
            "narration": data.narration
            // supplier_id: data.supplier_id,amount:data.amount ,
            // account_type: this.creditVal == 0?'credit':'balance',  // 'balance': to add balance, 'credit': to add credit
            // credit_reversal_date:this.crediBalance ==''?'':this.datePipe.transform(this.crediBalance, 'yyyy-MM-dd')   /* cannot be null if account_type = 'credit' */,
            // is_auto_reverse:this.isAuto
          }
          self.sharedService.postRequest('v1/super/account/add_supplier_payment', request_data).subscribe((res: any) => {
            if (!res.error) {
              this.ngxLoader.stop();
              if (res.replyCode == 'success') {
                this.crediBalance="";  this.creditVal=3;  this.data = res.data;  self.submitted = false;  $('#addBalance').modal('hide');
                self.addaccountValue.reset(); self.toastr.successToastr(res.replyMsg, 'Success');
              } else {
                self.submitted = false;
                this.crediBalance="";
                this.creditVal=3;
                self.addaccountValue.reset();
                $('#addBalance').modal('hide');
                self.toastr.errorToastr(res.replyMsg, 'Error');
              }
            } else {
              self.submitted = false;
              this.crediBalance="";
              this.creditVal=3;
              self.addaccountValue.reset();
              $('#addBalance').modal('hide');
              self.toastr.errorToastr(res.error.replyMsg, 'Error');
            }
          }, error => {
            this.ngxLoader.stop();
            self.submitted = false;
            this.crediBalance="";
            this.creditVal=3;
            self.addaccountValue.reset();
            $('#addBalance').modal('hide');
            self.toastr.errorToastr(error.error.replyMsg);
          });
      //   }
      // }
    }


   bookingView(data){
      // console.log("element :::::>>>>>>",data)
      var user_details=data
      // user_details.inv_id=data.id
      // user_details.status=data.status
      sessionStorage.setItem("supplierBooking",JSON.stringify(user_details))
      this.router.navigateByUrl("supplier/supplierBookingList" + '/' + user_details.id);
   }

   supplierAccount(list){
      // console.log("view account detail::::::::553",list);
      var dataAccount = list;
      sessionStorage.setItem("supplierAccountData",JSON.stringify(dataAccount))
      this.router.navigateByUrl("supplier/supplierAccount");
    }
    disType:any={};
    viewDiscount() {
      this.sharedService.get('v1/my_api_settiing/details').subscribe((res) => {
        if (res.replyCode == 'success') {
          this.disType = res.data;
        }
      }, err => {
        this.toastr.errorToastr(err.error.replyMsg, 'Error');
      });
    }
    onSubmitMargin(){
      this.marginsubmitted=true;
      if(this.supplierMargin.invalid){
        return;
      }else{
        this.marginbtnsubmitted=true;
        var request_data = this.supplierMargin.value;
            request_data.client_supplier_margin = Number(request_data.client_supplier_margin);
        //
        this.sharedService.postRequest('v1/my_api_settiing/edit_margin', request_data).subscribe((res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              this.supplierMargin.reset();
              this.marginsubmitted = false;
              this.marginbtnsubmitted = false;
              this.toastr.successToastr(res.replyMsg, 'Success');
              this.viewDiscount();
              $('#supplierMargin').modal('hide');
            } else {
              this.marginsubmitted = false;
              this.marginbtnsubmitted = false;
              this.toastr.errorToastr(res.replyMsg, 'Error');
            }
          } else {
            this.marginsubmitted = false;
            this.marginbtnsubmitted = false;
            this.toastr.errorToastr(res.error.replyMsg, 'Error');
          }
        }, error => {
          this.marginsubmitted = false;
          this.marginbtnsubmitted = false;
          this.toastr.errorToastr("Oops! Something went wrong!");
        });
      }
    }

    onSubmitPartnerMargin(){
      this.marginsubmitted=true;
      if(this.partnerMargin.invalid){
        return;
      }else{
        this.marginbtnsubmitted=true;
        var request_data = this.partnerMargin.value;
            request_data.partner_supplier_margin = Number(request_data.partner_supplier_margin);
        this.sharedService.postRequest('v1/my_api_settiing/edit_margin', request_data).subscribe((res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              this.partnerMargin.reset();
              this.marginsubmitted = false;
              this.marginbtnsubmitted = false;
              this.toastr.successToastr(res.replyMsg, 'Success');
              this.viewDiscount();
              $('#partnerMargin').modal('hide');
            } else {
              this.marginsubmitted = false;
              this.marginbtnsubmitted = false;
              this.toastr.errorToastr(res.replyMsg, 'Error');
            }
          } else {
            this.marginsubmitted = false;
            this.marginbtnsubmitted = false;
            this.toastr.errorToastr(res.error.replyMsg, 'Error');
          }
        }, error => {
          this.marginsubmitted = false;
          this.marginbtnsubmitted = false;
          this.toastr.errorToastr("Oops! Something went wrong!");
        });
      }
    }

    onSubmitAgencyMargin(){
      this.marginsubmitted=true;
      if(this.agencyMargin.invalid){
        return;
      }else{
        this.marginbtnsubmitted=true;
        var request_data = this.agencyMargin.value;
            request_data.agency_supplier_margin = Number(request_data.agency_supplier_margin);
        this.sharedService.postRequest('v1/my_api_settiing/edit_margin', request_data).subscribe((res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              this.agencyMargin.reset();
              this.marginsubmitted = false;
              this.marginbtnsubmitted = false;
              this.toastr.successToastr(res.replyMsg, 'Success');
              this.viewDiscount();
              $('#agencyMargin').modal('hide');
            } else {
              this.marginsubmitted = false;
              this.marginbtnsubmitted = false;
              this.toastr.errorToastr(res.replyMsg, 'Error');
            }
          } else {
            this.marginsubmitted = false;
            this.marginbtnsubmitted = false;
            this.toastr.errorToastr(res.error.replyMsg, 'Error');
          }
        }, error => {
          this.marginsubmitted = false;
          this.marginbtnsubmitted = false;
          this.toastr.errorToastr("Oops! Something went wrong!");
        });
      }
    }


    downloadSupplierList() {
      var request = {
        role_id: "2",
        page_limit: "",
        page: this.bodydata['page'] = '1',
        search: ""
      };

      this.sharedService.post('v1/super/list', request).subscribe((res: any) => {
          if (res.replyCode == "success") {
            this.data  = res.data;
            this.TotalCount = res.totalRecords;
          }
          setTimeout(() => {
              this.exportdata();
          }, 1000);
      }, err => {
      })
    }


    exportdata(): void {
      /* table id is passed over here */
      let element = document.getElementById('contentToConvert1');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      this.ngxLoader.stop();
      XLSX.writeFile(wb, 'Excel-Supplier-Report.xlsx');

    }



}
