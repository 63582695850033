import { Component, OnInit, ViewChild,ElementRef} from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';
import { Router} from '@angular/router';
import { SharedserviceService,CommonService } from 'src/app/services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiRequestService } from 'src/app/services/api.service';
import { ManagedataService } from 'src/app/services/manage-data.service';
declare var $: any;

@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {
  isAdvertismentData:any;
  deleteId:any;
  imagesizeerror1:boolean=false;
  imagesizeerror2:boolean=false;
  imagesizeerror3:boolean=false;
  imagesizeerror4:boolean=false;
  bannerdata:any={};
  imagePath:any;
  message:any;
  userimage:any;
  uploadimage1:any='';
  uploadimage2:any='';
  uploadimage3:any='';
  uploadimage4:any='';
  imagebasepath:any;
  imagebasepathAdvert:any;

  sortedState: any[];
  ccData:any;
  delete_data:any;
  bannerType:any;
  imageType:any;

  loadData:any;data:any;keys:any;public page: number = 1;start = 0;msg:any;
  public onEditformAdvrt: FormGroup;
  public advertisementForm: FormGroup;
  previewImage1 :any;
  previewImage2 :any;
  previewImage3 :any;
  previewImage4 :any;

  submitted = false;btnsubmitted:boolean=false;
   editFormFlag:boolean=false;uploadedImagesData: any;
   selectedFile1: File=null;
   selectedFile2: File=null;
   selectedFile3: File=null;
   selectedFile4: File=null;
   status:any;EditFormData:any;FormId:any;
   bodydata:any={};TotalCount:string;PackageStatus:any;Country:any;State:any;searchFlagReset:boolean=false;
   @ViewChild("inputSearch", {static: false}) inputSearch: ElementRef;
  isDataEditAdv: any;
  constructor(
    private formBuilder: FormBuilder,
    public sharedService: SharedserviceService,
    public router: Router,public commonService:CommonService,public apiService:ApiRequestService,public manageService:ManagedataService,
    private toastr: ToastrManager) 
    {
      this.sortedState = this.ccData;
    }

  edit(data){
      this.bannerdata.id=data['id'];
      this.bannerdata.oldimage=data.image;
      this.userimage=this.imagebasepath+'/'+data.image;
  }
    
  ngOnInit() {
    this.apiService.onAdverList();
    this.manageService.GetAdertisement().subscribe((data:any)=>{
      console.log("isAdvertismentData===============>>>>>>>>",data);
      if(data){
        this.isAdvertismentData = data.data;
        this.imagebasepathAdvert = data.imagePath
        // this.userimage = this.imagebasepathAdvert + "/" + this.isAdvertismentData[0].image;
        console.log("isAdvertismentData===============>>>>>>>>",this.imagebasepathAdvert);
      }
    })
   
    this.PackageStatus = '';
    this.onEditformAdvrt = this.formBuilder.group({
      dep_city:[''],
      arr_city:[''],
      mob_1   :[''],
      mob_2   :[''],
      pc_1    :[''],
      pc_2    :['']      
    });
    this.advertisementForm = this.formBuilder.group({
      dep_city:[''],
      arr_city:[''],
      mob_1   :[''],
      mob_2   :[''],
      pc_1    :[''],
      pc_2    :['']      
    });
  }
  get adValid(){ return this.advertisementForm.controls}
  // onSearch(){ this.searchFlagReset = true;  this.bodydata['search'] = String(this.inputSearch.nativeElement.value); this.loadList(this.bodydata); }
  // onReset(){  this.inputSearch.nativeElement.value='';  this.searchFlagReset = false; this.bodydata['search'] = ''; this.loadList(this.bodydata); }
  onPageChange(event){  
    this.bodydata['page_no'] = event;  
  }

  onCloseAddModal() { $('#addpackages').modal('hide');}
  addbannerImg(){
    this.advertisementForm.reset();
    this.onEditformAdvrt.reset();
    this.selectedFile1=null;
    this.selectedFile2=null;
    this.selectedFile3=null;
    this.selectedFile4=null;
    this.uploadimage1='';
    this.uploadimage2='';
    this.uploadimage3='';
    this.uploadimage4='';
    $('#addpackages').modal('show');
  }

  //File Upload Function
  onFileChanged1(event) {
    if( event.srcElement.files[0].size > 2000000){  this.imagesizeerror1=true; }
    else{
        this.selectedFile1 = event.target.files[0];  this.imagesizeerror1=false;
        const reader = new FileReader();
          if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              reader.readAsDataURL(file);  reader.onload = () => { this.uploadimage1 = reader.result as string; };
          }
    }
  }
  onFileChanged2(event) {
    if( event.srcElement.files[0].size > 2000000){  this.imagesizeerror2=true; }
    else{
        this.selectedFile2 = event.target.files[0];  this.imagesizeerror2=false;
        const reader = new FileReader();
          if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              reader.readAsDataURL(file);  reader.onload = () => { this.uploadimage2 = reader.result as string; };
          }
    }
  }
  onFileChanged3(event) {
    if( event.srcElement.files[0].size > 2000000){  this.imagesizeerror3=true; }
    else{
        this.selectedFile3 = event.target.files[0];  this.imagesizeerror3=false;
        const reader = new FileReader();
          if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              reader.readAsDataURL(file);  reader.onload = () => { this.uploadimage3= reader.result as string; };
          }
    }
  }
  onFileChanged4(event) {
    if( event.srcElement.files[0].size > 2000000){  this.imagesizeerror4=true; }
    else{
        this.selectedFile4 = event.target.files[0];  this.imagesizeerror4=false;
        const reader = new FileReader();
          if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              reader.readAsDataURL(file);  reader.onload = () => { this.uploadimage4 = reader.result as string; };
          }
    }
  }
  onFileChangedEdit1(event) {
    if( event.srcElement.files[0].size > 2000000){  this.imagesizeerror1=true; }
    else{
        this.selectedFile1 = event.target.files[0];  this.imagesizeerror1=false;
        const reader = new FileReader();
          if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              reader.readAsDataURL(file);  reader.onload = () => { this.uploadimage1 = reader.result as string; };
          }
    }
  }
  onFileChangedEdit2(event) {
    if( event.srcElement.files[0].size > 2000000){  this.imagesizeerror2=true; }
    else{
        this.selectedFile2 = event.target.files[0];  this.imagesizeerror2=false;
        const reader = new FileReader();
          if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              reader.readAsDataURL(file);  reader.onload = () => { this.uploadimage2 = reader.result as string; };
          }
    }
  }
  onFileChangedEdit3(event) {
    if( event.srcElement.files[0].size > 2000000){  this.imagesizeerror3=true; }
    else{
        this.selectedFile3 = event.target.files[0];  this.imagesizeerror3=false;
        const reader = new FileReader();
          if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              reader.readAsDataURL(file);  reader.onload = () => { this.uploadimage3= reader.result as string; };
          }
    }
  }
  onFileChangedEdit4(event) {
    if( event.srcElement.files[0].size > 2000000){  this.imagesizeerror4=true; }
    else{
        this.selectedFile4 = event.target.files[0];  this.imagesizeerror4=false;
        const reader = new FileReader();
          if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              reader.readAsDataURL(file);  reader.onload = () => { this.uploadimage4 = reader.result as string; };
          }
    }
  }
  //File Upload Function

  //Banner Add 
  onAddForm() {
    this.submitted = true;
    this.btnsubmitted = true;
    const uploadData = new FormData();
    
    // Appending images to FormData
    if (this.selectedFile1 == null) {  
        uploadData.append('mob_1', '');  
    } else {
        uploadData.append('mob_1', this.selectedFile1, this.selectedFile1.name);  
    }
    if (this.selectedFile2 == null) {  
        uploadData.append('mob_2', '');  
    } else {
        uploadData.append('mob_2', this.selectedFile2, this.selectedFile2.name);  
    }
    if (this.selectedFile3 == null) {  
        uploadData.append('pc_1', '');  
    } else {
        uploadData.append('pc_1', this.selectedFile3, this.selectedFile3.name);  
    }
    if (this.selectedFile4 == null) {  
        uploadData.append('pc_2', '');  
    } else {
        uploadData.append('pc_2', this.selectedFile4, this.selectedFile4.name);  
    }
    uploadData.append('dep_city', this.advertisementForm.value.dep_city);
    uploadData.append('arr_city', this.advertisementForm.value.arr_city);
    this.sharedService.postRequest('v1/ad/add', uploadData).subscribe((res: any) => {
        if (!res.error) {
            if (res.replyCode == 'success') {
                this.apiService.onAdverList();
                this.submitted = false;
                this.btnsubmitted = false;
                $('#addpackages').modal('hide');
                this.advertisementForm.reset();
                this.toastr.successToastr(res.replyMsg, 'Success');
            } else {
                this.btnsubmitted = false;
                this.toastr.errorToastr(res.replyMsg, 'Error');
            }
        } else {
            this.btnsubmitted = false;
            this.toastr.errorToastr(res.error.replyMsg, 'Error');
        }
    }, error => {
        this.btnsubmitted = false;
        this.toastr.errorToastr("Oops! Something went wrong!");
    });
}

 

  onDelete(data){
    this.deleteId = data;
    $('#delete').modal('show');
  }
  confirmDel(){
    this.apiService.onDeleteAdvertisment({id:this.deleteId});
    setTimeout(() => {
      this.apiService.onAdverList();
      $('#delete').modal('hide');
    }, 200);
  }
  onDeleteClose(){
    $('#delete').modal('hide');
  }

  onEditData(data:any){
    this.onEditformAdvrt.reset();
    this.uploadimage1=''
    this.uploadimage2=''
    this.uploadimage3=''
    this.uploadimage4=''
    this.isDataEditAdv = data;
    console.log("data=============-------257-------======>>>>>>",data);
    this.uploadimage1 = this.imagebasepathAdvert + "/" + this.isDataEditAdv.mob_1;
    this.uploadimage2 = this.imagebasepathAdvert + "/" + this.isDataEditAdv.mob_2;
    this.uploadimage3 = this.imagebasepathAdvert + "/" + this.isDataEditAdv.pc_1;
    this.uploadimage4 = this.imagebasepathAdvert + "/" + this.isDataEditAdv.pc_2;
    this.onEditformAdvrt.patchValue({
      dep_city:this.isDataEditAdv.dep_city,
      arr_city:this.isDataEditAdv.arr_city,
      // mob_1:this.isDataEditAdv.mob_1,
      // mob_2:this.isDataEditAdv.mob_2,
      // pc_1:this.isDataEditAdv.pc_1,
      // pc_2:this.isDataEditAdv.pc_2,
      id:this.isDataEditAdv.id
    })
   
    $('#editpackages').modal('show');
  }
  onClosesEdit(){
    $('#editpackages').modal('hide');
  }
  onEdit() {
    const uploadData = new FormData();
    this.addImageToUploadData(this.selectedFile1, 'mob_1', uploadData, 'uploadimage1');
    this.addImageToUploadData(this.selectedFile2, 'mob_2', uploadData, 'uploadimage2');
    this.addImageToUploadData(this.selectedFile3, 'pc_1', uploadData, 'uploadimage3');
    this.addImageToUploadData(this.selectedFile4, 'pc_2', uploadData, 'uploadimage4');
    
    uploadData.append('dep_city', this.onEditformAdvrt.value.dep_city);
    uploadData.append('arr_city', this.onEditformAdvrt.value.arr_city);
    uploadData.append('id', this.isDataEditAdv.id);
    setTimeout(() => {
      this.sharedService.postRequest('v1/ad/edit', uploadData).subscribe((res: any) => {
        if (res.replyCode == "success") {
          $('#editpackages').modal('hide'); 
          this.apiService.onAdverList();
        } else {
          this.toastr.errorToastr(res.error.replyMsg, 'Error'); 
        }
      }, error => {
        this.toastr.errorToastr("Oops! Something went wrong!"); 
      });
    }, 500);
}

addImageToUploadData(selectedFile, key, uploadData, uploadImage) {
  if (selectedFile == null) {  
      uploadData.append(key, '');  
  } else {
      uploadData.append(key, selectedFile, selectedFile.name); 
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (_event) => {
          this[uploadImage] = reader.result;
      };
  }
}

  onClose(){  this.uploadimage1='';this.uploadimage2='';this.uploadimage3='';this.uploadimage4=''; this.submitted=false; }
}
