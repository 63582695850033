import * as RedirectionPath from '../../common/redirect';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService, SharedserviceService } from 'src/app/services';
import { DatePipe} from '@angular/common';
import { FormBuilder} from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-history-view',
  templateUrl: './history-view.component.html',
  styleUrls: ['./history-view.component.scss'],
  providers: [DatePipe, NgxUiLoaderService,Ng2SearchPipeModule]
})
export class HistoryViewComponent implements OnInit {
  public environment = environment;
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
  searchText:any;
  sort_reference_id: any = "desc";
  sort_total_amount: any = "desc";
  checkTraveler: any = [];
  delBox: boolean = false;
  userlist: any;
  flightData: any;
  returnFlight: any; flt_bokg_dtl: any;
  returnFlightd: any;
  cancelticket: any;
  return_d_date: any;
  rreturn_d_date: any;
  agentReport: any;
  btnsubmitted: boolean = false;
  Type: any;
  bodydata: any = {}; data: any; keys: any; TotalCount: any; msg: any; ccData: any;
  flag: boolean = false;
  clientView: any;
  nodatafound: boolean = false;
  page: any = {};
  totalpage: any;
  profileImg: any;
  sortedBook: any[];
  userdata: any;
  sectordata: any;
  searchFlagReset: boolean = false;
  p: number = 1;
  bgBannerPath: any;

  userid: any = "";
  todate: any = "";
  formdate: any = "";
  sector: any = "";
  type: any = "";
  filterstatus: any = "";
  imgPath: any;
  imgurl: any;
  sellerDtl: boolean = false;
  bookData: any;
  headBner: boolean = false;
  userParam: any;
  imgUrl: any;
  booking_id: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public router: Router,
    public commonService: CommonService,
    private toastr: ToastrManager,
    public activatedRoute: ActivatedRoute) {
    this.dateAdapter.setLocale('en-GB');
    this.activatedRoute.params.forEach((urlParams) => {
      this.Type = urlParams['type'];
      this.userParam = urlParams['id'];
      if (this.Type) {
        this.filterstatus = this.Type;
      }

      this.sortedBook = this.ccData;
      this.userdata = JSON.parse(localStorage.getItem("user"));
      this.imgUrl = this.sharedService.serviceBase
      this.profileImg = this.imgUrl + 'uploads/userprofile';


    });
  }

  ngOnInit() {
    this.bodydata['role_id'] = '3';

    this.SectorList();
    this.page = 1;
    this.bodydata = {
      page_limit: 10,
      page_no: this.bodydata['page_no'],
      search: "",
      flight_type: "",
      sector: "",
      display_type: "",
      status: "",
      start_date: "",
      end_date: "",
      order_key: "departure_date",
      order_value: "desc",
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB"
    }
    this.bodydata['page_no'] = 1;
    this.filtersearch(this.bodydata);
  }

  applyFilter(event: Event) {
    $("#myInput").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#myTable1 tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });
  }

  sellerInfo(event) {
    if (event.checked == true) {
      this.sellerDtl = true;
    } else {
      this.sellerDtl = false;
    }
  }

  delCheckBox() {
    if (this.delBox == false) {
      this.delBox = true;
    } else {
      this.delBox = false;
    }
  }

  sortfun(orderkey, type) {
    if (orderkey == 'reference_id' && type == 'asc') {
      this.sort_reference_id = 'asc';
    } else {
      this.sort_reference_id = 'desc';
    }
    if (orderkey == 'total_amount' && type == 'asc') {
      this.sort_total_amount = 'asc';
    } else {
      this.sort_total_amount = 'desc';
    }
    this.searchFlagReset = true;
    var DataSearch = {
      page_limit: 10,
      page_no: "",
      search: "",
      status: "",
      flight_type: "",
      sector: "",
      start_date: "",
      end_date: "",
      order_key: orderkey,
      order_value: type,
      user_id: this.userParam,
      display_type: "my_api",
      type: "api",
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB"
    }
    this.filtersearch(DataSearch);

  }

  fbdCheck(i, event) {

    if (event.srcElement.checked == false) {

      this.checkTraveler.forEach((element, index) => {
        if (element.id == i) this.checkTraveler.splice(index, 1);
      });
    } else {

      this.checkTraveler.push({ id: i })
    }

  }

  download() {
    this.ngxLoader.start();
    var data = {
      todate: this.todate,
      formdate: this.formdate,
      sector: this.sector,
      type: this.Type,
      status: this.filterstatus,
      user_id: this.userid
    }
    var formdate;
    var todate;
    if (this.formdate != '') {
      formdate = this.datePipe.transform(new Date(this.formdate), 'yyyy-MM-dd');
    } else {
      formdate = ''
    }
    if (this.todate != '') {
      todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
    } else {
      todate = ''
    }
    var requestdata = {
      page_limit: '',
      page_no: this.bodydata['page_no'],
      search: "",
      user_id: this.userParam,
      status: this.filterstatus,
      flight_type: this.type,
      sector: this.sector,
      start_date: formdate,
      display_type: "",
      end_date: todate,
      order_key: this.bodydata.order_key,
      order_value: this.bodydata.order_value,
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB"
    }
    this.sharedService.post('v1/booking/upcoming_booking_list', requestdata).subscribe((res) => {

      if (res.replyCode == 'success') {
        this.ccData = res.data;
        if (res.data.length > 0) {
          this.flag = false;
          this.data = res.data;
          this.keys = Object.keys(this.data[0]);
          this.TotalCount = res.totalRecords;
          this.imgurl = res.imgBasePath
          setTimeout(() => {
            // $('#Exportmodal').modal('show');
            this.exportdata();
          }, 1000);

          this.ngxLoader.stop();
        } else {
          this.flag = true;
          this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
        }

        this.ngxLoader.stop();
      } else {
        this.ngxLoader.stop();
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
      this.nodatafound = true;
    });
  }

  exportdata(): void {
    /* table id is passed over here */
    let element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Excel-Report.xlsx');

  }



  filtersearch(bodydata) {
    this.ngxLoader.start();
    var data = {
      todate: this.todate,
      formdate: this.formdate,
      sector: this.sector,
      type: this.Type,
      status: this.filterstatus,
      user_id: this.userid
    }
    var formdate;
    var todate;
    if (this.formdate != '') {
      formdate = this.datePipe.transform(new Date(this.formdate), 'yyyy-MM-dd');
    } else {
      formdate = ''
    }
    if (this.todate != '') {
      todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
    } else {
      todate = ''
    }
    var requestdata = {
      page_limit: 10,
      page_no: this.bodydata['page_no'],
      search: "",
      user_id: this.userParam,
      status: this.filterstatus,
      flight_type: this.type,
      sector: this.sector,
      start_date: formdate,
      display_type: "",
      end_date: todate,
      order_key: bodydata.order_key,
      order_value: bodydata.order_value,
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB"
    }
    this.sharedService.post('v1/booking/upcoming_booking_list', requestdata).subscribe((res) => {

      if (res.replyCode == 'success') {
        this.ccData = res.data;
        if (res.data.length > 0) {
          this.flag = false;
          this.data = res.data;
          this.keys = Object.keys(this.data[0]);
          this.TotalCount = res.totalRecords;
          this.imgurl = res.imgBasePath

          this.ngxLoader.stop();
        } else {
          this.flag = true;
          this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
        }

        this.ngxLoader.stop();
      } else {
        this.ngxLoader.stop();
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
      this.nodatafound = true;
    });
  }





  SectorList() {
    var sendSector = {
      user_id: this.userParam,
      display_type: "my_api",
      type: "api"
    }
    this.sharedService.post('v1/booking/my_booking_sector', sendSector).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.sectordata = res.data;
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['replyMsg'], 'Error');
    });
  }

  // viewDetail(element) {
  //   var self = this;
  //   var Objects = {
  //     user_id: this.userdata.id,
  //     flight_booking_id: element.flight_booking_id
  //   }
  //   this.sharedService.post('v1/flight/flight_booking_details', Objects).subscribe((res) => {
  //     if (res.replyCode == 'success') {
  //       var counter = 0;
  //       this.bgBannerPath = res.bannerimgBasePath;
  //       setTimeout(() => {
  //         this.imgPath = res.imgBasePath;
  //         res.data.flight_booking_details.forEach(element => {

  //           if (element.ticket_cancel_at == null) {
  //             element.t_status = 'booked'
  //           } else {
  //             counter += 1;
  //             element.t_status = 'cancelled'
  //           }
  //         });
  //         if (counter == res.data.total_book_seats) {
  //           res.data.ticket_status = "cancelled"
  //         } else {
  //           res.data.ticket_status = "booked"
  //         }
  //         this.bookData = res.data;

  //         this.bookData.cal_base_fare = ((this.bookData.flightData.flight_fare_taxes[0].base_fare * (this.bookData.adult + this.bookData.children)) + (this.bookData.flightData.infant_base_price * this.bookData.infant));
  //         this.bookData.cal_fees_and_taxes = (this.bookData.flightData.flight_fare_taxes[0].fee_taxes * (this.bookData.adult + this.bookData.children));
  //         this.bookData.servicecharge = (this.bookData.total_amount - (this.bookData.cal_base_fare) - (this.bookData.cal_fees_and_taxes));

  //         this.bookData.ticket_id = element.flight_booking_id;
  //         this.flightData = res.data;
  //         self.returnFlight = res.data.flightData.return_flight;
  //         self.flt_bokg_dtl = res.data.flight_booking_details;
  //       }, 100);
  //       $('#genrepo').modal('show');
  //     }
  //   });

  // }

  viewDetail(element){
    this.booking_id = element.flight_booking_id
    sessionStorage.setItem("booking_id", JSON.stringify(this.booking_id));
    window.open(RedirectionPath.processUrl('booking-summary'), '_blank');
  }

  durationdiff(dep_time, arr_time, dep_date, type) {
    var dep_hr = parseInt(dep_time.split(':')[0]);
    var arr_hr = parseInt(arr_time.split(':')[0]);
    if (arr_hr < dep_hr) {
      var nwdate;
      if (!dep_date) {
        nwdate = new Date();
      } else {
        nwdate = new Date(dep_date);
      }

      var newdate = nwdate.setDate(nwdate.getDate() + 1);
      if (type == 'oneway') {
        this.return_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
      } else {
        this.rreturn_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
      }

    } else {
      this.return_d_date = dep_date;
      this.rreturn_d_date = dep_date;
    }
    var start_date = dep_date + " " + dep_time;
    var end_date = this.return_d_date + " " + arr_time;
    return this.getDataDiff(new Date(start_date.toString()), new Date(end_date.toString()));

  }
  //----------------Download PDF--------------
  // generatePDF1() {
  //   var data = document.getElementById('contentToConvert1');
  //   this.ngxLoader.start();
  //     html2canvas(data).then(canvas => {
  //       var imgWidth = 208;
  //       var imgHeight = canvas.height * imgWidth / canvas.width;
  //       const contentDataURL = canvas.toDataURL('image/png')
  //       let pdf = new jspdf('p', 'mm', 'a4');
  //       var position = 5;
  //       pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //       pdf.save('download.pdf');
  //     });
  //   this.ngxLoader.stop();
  // }

  printpreview() {
    var printW = window.open("");
    var html = document.getElementById('contentToConvert1').innerHTML;

    printW.document.write('<html><head><title>Booking Details</title>');
    printW.document.write(`<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" type="text/css" />`);
    printW.document.write(`<style>
     .col-3 {-webkit-box-flex: 0;flex: 0 0 25%;max-width: 25%;}
     .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
     table td{border-bottom:1px solid#ddd;padding:5px;border-collapse: collapse;} table th {border-collapse: collapse;border-bottom:1px solid#ddd;padding:5px;font-weight: 600;color: #000;} table{ width: 100%; border-spacing:1px;border-collapse: separate;}.row{display: flex;flex-wrap: wrap;width:100%;}.col-md-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}.img-box {width: 50px;height: 50px;border: 1px solid #ddd;object-fit:cover;}
     .border {border: 1px solid #dee2e6!important;}
     table{margin-bottom:5px !important;}
     .ph-text{height:220px !important;font-size:10px !important;line-height:13px !important;}
     .row {display: -webkit-box;display: flex;flex-wrap: wrap;}
     .text-left {text-align: left!important;}
     .img-profile {width: 100%;height: auto;-o-object-fit: contain;object-fit: contain;max-height: 70px;}
      .text-center {text-align: center!important;}
      .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
      .head-clr {color: #ff6a00;}.fw-500 {font-weight: 500;}
      .m-0 {margin: 0!important;}.w-100 {width: 100%!important;}.text-center {text-align: center!important;}
      .bg-white {background-color: #fff!important;}
      table {border-collapse: collapse;}
      .table {width: 100%;color: #212529;}
      .col-lg-4 {-webkit-box-flex: 0;flex: 0 0 33.333333%;max-width: 33.333333%;}
      .wp-100{width:100%;} .wp-30{width:30% !important;}
      .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {margin-bottom:.5rem !important;font-weight: 500;line-height: 1.2;}
      .mytbl td {padding: 0.5rem;margin-bottom: 0rem !important;}
      .mytbl td {padding: 0.5rem !important;vertical-align: middle;padding-left: 0.75rem !important;}
      .table td, .table th {padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;}
      .border-bottom {border-bottom: 1px solid #dee2e6!important;}
      .img-box-fix {width: 50px;height: 30px;-o-object-fit: contain;object-fit: contain;}
      img {vertical-align: middle;border-style: none;}
      .textclr {color: #828282;}
      .ft-12 {font-size: 12px !important;}
      .align-self-center {align-self: center!important;}
      .table-responsive {display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
      .d-none {display: none!important;}.text-dark {color: #343a40!important;}.h6, h6 {font-size: 1rem;}
      .form-control:disabled, .form-control[readonly] {background-color: #e9ecef !important;opacity: 1;}
      .form-control {display: block;width: 100%;height: calc(1.5em + .75rem + 2px);padding: .375rem .75rem;font-size: 1rem;
        font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: .25rem;-webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
      .prnt-h{line-height:32px !important;}
      .form-control-1{border:1px solid#ddd;border-radius:5px;background-color:#e9ecef;color:#303030;padding:8px;}
      .w-10pr{width:10%;}.w-14pr{width:14%;}.w-25pr{width:25%;}
      .pmt-10{margin-top:10px !important;}
      .mytbl tr td{padding:2px;}
      .p-Size{font-size:12px;}.pmb-1{margin-bottom:10px !important;}.pmb-2{margin-bottom:15px !important; }
      .mytbl tr td{padding:2px;}.pmt-1{margin-bottom:10px !important;}.pmt-2{margin-bottom:15px !important; }
      .pr-dnone{display:none !important;}
      </style>`);
    printW.document.write('</head><body >');
    printW.document.write(html);
    printW.document.write('</body></html>');



    printW.document.close();
    printW.focus();
    printW.print();
    // if(this.commonService.osname != "Mac OS"){
    //   printW.close();
    // }else{
    //   console.log("not Windows os")
    // }
  }

  getDataDiff(s_date, e_date) {
    var diff = e_date.getTime() - s_date.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return hours + " hrs " + ": " + minutes + " min";
  }


  onSearch() {
    this.ngxLoader.start();
    this.searchFlagReset = true;
    var requestdata = {
      user_id: this.userParam,
      page_limit: 10,
      page_no: "",
      search: String(this.inputSearch.nativeElement.value),
      status: this.filterstatus,
      flight_type: "",
      display_type: "",
      sector: "",
      start_date: "",
      end_date: "",
      order_key: "departure_date",
      order_value: "desc",
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB"
    }
    this.filtersearch(requestdata);

  }

  onReset() {
    this.inputSearch.nativeElement.value = '';
    this.bodydata['search'] = '';
    this.searchFlagReset = false;
    this.filtersearch(this.bodydata)
  }

  onPageChange(event) {

    this.bodydata['page_no'] = event;
    this.filtersearch(this.bodydata);
  }

}
