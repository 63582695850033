import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomedashboardRoutingModule } from './homedashboard-routing.module';
import { NgModule } from '@angular/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SharedModule } from '../_shared/_shared.module';
import { HomeComponent } from '../dashboard';

// import { GenericListFilterModule } from 'generic-list-filter';


@NgModule({
  declarations: [DashboardComponent,HomeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HomedashboardRoutingModule,NgxUiLoaderModule,SharedModule
  ]
})
export class HomedashboardModule { }
