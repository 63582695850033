import { Component, HostListener, OnInit } from '@angular/core';

import { ApiRequestService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services';
import { DatePipe } from '@angular/common';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { Options } from "@angular-slider/ngx-slider";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-search-flight-result',
  templateUrl: './search-flight-result.component.html',
  styleUrls: ['./search-flight-result.component.scss'],
  providers: [DatePipe]
})
export class SearchFlightResultComponent implements OnInit {
  getData: any = {};
  public environment = environment;
  count = 10;
  flightdetailsFromBackend: any = [];
  flightdetails: any = [];
  flightdetailsOnword: any = [];
  flightdetailsReturn: any = [];
  isFitGroupData: any = []; bookingToken: any = '';
  isSelectIndexValue: any = '0';
  fareRules: any = [];
  isLoggedInUser: any = {};
  return_d_date: any; rreturn_d_date: any;
  isTravelsObject: any = {};
  imageUrl: string = '';
  isAccountBalance: number = 0;
  isMasterOptions: any;
  selectedFlightRule: any;
  isTotalPassenger: any;
  isOnwordFlight:boolean=true;
  isLastUserActivity:string=''
  SearchingFlightRecordChecked: boolean = false;
  isCurrentTime:string='';
  Timings: any = [
    { val: '1', name: "Before 6 Am", is_selected: false, value: "00:00-05:59" },
    { val: '2', name: "6 Am - 12 Pm", is_selected: false, value: "06:00-11:59" },
    { val: '3', name: "12 Pm - 6 Pm ", is_selected: false, value: "12:00-17:59" },
    { val: '4', name: "After 6 Pm", is_selected: false, value: "18:00-23:59" }
  ]
  InTimings: any = [
    { val: '1', name: "Before 6 Am", is_selected: false, value: "00:00-05:59" },
    { val: '2', name: "6 Am - 12 Pm", is_selected: false, value: "06:00-11:59" },
    { val: '3', name: "12 Pm - 6 Pm ", is_selected: false, value: "12:00-17:59" },
    { val: '4', name: "After 6 Pm", is_selected: false, value: "18:00-23:59" }
  ]
  partnerSearch: string = 'normal'; Airlines: any = []; Destinations: any = [];
  Stops: any = [
    { val: '0', name: "Default", is_selected: false }, { val: '1', name: "1 Stop", is_selected: false }, { val: '2', name: "2 Stop", is_selected: false }
  ];
  minValue: number = 20;
  maxValue: number = 80;
  options: Options = { floor: 0, ceil: 100 };
  Refundable = { val: "REFUNDABLE", name: "Refundable Fare", is_selected: false };
  isAirlinesList: any = [];
  isAirlinesStopList: any = [];
  isFareRuleData: any;
  isRefundableFareFlag: boolean = false;
  isNonRefundableFareFlag: boolean = false;
  filterAirlinesResponse: any = [];
  isLoggedInStatus: boolean = false;
  labelName: string;
  isVisable: boolean=false;
  seachingAirlinesArray: any = []; seachingAirlinesStopArray: any = [];
  devide_hour: number;
  isOnwordDataSelect: any;
  isReturnDataSelect: any;
  isOnwordShow: boolean;
  isReturnValue: boolean;
  constructor(public commonService: ManagedataService, private datePipe: DatePipe, public router: Router, public dataservice: CommonService, public apiService: ApiRequestService) {
    // if(sessionStorage.getItem('search_form') != undefined && sessionStorage.getItem('search_form') != null && sessionStorage.getItem('search_form') != "" ){
    //   var JsonData:any=sessionStorage.getItem('search_form');
    //   this.isTotalPassenger = JSON.parse(JsonData).travellers
    //   console.log("isTotalPassenger=====>>>",this.isTotalPassenger);
    // }
    this.commonService.GetMasterTripOptions().subscribe((data: any) => {
      if (data) {
        this.isMasterOptions = data;
      }
    })
  }
  sliderEvent() {
    this.applyFilter();
  }
  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {
      this.count += 10;
      // this.lazyscroll = [...this.lazyscroll, this.count+=10];
    }
  }
  onApply() {
    var timeArray: any = [];
    var filterTimeData = this.Timings.filter((x: any) => x.is_selected);
    if (filterTimeData.length > 0) {
      filterTimeData.forEach((element: any) => {
        var split_time = element.value.split('-');
        var obj = { min: '', max: '' };
        obj.min = split_time[0];
        obj.max = split_time[1];
        timeArray = timeArray.concat(obj);
        timeArray.forEach((elementsssssss: any) => {
          this.flightdetails = this.filterAirlinesResponse.filter((item: any) => (item.departure_time >= elementsssssss.min) && (item.departure_time <= elementsssssss.max))
        });
      });
    }
  }
  hideList(indexValue:any){
    // console.log("indexValue======>>>>",$("#"+indexValue).prev('div.testing'));
    // if(indexValue){
      if($("#"+indexValue).prev('div.testing').find('.priceBox').hasClass('hidden-div')){
        this.isVisable=true;
        $("#"+indexValue+" i").removeClass('fa-chevron-down').addClass('fa fa-chevron-up')
        $("#"+indexValue).prev('div.testing').find("div.priceBox").addClass('visible-div').removeClass('hidden-div')
      }else{
        this.isVisable=false;
        $("#"+indexValue+" i").removeClass('fa-chevron-up').addClass('fa fa-chevron-down')
        $("#"+indexValue).prev('div.testing').find("div.priceBox:not(div.priceBox:nth-child(1)):not(div.priceBox:nth-child(2)):not(div.priceBox:nth-child(3))").addClass('hidden-div').removeClass('visible-div')
      }
    // }
  }
  ngOnInit(): void {

    // this.commonService.GetLoggedInUser().subscribe(data => this.isLoggedInUser=data);
    // this.commonService.GetLoggedInStatus().subscribe(data => this.isLoggedInStatus=data);
    // this.commonService.GetSearchingFlightRecordChecked().subscribe((data:any)=>{this.SearchingFlightRecordChecked=data})
    this.commonService.GetFlightImagePath().subscribe((data: any) => { this.imageUrl = data })
    // this.commonService.GetSearchFlightResponse().subscribe((data: any) => {
    //   this.imageUrl = data.imgBasePath;
    //   if (Object.keys(data).length > 0) {
    //     var groupByflightNumber = [];
    //     if(this.isMasterOptions==2){
    //       this.flightdetails = data.data || data.onword;
    //       this.flightdetails.sort(function (a, b) { return a.fares[0].net_amount - b.fares[0].net_amount })
    //       this.flightdetails.forEach((ele: any) => {
    //         if (!groupByflightNumber.find(x => x.flight_number == ele.flight_number)) {
    //           /// IF FLIGHT NUMBER NOT PRESENT, INSERTING THE DATA
    //           groupByflightNumber.push(ele);
    //         } else {
    //           /// IF FLIGHT NUMBER IS PRESENT, PUSH THE FARE OBJECT
    //           var i = groupByflightNumber.findIndex(x => x.flight_number == ele.flight_number);
    //           groupByflightNumber[i].fares.push(ele.fares[0]);
    //         }
    //       });
    //       this.flightdetails = groupByflightNumber
    //       this.filterAirlinesResponse = groupByflightNumber;
    //     }else{
    //       this.flightdetails = data.data;
    //       this.flightdetails.sort(function (a, b) { return a.final_pay_amount - b.final_pay_amount })
    //     }
    //     this.bookingToken = data.booking_token;
    //     this.flightdetails.forEach((element: any) => {
    //       Object.assign(element, { selectedFight: {} });
    //       this.isAirlinesList.push(element.airline_name);
    //       this.isAirlinesStopList.push(element.no_of_stop);
    //     });
    //     if (this.isMasterOptions == 2) {
    //       var JsonData: any = sessionStorage.getItem('search_form');
    //       this.isTotalPassenger = JSON.parse(JsonData).travellers
    //       console.log("isTotalPassenger=====>>>", this.isTotalPassenger);
    //       this.flightdetails.forEach((element: any) => {
    //         element.selectedFight = element.fares[0];
    //       });
    //       this.minValue = Math.min(...this.flightdetails.map((item: any) => item.fares[0].net_amount));
    //       this.maxValue = Number(Math.max(...this.flightdetails.map((item: any) => item.fares[0].net_amount)));
    //       // this.commonService.SetSearchingFlightLoaderStatus(false);
    //       this.options = {
    //         floor: this.minValue,
    //         ceil: this.maxValue
    //       }
    //     }
    //     console.log("flightdetails=======1111111====>>>>>>>>>", this.flightdetails);
    //   } else {
    //     this.flightdetails = [];
    //   }
    // })

    this.commonService.GetSearchFlightResponse().subscribe((data: any) => {
      this.count = 10;
      this.imageUrl = data.imgBasePath;
      // if (Object.keys(data).length > 0) {
      if (data.length > 0) {
        var groupByflightNumber: any = [];
        this.flightdetails = data.data;
        this.flightdetails.sort(function (a: any, b: any) { return a.final_pay_amount - b.final_pay_amount })
        this.bookingToken = data.booking_token;
        this.flightdetails.forEach((element: any) => {
          Object.assign(element, { selectedFight: {} });
          this.isAirlinesList.push(element.airline_name);
          this.isAirlinesStopList.push(element.no_of_stop);
        });
        // console.log("flightdetails=======1111111====>>>>>>>>>", this.flightdetails);
      } else {
        this.flightdetails = [];
      }
    })

    this.commonService.GetSearchFlightOnewayResponse().subscribe((data: any) => {
      this.count = 10;
      this.imageUrl = data.imgBasePath;
      setTimeout(() => {
        // if(sessionStorage.getItem('search_form_Data')!='' || sessionStorage.getItem('search_form_Data')!=undefined || sessionStorage.getItem('search_form_Data')!=null){}
        if(this.isMasterOptions==2){
      var JsonData: any = sessionStorage.getItem('search_form_Data');
      var adult_child = JSON.parse(JsonData).formValue.adult + JSON.parse(JsonData).formValue.child
      // console.log("data.length============>>>>>",data.data.length);
      if (data.data != '') {
        var groupByflightNumber: any = [];
        if (this.isMasterOptions == 2) {
          this.flightdetailsOnword = data.data;
          // console.log('this.flightdetailsOnword ============================= 253 ===', this.flightdetailsOnword[0]);

          // this.flightdetailsOnword.sort(function (a:any, b:any) { return a.fares[0].net_amount - b.fares[0].net_amount })

          /// FOR MULTI PRICE DISPLAY ///
          this.flightdetailsOnword.forEach((ele: any) => {
            if (!groupByflightNumber.find((x: any) => x.flight_number == ele.flight_number)) {
              /// IF FLIGHT NUMBER NOT PRESENT, INSERTING THE DATA
              if (ele.fares[0].total_available_seats >= adult_child) {
                groupByflightNumber.push(ele);
              }
            } else {
              /// IF FLIGHT NUMBER IS PRESENT, PUSH THE FARE OBJECT
              var i = groupByflightNumber.findIndex((x: any) => x.flight_number == ele.flight_number);

              /// IF SAME FARE TYPE IS PRESENT ///
              var v = groupByflightNumber[i].fares.findIndex((x: any) => x.fare_type == ele.fares[0].fare_type);
              if (v >= 0) {
                if (groupByflightNumber[i].fares[v].net_amount > ele.fares[0].net_amount && ele.fares[0].total_available_seats >= adult_child) {
                  groupByflightNumber[i].fares.splice(v, 1);
                  groupByflightNumber[i].fares.push(ele.fares[0]);
                }
              } else {
                if (ele.fares[0].total_available_seats >= adult_child) {
                  groupByflightNumber[i].fares.push(ele.fares[0]);
                }
              }
            }
          });

          /// FOR SINGLE PRICE DISPLAY ///
          // this.flightdetailsOnword.forEach((ele: any) => {
          //   if (!groupByflightNumber.find((x: any) => x.flight_number == ele.flight_number && x.fares[0].fare_type == ele.fares[0].fare_type)) {
          //     /// IF FLIGHT NUMBER NOT PRESENT, INSERTING THE DATA
          //     if (ele.fares[0].total_available_seats >= adult_child) {
          //       groupByflightNumber.push(ele);
          //     }
          //   } else {
          //     /// IF FLIGHT NUMBER IS PRESENT, PUSH THE FARE OBJECT
          //     var i = groupByflightNumber.findIndex((x: any) => x.flight_number == ele.flight_number && x.fares[0].fare_type == ele.fares[0].fare_type);
          //     if (groupByflightNumber[i].fares[0].net_amount > ele.fares[0].net_amount && ele.fares[0].total_available_seats >= adult_child) {
          //       groupByflightNumber.splice(i, 1);
          //       groupByflightNumber.push(ele);
          //     }
          //     // groupByflightNumber[i].fares.push(ele.fares[0]);
          //   }
          // });

          this.flightdetailsOnword = groupByflightNumber;
          // console.log('search-result === flightdetailsOnword.length ============================== 282 ===', this.flightdetailsOnword.length);

          this.flightdetailsOnword.sort(function (a: any, b: any) { return a.fares[0].net_amount - b.fares[0].net_amount })
          this.filterAirlinesResponse = groupByflightNumber;
        }
        this.bookingToken = data.booking_token;
        this.flightdetailsOnword.forEach((element: any) => {
          Object.assign(element, { selectedFight: {} });
          this.isAirlinesList.push(element.airline_name);
          this.isAirlinesStopList.push(element.no_of_stop);
        });

        if (this.isMasterOptions == 2) {
          this.isTotalPassenger = JSON.parse(JsonData).formValue.travellers
          this.flightdetailsOnword.forEach((element: any) => {
            // console.log('element.fares ========================= 281 ===', element);

            element.selectedFight = element.fares[0];
          });
          this.minValue = Math.min(...this.flightdetailsOnword.map((item: any) => item.fares[0].net_amount));
          this.maxValue = Number(Math.max(...this.flightdetailsOnword.map((item: any) => item.fares[0].net_amount)));
          // this.commonService.SetSearchingFlightLoaderStatus(false);
          this.options = {
            floor: this.minValue,
            ceil: this.maxValue
          }
        }
        // console.log("flightdetailsOnword======222222=====>>>>", this.flightdetailsOnword);
        this.flightdetails = this.flightdetailsOnword
      } else {
        this.flightdetailsOnword = [];
      }
    }
    }, 500);
    })

    this.commonService.GetSearchFlightReturnResponse().subscribe((data: any) => {
      this.count = 10;
      this.imageUrl = data.imgBasePath;
      setTimeout(() => {
        if (this.isMasterOptions == 2) {
      var JsonData: any = sessionStorage.getItem('search_form_Data');
      var adult_child = JSON.parse(JsonData).formValue.adult + JSON.parse(JsonData).formValue.child
      // if (Object.keys(data).length > 0) {
      if (data.data != '') {
        var groupByflightNumber: any = [];
        if (this.isMasterOptions == 2) {
          this.flightdetailsReturn = data.data;
          // this.flightdetailsReturn.sort(function (a: any, b: any) { return a.fares[0].net_amount - b.fares[0].net_amount })
           /// FOR MULTI PRICE DISPLAY ///
          this.flightdetailsReturn.forEach((ele: any) => {
            if (!groupByflightNumber.find((x: any) => x.flight_number == ele.flight_number)) {
              /// IF FLIGHT NUMBER NOT PRESENT, INSERTING THE DATA
              if (ele.fares[0].total_available_seats >= adult_child) {
                groupByflightNumber.push(ele);
              }
            } else {
              /// IF FLIGHT NUMBER IS PRESENT, PUSH THE FARE OBJECT
              var i = groupByflightNumber.findIndex((x: any) => x.flight_number == ele.flight_number);

              /// IF SAME FARE TYPE IS PRESENT ///
              var v = groupByflightNumber[i].fares.findIndex((x: any) => x.fare_type == ele.fares[0].fare_type);
              if (v >= 0) {
                if (groupByflightNumber[i].fares[v].net_amount > ele.fares[0].net_amount && ele.fares[0].total_available_seats >= adult_child) {
                  groupByflightNumber[i].fares.splice(v, 1);
                  groupByflightNumber[i].fares.push(ele.fares[0]);
                }
              } else {
                if (ele.fares[0].total_available_seats >= adult_child) {
                  groupByflightNumber[i].fares.push(ele.fares[0]);
                }
              }
            }
          });

          /// FOR SINGLE PRICE DISPLAY ///
          // this.flightdetailsReturn.forEach((ele: any) => {
          //   if (!groupByflightNumber.find((x: any) => x.flight_number == ele.flight_number && x.fares[0].fare_type == ele.fares[0].fare_type)) {
          //     /// IF FLIGHT NUMBER NOT PRESENT, INSERTING THE DATA
          //     if (ele.fares[0].total_available_seats >= adult_child) {
          //       groupByflightNumber.push(ele);
          //     }
          //   } else {
          //     /// IF FLIGHT NUMBER IS PRESENT, PUSH THE FARE OBJECT
          //     var i = groupByflightNumber.findIndex((x: any) => x.flight_number == ele.flight_number && x.fares[0].fare_type == ele.fares[0].fare_type);
          //     if (groupByflightNumber[i].fares[0].net_amount > ele.fares[0].net_amount && ele.fares[0].total_available_seats >= adult_child) {
          //       groupByflightNumber.splice(i, 1);
          //       groupByflightNumber.push(ele);
          //     }
          //     // groupByflightNumber[i].fares.push(ele.fares[0]);
          //   }
          // });

          this.flightdetailsReturn = groupByflightNumber;
          this.flightdetailsReturn.sort(function (a: any, b: any) { return a.fares[0].net_amount - b.fares[0].net_amount })

          this.filterAirlinesResponse = groupByflightNumber;
        }
        this.bookingToken = data.booking_token;
        this.flightdetailsReturn.forEach((element: any) => {
          Object.assign(element, { selectedFight: {} });
          this.isAirlinesList.push(element.airline_name);
          this.isAirlinesStopList.push(element.no_of_stop);
        });

        if (this.isMasterOptions == 2) {
          var JsonData: any = sessionStorage.getItem('search_form_Data');
          this.isTotalPassenger = JSON.parse(JsonData).formValue.travellers
          // console.log("isTotalPassenger=====>>>", this.isTotalPassenger);
          this.flightdetailsReturn.forEach((element: any) => {
            element.selectedFight = element.fares[0];
          });
          this.minValue = Math.min(...this.flightdetailsReturn.map((item: any) => item.fares[0].net_amount));
          this.maxValue = Number(Math.max(...this.flightdetailsReturn.map((item: any) => item.fares[0].net_amount)));
          // this.commonService.SetSearchingFlightLoaderStatus(false);
          this.options = {
            floor: this.minValue,
            ceil: this.maxValue
          }
        }
        // console.log("flightdetailsReturn======333333=====>>>>", this.flightdetailsReturn);
      } else {
        this.flightdetailsReturn = [];
      }
    }
      }, 500);
    })

    // this.commonService.GetSearchFlightOnewayResponse().subscribe((data: any) => {
    //   this.imageUrl = data.imgBasePath;
    //   if (Object.keys(data).length > 0) {
    //     var groupByflightNumber = [];
    //     if(this.isMasterOptions==2){
    //       this.flightdetailsOnword = data.data;
    //       this.flightdetailsOnword.sort(function (a, b) { return a.fares[0].net_amount - b.fares[0].net_amount })
    //       console.log("flightdetailsOnword======shorting===>>>>>",this.flightdetailsOnword);
    //       this.flightdetailsOnword.forEach((ele: any) => {
    //         if (!groupByflightNumber.find(x => x.flight_number == ele.flight_number)) {
    //           /// IF FLIGHT NUMBER NOT PRESENT, INSERTING THE DATA
    //           groupByflightNumber.push(ele);
    //         } else {
    //           /// IF FLIGHT NUMBER IS PRESENT, PUSH THE FARE OBJECT
    //           var i = groupByflightNumber.findIndex(x => x.flight_number == ele.flight_number);
    //           groupByflightNumber[i].fares.push(ele.fares[0]);
    //         }
    //       });
    //       this.flightdetailsOnword = groupByflightNumber
    //       this.filterAirlinesResponse = groupByflightNumber;
    //     }
    //     this.bookingToken = data.booking_token;
    //     this.flightdetailsOnword.forEach((element: any) => {
    //       Object.assign(element, { selectedFight: {} });
    //       this.isAirlinesList.push(element.airline_name);
    //       this.isAirlinesStopList.push(element.no_of_stop);
    //     });

    //     if (this.isMasterOptions == 2) {
    //       var JsonData: any = sessionStorage.getItem('search_form');
    //       this.isTotalPassenger = JSON.parse(JsonData).travellers
    //       console.log("isTotalPassenger=====>>>", this.isTotalPassenger);
    //       this.flightdetailsOnword.forEach((element: any) => {
    //         element.selectedFight = element.fares[0];
    //       });
    //       this.minValue = Math.min(...this.flightdetailsOnword.map((item: any) => item.fares[0].net_amount));
    //       this.maxValue = Number(Math.max(...this.flightdetailsOnword.map((item: any) => item.fares[0].net_amount)));
    //       // this.commonService.SetSearchingFlightLoaderStatus(false);
    //       this.options = {
    //         floor: this.minValue,
    //         ceil: this.maxValue
    //       }
    //     }
    //     console.log("flightdetailsOnword======222222=====>>>>", this.flightdetailsOnword);
    //   } else {
    //     this.flightdetailsOnword = [];
    //   }
    // })


    // this.commonService.GetSearchFlightReturnResponse().subscribe((data: any) => {
    //   this.imageUrl = data.imgBasePath;
    //   if (Object.keys(data).length > 0) {
    //     var groupByflightNumber = [];
    //     if(this.isMasterOptions==2){
    //       this.flightdetailsReturn = data.data;
    //       this.flightdetailsReturn.sort(function (a, b) { return a.fares[0].net_amount - b.fares[0].net_amount })
    //       this.flightdetailsReturn.forEach((ele: any) => {
    //         if (!groupByflightNumber.find(x => x.flight_number == ele.flight_number)) {
    //           /// IF FLIGHT NUMBER NOT PRESENT, INSERTING THE DATA
    //           groupByflightNumber.push(ele);
    //         } else {
    //           /// IF FLIGHT NUMBER IS PRESENT, PUSH THE FARE OBJECT
    //           var i = groupByflightNumber.findIndex(x => x.flight_number == ele.flight_number);
    //           groupByflightNumber[i].fares.push(ele.fares[0]);
    //         }
    //       });
    //       this.flightdetailsReturn = groupByflightNumber
    //       this.filterAirlinesResponse = groupByflightNumber;
    //     }
    //     this.bookingToken = data.booking_token;
    //     this.flightdetailsReturn.forEach((element: any) => {
    //       Object.assign(element, { selectedFight: {} });
    //       this.isAirlinesList.push(element.airline_name);
    //       this.isAirlinesStopList.push(element.no_of_stop);
    //     });

    //     if (this.isMasterOptions == 2) {
    //       var JsonData: any = sessionStorage.getItem('search_form');
    //       this.isTotalPassenger = JSON.parse(JsonData).travellers
    //       console.log("isTotalPassenger=====>>>", this.isTotalPassenger);
    //       this.flightdetailsReturn.forEach((element: any) => {
    //         element.selectedFight = element.fares[0];
    //       });
    //       this.minValue = Math.min(...this.flightdetailsReturn.map((item: any) => item.fares[0].net_amount));
    //       this.maxValue = Number(Math.max(...this.flightdetailsReturn.map((item: any) => item.fares[0].net_amount)));
    //       // this.commonService.SetSearchingFlightLoaderStatus(false);
    //       this.options = {
    //         floor: this.minValue,
    //         ceil: this.maxValue
    //       }
    //     }
    //     console.log("flightdetailsReturn======333333=====>>>>", this.flightdetailsReturn);
    //   } else {
    //     this.flightdetailsReturn = [];
    //   }
    // })

    this.commonService.GetSelectedTravelersData().subscribe(data => {
      if (Object.keys(data).length > 0) {
        this.isTravelsObject = data;
      }
    })

    // this.commonService.GetUserBalance().subscribe((data:any)=>{
    //   if(Object.keys(data).length>0){
    //     this.isAccountBalance=data.available_balance;
    //   }
    // })
  }



  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) * 1.8 >= document.body.offsetHeight;
  }
  imagesrc: any;
  onChangeDepartureTiming(event: any, timeObject: any) {
    // console.log(event, timeObject);
    timeObject.is_selected = event.checked;
    this.applyFilter();
  }
  onChangeArrivalTiming(event: any, timeObject: any) {
    // console.log(event, timeObject);
    timeObject.is_selected = event.checked;
    this.applyFilter();
  }
  onChangeAirlines(event: any, airlineName: any) {
    // console.log(event, airlineName);
    if (event.checked) {
      this.seachingAirlinesArray.push(airlineName)
    } else {
      var removeIndex = this.seachingAirlinesArray.map(function (item: any) { return item; }).indexOf(airlineName);
      this.seachingAirlinesArray.splice(removeIndex, 1);
    }
    this.applyFilter();
  }
  onChangeAirlinesStop(event: any, noOfStop: any) {
    // console.log(event, noOfStop);
    if (event.checked) {
      this.seachingAirlinesStopArray.push(noOfStop)
    } else {
      var removeIndex = this.seachingAirlinesStopArray.map(function (item: any) { return item; }).indexOf(noOfStop);
      this.seachingAirlinesStopArray.splice(removeIndex, 1);
    }
    this.applyFilter();
  }
  onChangeRefundableFare(event: any) {
    this.isRefundableFareFlag = event.checked;
    console.log("isRefundableFareFlag>>>>", this.isRefundableFareFlag)
    this.applyFilter();
  }
  onChangeNonRefundableFare(event: any) {
    this.isNonRefundableFareFlag = event.checked;
    this.applyFilter();
  }
  applyFilter() {
    this.flightdetailsOnword = this.filterAirlinesResponse;
    console.log("flightdetailsOnword======>>>",this.flightdetailsOnword);
    // return
    var timeArray1: any = []; var timeArray2: any = [];
    var filterFromTimeData = this.Timings.filter((x: any) => x.is_selected);
    var filterToTimeData = this.InTimings.filter((x: any) => x.is_selected);
    if (filterFromTimeData.length > 0) {
      filterFromTimeData.forEach((element: any) => {
        var split_time1 = element.value.split('-');
        var obj1 = { min: '', max: '' };
        obj1.min = split_time1[0];
        obj1.max = split_time1[1];
        timeArray1 = timeArray1.concat(obj1);
      });
      if (timeArray1.length == 1) {
        this.flightdetailsOnword = this.flightdetailsOnword.filter((item: any) => (item.departure_time >= timeArray1[0].min) && (item.departure_time <= timeArray1[0].max));
      }
      if (timeArray1.length == 2) {
        this.flightdetailsOnword = this.flightdetailsOnword.filter((item: any) => (item.departure_time >= timeArray1[0].min) && (item.departure_time <= timeArray1[0].max) || (item.departure_time >= timeArray1[1].min) && (item.departure_time <= timeArray1[1].max));
      }
      if (timeArray1.length == 3) {
        this.flightdetailsOnword = this.flightdetailsOnword.filter((item: any) => (item.departure_time >= timeArray1[0].min) && (item.departure_time <= timeArray1[0].max) || (item.departure_time >= timeArray1[1].min) && (item.departure_time <= timeArray1[1].max) || (item.departure_time >= timeArray1[2].min) && (item.departure_time <= timeArray1[2].max));
      }
      if (timeArray1.length == 4) {
        this.flightdetailsOnword = this.flightdetailsOnword.filter((item: any) => (item.departure_time >= timeArray1[0].min) && (item.departure_time <= timeArray1[0].max) || (item.departure_time >= timeArray1[1].min) && (item.departure_time <= timeArray1[1].max) || (item.departure_time >= timeArray1[2].min) && (item.departure_time <= timeArray1[2].max) || (item.departure_time >= timeArray1[3].min) && (item.departure_time <= timeArray1[3].max));
      }
    }
    if (filterToTimeData.length > 0) {
      filterToTimeData.forEach((element: any) => {
        var split_time2 = element.value.split('-');
        var obj2 = { min: '', max: '' };
        obj2.min = split_time2[0];
        obj2.max = split_time2[1];
        timeArray2 = timeArray2.concat(obj2);
      });
      if (timeArray2.length == 1) {
        this.flightdetailsOnword = this.flightdetailsOnword.filter((item: any) => (item.arrival_time >= timeArray2[0].min) && (item.arrival_time <= timeArray2[0].max));
      }
      if (timeArray2.length == 2) {
        this.flightdetailsOnword = this.flightdetailsOnword.filter((item: any) => (item.arrival_time >= timeArray2[0].min) && (item.arrival_time <= timeArray2[0].max) || (item.arrival_time >= timeArray2[1].min) && (item.arrival_time <= timeArray2[1].max));
      }
      if (timeArray2.length == 3) {
        this.flightdetailsOnword = this.flightdetailsOnword.filter((item: any) => (item.arrival_time >= timeArray2[0].min) && (item.arrival_time <= timeArray2[0].max) || (item.arrival_time >= timeArray2[1].min) && (item.arrival_time <= timeArray2[1].max) || (item.arrival_time >= timeArray2[2].min) && (item.arrival_time <= timeArray2[2].max));
      }
      if (timeArray2.length == 4) {
        this.flightdetailsOnword = this.flightdetailsOnword.filter((item: any) => (item.arrival_time >= timeArray2[0].min) && (item.arrival_time <= timeArray2[0].max) || (item.arrival_time >= timeArray2[1].min) && (item.arrival_time <= timeArray2[1].max) || (item.arrival_time >= timeArray2[2].min) && (item.arrival_time <= timeArray2[2].max) || (item.arrival_time >= timeArray2[3].min) && (item.arrival_time <= timeArray2[3].max));
      }
    }
    if (this.minValue && this.maxValue) {
      this.flightdetailsOnword = this.flightdetailsOnword.filter((item: any) => (item.fares[0].net_amount >= this.minValue) && (item.fares[0].net_amount <= this.maxValue))
    }
    if (this.seachingAirlinesArray.length > 0) {
      this.flightdetailsOnword = this.flightdetailsOnword.filter((items: any) => {
        return this.seachingAirlinesArray.indexOf(items.airline_name) > -1;
      });
    }
    if (this.seachingAirlinesStopArray.length > 0) {
      this.flightdetailsOnword = this.flightdetailsOnword.filter((items: any) => {
        return this.seachingAirlinesStopArray.indexOf(items.no_of_stop) > -1;
      });
    }
    if(this.isRefundableFareFlag ==true){
      this.flightdetailsOnword=this.flightdetailsOnword.filter((item:any) => {
        return item.fares[0].refundable === this.isRefundableFareFlag});
    }
    if (this.isNonRefundableFareFlag == true) {
      this.flightdetailsOnword = this.flightdetailsOnword.filter((item: any) => {
        return item.fares[0].refundable === !this.isNonRefundableFareFlag
      });
    }
  }
  removeDuplicates(arr: any) {
    return arr.filter((item: any, index: any) => arr.indexOf(item) == index);
  }
  durationdiff(dep_time, arr_time, dep_date, type) {
    // console.log("dep_time, arr_time, dep_date, type",dep_time, arr_time, dep_date, type)
    var dep_hr = parseInt(dep_time.split(':')[0]); var arr_hr = parseInt(arr_time.split(':')[0]);
    if (arr_hr < dep_hr) {
      var nwdate;
      if (!dep_date) { nwdate = new Date(); }
      else { nwdate = new Date(dep_date); }
      var newdate = nwdate.setDate(nwdate.getDate() + 1);
      if (type == 'oneway') { this.return_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd'); }
      else { this.rreturn_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd'); }
    }
    else { this.return_d_date = dep_date; this.rreturn_d_date = dep_date; }
    var start_date = dep_date + " " + dep_time; var end_date = this.return_d_date + " " + arr_time;
    return this.getDataDiff(new Date(start_date.toString()), new Date(end_date.toString()));
  }


  durationSplit(duration: any) {
    var split_duration = duration.split(':');
    return split_duration[0] + ' hrs : ' + split_duration[1] + ' mins'
  }
  getDataDiff(s_date, e_date) {
    var diff = e_date.getTime() - s_date.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000)); var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return hours + " hrs " + ": " + minutes + " min";
  }
  timeConvert(n) {
    var num = n; var hours = (num / 60);
    var rhours = Math.floor(hours); var minutes = (hours - rhours) * 60; var rminutes = Math.round(minutes);
    return rhours + " hr  " + rminutes + " min";
  }
  contactSupport() {
    // this.modalService.SetContactSupportModal(true);
  }
  bookflight_backup(event: any) {
    sessionStorage.setItem('booking_details', JSON.stringify({ data: event }));
    sessionStorage.setItem('booking_details_img', this.imageUrl);
    if (this.isMasterOptions == 1) {
      sessionStorage.setItem('booking_token', JSON.stringify({ token: this.bookingToken }));
    } else {
      sessionStorage.setItem('booking_token', JSON.stringify({ token: event.booking_token }));
    }
    this.router.navigate(['/search-details/' + event.flight_id]);
  }
  aval_seat: any;
  bookflight(flight) {
    // console.log("flight ::::::::::::>>>>",flight)
    var JsonData: any = sessionStorage.getItem('search_form_Data');
    sessionStorage.setItem("static_Value", JSON.stringify(flight.static))
    sessionStorage.setItem("Booking_token", JSON.stringify(this.bookingToken))
    sessionStorage.setItem("book_own_inv", JSON.stringify(false));
    sessionStorage.setItem("Regular Search", JSON.stringify(this.isMasterOptions == 2 ? true : false))
    const self = this;
    // var formvalue = this.searchForm.value;
    // sessionStorage.setItem("Regular Search",JSON.stringify(this.Regularsearch))
    if (this.isMasterOptions == 1) {
      this.aval_seat = flight.total_available_seats
      // console.log("this.aval_seat",this.aval_seat,formvalue)
      var totalseat = Number(JSON.parse(JsonData).formValue.adult) + Number(JSON.parse(JsonData).formValue.child)
      // console.log("totalseat",totalseat)
      if (this.aval_seat >= totalseat) {
        var senddata = {
          flight_id: flight.flight_id,
          trip_type: JSON.parse(JsonData).isTripType,
          departure_date: JSON.parse(JsonData).formValue.departure_date,
          return_date: JSON.parse(JsonData).formValue.return_date,
          adult: JSON.parse(JsonData).formValue.adult,
          children: JSON.parse(JsonData).formValue.child,
          infant: JSON.parse(JsonData).formValue.infant,
          inventory_user_Type: "self",
          booking_token: this.bookingToken,
          inv_user: flight.inventory_user_Type
        }
        if (flight.partnervalue == 'normal') {
          // console.log("hello partner")
          sessionStorage.setItem("partnerSearch", JSON.stringify(false));
          this.dataservice.searchedflightdetail(senddata);
        } else {
          sessionStorage.setItem("searchedflights_detail", JSON.stringify(flight));
          sessionStorage.setItem("partnerSearch", JSON.stringify(true));
          // sessionStorage.setItem("imagepath", JSON.stringify(self.srcimagepath));
          this.router.navigateByUrl("detail");
        }
      }
      else {
        $('#confirmbooking').modal('show');
      }
    } else {
      this.aval_seat = flight.selectedFight.total_available_seats
      sessionStorage.setItem("Booking_token", JSON.stringify(flight.selectedFight.booking_token))
      var totalseat = Number(JSON.parse(JsonData).formValue.adult) + Number(JSON.parse(JsonData).formValue.child)
      if (this.aval_seat >= totalseat) {
        sessionStorage.setItem("partnerSearch", JSON.stringify(true));
        sessionStorage.setItem("searchedflights_detail", JSON.stringify(flight));
        self.router.navigateByUrl("detail");
      }
      else {
        $('#confirmbooking').modal('show');
      }
    }
  }

  selectedTabValue(event, data) {
    this.labelName = event.tab.textLabel;
    if (this.labelName == 'Fare Rules') {
      var flightData = data
      this.apiService.onFITFareRuleList((flightData.selectedFight.inventory_from), {
        "fare_id": flightData.selectedFight.id,
        "dep_city": flightData.departure_airport_code,
        "arr_city": flightData.arrival_airport_code,
        "static": flightData.selectedFight.static
      })
    }
    this.commonService.GetFareRuleList().subscribe((data: any) => {
      if (data) {
        this.isFareRuleData = data;
      }
    })
  }
  selectValue(flightObject, selectedFlight) {
    flightObject.selectedFight = selectedFlight;
    console.log("flightObject>>>>>>>", flightObject);
    this.isSelectIndexValue = flightObject.selectedFight.id;
    this.apiService.onFITFareRuleList((selectedFlight.inventory_from), {
      "fare_id": flightObject.selectedFight.id,
      "dep_city": flightObject.departure_airport_code,
      "arr_city": flightObject.arrival_airport_code,
      "static": flightObject.selectedFight.static
    })
    this.commonService.GetFareRuleList().subscribe((data: any) => {
      if (data) {
        this.isFareRuleData = data;
      }
    })
  }
  onSelectOnword(flight, selectedData) {
    var JsonData: any = sessionStorage.getItem('search_form_Data');
    sessionStorage.setItem("selected_FIT_data", JSON.stringify(selectedData))
    sessionStorage.setItem("Booking_token", JSON.stringify(selectedData.booking_token))
    sessionStorage.setItem("book_own_inv", JSON.stringify(false));
    sessionStorage.setItem("Regular Search", JSON.stringify(this.isMasterOptions == 2 ? true : false))
    const self = this;
    this.aval_seat = flight.selectedFight.total_available_seats
    var totalseat = Number(JSON.parse(JsonData).formValue.adult) + Number(JSON.parse(JsonData).formValue.child)
    if (this.aval_seat >= totalseat) {
      sessionStorage.setItem("partnerSearch", JSON.stringify(true));
      sessionStorage.setItem("searchedflights_detail", JSON.stringify(flight));
      this.isOnwordFlight=false;
      this.isOnwordShow=true;
      setTimeout(() => {
        var JsonData:any=sessionStorage.getItem('searchedflights_detail');
        this.isOnwordDataSelect = JSON.parse(JsonData);
      }, 100);
    }
    else {
      $('#confirmbooking').modal('show');
    }
  }

  onSelectReturn(flight, selectedData) {
    var JsonData: any = sessionStorage.getItem('search_form_Data');
    sessionStorage.setItem("selected_FIT_return", JSON.stringify(selectedData))
    sessionStorage.setItem("Booking_token_return", JSON.stringify(selectedData.booking_token))
    sessionStorage.setItem("book_own_inv_return", JSON.stringify(false));
    sessionStorage.setItem("Regular Search", JSON.stringify(this.isMasterOptions == 2 ? true : false))
    const self = this;
    this.aval_seat = flight.selectedFight.total_available_seats
    var totalseat = Number(JSON.parse(JsonData).formValue.adult) + Number(JSON.parse(JsonData).formValue.child)
    if (this.aval_seat >= totalseat) {
      sessionStorage.setItem("partnerSearch", JSON.stringify(true));
      sessionStorage.setItem("searchedflights_detail_return", JSON.stringify(flight));
      setTimeout(() => {
        var JsonData:any=sessionStorage.getItem('searchedflights_detail_return');
        this.isReturnDataSelect = JSON.parse(JsonData);
        this.isReturnValue = true;
      }, 100);
    }
    else {
      $('#confirmbooking').modal('show');
    }
  }
  onFlightOnwordReturn(){
    this.router.navigateByUrl("detail-fit");
  }
  bookflightFIT(flight, selectedData) {
    // console.log("flight=======11111=====>>>>",flight);
    // console.log("flight=======22222=====>>>>",selectedData);
    var JsonData: any = sessionStorage.getItem('search_form_Data');
    sessionStorage.setItem("selected_FIT_data", JSON.stringify(selectedData))
    sessionStorage.setItem("Booking_token", JSON.stringify(selectedData.booking_token))
    sessionStorage.setItem("book_own_inv", JSON.stringify(false));
    sessionStorage.setItem("Regular Search", JSON.stringify(this.isMasterOptions == 2 ? true : false))
    const self = this;
    this.aval_seat = flight.selectedFight.total_available_seats
    var totalseat = Number(JSON.parse(JsonData).formValue.adult) + Number(JSON.parse(JsonData).formValue.child)
    if (this.aval_seat >= totalseat) {
      sessionStorage.setItem("partnerSearch", JSON.stringify(true));
      sessionStorage.setItem("searchedflights_detail", JSON.stringify(flight));
      self.router.navigateByUrl("detail-fit");
    }
    else {
      $('#confirmbooking').modal('show');
    }
  }


  return_date: string; searchnotresult: boolean = false; newCal: any; newCal2: any; newCal3: any;
  renderdata() {
    // this.search_details = JSON.parse(sessionStorage.getItem('search_form_Data'));
    setTimeout(() => {
      this.flightdetails.forEach(element => {
        var obj = element;
        if (element.inventory_user_Type == 'fareboutique' && element.add_margin == 1) {
          this.newCal = element.creditlmitAmount < element.total_payable_price;
          this.newCal2 = element.creditlmitAmount > element.per_ticket_avg_price + element.margin - element.discount;
          this.newCal3 = ~~(element.creditlmitAmount / (element.per_ticket_avg_price + element.margin - element.discount));
          Object.assign(obj, { newCal: this.newCal, newCal2: this.newCal2, newCal3: this.newCal3 })
        }
      });
    }, 100);
    // setTimeout(() => {
    //   if (this.search_details.trip_type == 'one_way') {  this.flighttype == 'return'  }
    //   else {  this.flighttype == 'one_way';  this.disableInput = true;  this.return_date_input = false;  }
    // }, 1000);
  }
  checkOffer() {

  }
  onCheckRecordFlag(flightArray: any) {
    var flag: boolean = false;
    if (flightArray.length > 0) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  }
  duration(duration) {
    // console.log("duration value:::::::::151::::::",duration);
    var duration_split = duration.split(':');
    return duration_split[0] + ' hrs : ' + duration_split[1] + ' mins';
  }
  getTimeFromTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  padZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  getCookie(name: string): any {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return JSON.parse(c.substring(nameEQ.length, c.length));
    }
    return null;
  }

}
