import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ToastrManager } from 'ng6-toastr-notifications';
import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  constructor(private toastr: ToastrManager) {  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_sample_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  exportToExcel(json: any[]): void {
    const fileName = 'Fareboutique_Inventory' + new Date().getTime() + '.xlsx';
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    console.log("wb===============>>>>>>>", wb);
    var groupByName = {};
    json.forEach(function (a) {
      groupByName[a.name] = groupByName[a.name] || [];
      groupByName[a.name].push({
        'Departure City': a['dep_city_code'],
        'Arrival City': a['arr_city_code'],
        'Date': formatDate(new Date(a['date']), 'dd-MM-yyyy', 'en'),
        'PNR': a['pnr_number'],
        'Available': a['available_seats'],
        'Blocked': a['block_seat'],
        'Price': a['price'],
        'Status': a['status'] == 1 ? 'ON' : 'OFF',
      });
    });
    for (let entry in groupByName) {
      var wsheet = XLSX.utils.json_to_sheet(groupByName[entry]);
      XLSX.utils.book_append_sheet(wb, wsheet, entry);
    }
    XLSX.writeFile(wb, fileName);
  }

  public exportAsExcelFileOfTraveller(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile2(excelBuffer, excelFileName);
  }
  private saveAsExcelFile2(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_sample_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public exportAsGGNexcellFileofData(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsGgnSaveData(excelBuffer, excelFileName);
  }
  private saveAsGgnSaveData(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_sample_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  exportToHoldSeatsExcel(json: any[]): void {
    const fileName = 'HoldSeat_Inventory' + '.xlsx';
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    var groupByName = {};
    json.forEach(function (a) {
      if (a.blocked_seats > 0) {
        groupByName[a.name] = groupByName[a.name] || [];
        groupByName[a.name].push({
          'Airline': a['airline_name'],
          'Sector': a['dep_city_code'] +'-'+ a['arr_city_code'],
          'Airline No': a['flight_number'],
          'Date': formatDate(new Date(a['departure_date']), 'dd-MM-yyyy', 'en'),
          'Departure Time': a['departure_time'],
          'Arrival Time': a['arrival_time'],
          'PNR': a['pnr_number'],
          'Available': a['available_seats'],
          'Blocked': a['blocked_seats'],
          'Price': a['price'],
          'Status': a['status'] == 0 ? 'ON' : 'OFF',
        });
      }

    });

    for (let entry in groupByName) {
      var wsheet = XLSX.utils.json_to_sheet(groupByName[entry]);
      XLSX.utils.book_append_sheet(wb, wsheet, entry);
    }
    XLSX.writeFile(wb, fileName);
  }

  exportToSoldSeatsExcel(json: any[]): void {
    const fileName = 'SoldSeat_Inventory' + new Date().getTime() + '.xlsx';
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    var groupByName = {};
    json.forEach(function (a) {
      
      if (a.sold_seats > 0) {
        groupByName[a.name] = groupByName[a.name] || [];
        groupByName[a.name].push({
          'Airline': a['airline_name'],
          'Sector': a['dep_city_code'] +'-'+ a['arr_city_code'],
          'Airline No': a['flight_number'],
          'Date': formatDate(new Date(a['departure_date']), 'dd-MM-yyyy', 'en'),
          'Departure Time': a['departure_time'],
          'Arrival Time': a['arrival_time'],
          'PNR': a['pnr_number'],
          'Available': a['available_seats'],
          'Blocked': a['blocked_seats'],
          'Price': a['price'],
          'Status': a['status'] == 0 ? 'ON' : 'OFF',
        });
      }

    });
    
   
    for (let entry in groupByName) {
      if(groupByName[entry].length != 'undefind'){
        var wsheet = XLSX.utils.json_to_sheet(groupByName[entry]);
        XLSX.utils.book_append_sheet(wb, wsheet, entry);
      }
    }
    // console.log(wb,'groupByName-----137')
    if(wb.SheetNames.length > 0){
      XLSX.writeFile(wb, fileName);
    }else{
      this.toastr.errorToastr("Data not available", "Error");
      // alert('No Data available')
    }

  
   
  }

  exportToDatewaiseExcel(json: any[]): void {
    const fileName = 'Datewise_Inventory' + new Date().getTime() + '.xlsx';
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    var groupByName = {};
    json.forEach(function (a) {
      groupByName[a.name] = groupByName[a.name] || [];
      groupByName[a.name].push({
        'Airline': a['airline_name'],
        'Sector': a['dep_city_code'] +'-'+ a['arr_city_code'],
        'Airline No': a['flight_number'],
        'Date': formatDate(new Date(a['departure_date']), 'dd-MM-yyyy', 'en'),
        'Departure Time': a['departure_time'],
        'Arrival Time': a['arrival_time'],
        'PNR': a['pnr_number'],
        'Available': a['available_seats'],
        'Blocked': a['blocked_seats'],
        'Price': a['price'],
        'Status': a['status'] == 0 ? 'ON' : 'OFF',
      });
    });
    for (let entry in groupByName) {
      var wsheet = XLSX.utils.json_to_sheet(groupByName[entry]);
      XLSX.utils.book_append_sheet(wb, wsheet, entry);
    }
    XLSX.writeFile(wb, fileName);
  }

}
