import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map} from 'rxjs/operators';
import * as ActionClub from './action';

@Injectable()
export class ClubEffects {
  constructor(private actions$: Actions) {}

  @Effect()
  loadAction$ = this.actions$
    .pipe(
      ofType<ActionClub.PostClubRequest>(ActionClub.POST_Club),
      map(action => {return action.payload}),
      
    );
  private handleError(error) {
    return of(new ActionClub.Error(error));
  }
}
