import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from 'src/app/services';
declare var $: any;

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {
  btnsubmitted:boolean=false;
  user:any;
  user_id:any;
  list_data:any=[];
  editbankaccount:any;
  public addNewAccount : FormGroup; 
  public editAccount : FormGroup; 
  submitted:boolean=false;
  dataId: any;

  constructor(public sharedService: SharedserviceService,
    private fb: FormBuilder,
    public router: Router,
    private toastr: ToastrManager, ) { 
      this.user = JSON.parse(localStorage.getItem("user"));

      if (this.user != null) {
        this.user_id = this.user.id;
        // this.role_id = this.user.role_id;
      }
    }

  ngOnInit() {
    this.showList();
    this.addNewAccount = this.fb.group({
      name:['', Validators.required],
      bank:['', Validators.required],
      branch:['', Validators.required],
      ac_number:['', Validators.required],
      ifsc:['',Validators.required]
    })
    this.editAccount = this.fb.group({
      id:['', Validators.required],
      name:['', Validators.required],
      bank:['', Validators.required],
      branch:['', Validators.required],
      ac_number:['', Validators.required],
      ifsc:['',Validators.required]
    })
  }

  get ab (){return this.addNewAccount.controls} 
  get bb (){return this.editAccount.controls} 
 
  showList(){
    var self = this;
    var request = {
      user_id: this.user_id
     }
     self.sharedService.post('v1/bank/list',request).subscribe((res:any) =>{
      if (res.replyCode == "success") {
        this.list_data = res.data.rows;
        // console.log("data length 12:::::::::",res.data.length)
        // console.log("data length :::::::::",this.list_data.length)
      }
     },err=> {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    })
    
  }
  numberOnly(event): boolean {
    // 
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  add_acc(){
    var self = this;
    self.submitted = true;
    if (self.addNewAccount.invalid) {
      return;
    } else {
      
        var data = self.addNewAccount.value;
        this.sharedService.post('v1/bank/add',data).subscribe((res:any)=>{
          if (!res.error) {
            if(res.replyCode == 'success'){
              self.submitted = false;
              this.showList();
              this.addNewAccount.reset();
              $('#add_new_account').modal('hide')
               this.toastr.successToastr(res.replyMsg,'Success')
            }
          }
        }
        ,err =>{
          this.toastr.errorToastr(err.replyMsg,'Error')
        })
      }
       }

  editAccountModal(data){
    this.editbankaccount=data;
    this.editAccount.patchValue({
        id:this.editbankaccount.id,
        name:this.editbankaccount.name,
        bank:this.editbankaccount.bank,
        branch:this.editbankaccount.branch,
        ac_number:Number(this.editbankaccount.ac_number),
        ifsc:this.editbankaccount.ifsc
    })
    $('#edit_account').modal('show')
  }
       
  editDetail(){
      var self = this;
      self.submitted = true;
      var editData = self.editAccount.value;
      self.sharedService.post('v1/bank/edit',editData).subscribe((res:any)=>{
        if (!res.error) {
          if(res.replyCode == 'success'){
            self.submitted = false;
            self.showList();
            $('#edit_account').modal('hide')
             this.toastr.successToastr(res.replyMsg,'Success')
          }
        }
      }
      ,err =>{
        this.toastr.errorToastr(err.error.replyMsg,'Error')
      })

  }  

  deleteModal(data){
    this.dataId = data;
    // console.log("check Delete data ID",this.dataId);
   $('#delete').modal('show');
  }
 
  
  deleteAccount(){
    var self = this;
    var request_data ={
      id:this.dataId
    };
    // console.log("delete ::::::",request_data)
    self.sharedService.post('v1/bank/delete',request_data).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        self.showList();
        this.toastr.successToastr(res.replyMsg,'Success')
        $('#delete').modal('hide');
      }
    },err =>{
      this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }
  
 addAccountModal(){
   $('#add_new_account').modal('show')
 }


}
