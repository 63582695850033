import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from 'src/app/services';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  return_client_status: boolean = false;
  return_agency_status: boolean = true ;

  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
  searchFlagReset: boolean = false;
  bodydata: any = {}; TotalCount: any; data: any; keys: any; public page: number = 1; start = 0; msg: any; status: any; FormId: any; flag: boolean = false;
  userDatarole: any;
  notificationForm: FormGroup;
  checkedUser: any = [];
  isMasterSel: boolean = false;
  allChecked: boolean = false;
  allCheckedRemoved: boolean = false;
  checkedCategoryList: any;
  checkedIDs: any;
  listEvent: any;
  dataMsg: any;
  dataID: any = [];
  _selected: any = [];
  _unSelected: any = [];
  isName: any = [];
  nameDtl: any = [];
  checkItem: boolean = false;
  all: boolean = false;
  submitted: boolean = false;
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public router: Router,
    private toastr: ToastrManager,
    private activatedRoute: ActivatedRoute
  ) {

  }
  get f() { return this.notificationForm.controls; }

  ngOnInit() {
    this.bodydata = {
      role_id: "",
      page_limit: 10,
      page_no: this.bodydata['page_no'],
      search: "",
      order_key: "name",
      order_value: "desc"
    }
    this.bodydata['page_no'] = '1';
    this.loaddata(this.bodydata);
    // this.getNotification();

    this.notificationForm = this.fb.group({
      title: ['', Validators.required],
      msg_body: ['', Validators.required]
    })
  }





  ItemChecked() {
    this.allCheckedRemoved = false;
    if (this.allChecked && this._unSelected.length > 0) {
      this.allChecked = true;
    } else {
      this.allChecked = !this.allChecked;
    }
    this._selected = [];
    this._unSelected = [];
    this.all = this.allChecked;
  }

  clickCheched(id) {
    if (this.allChecked) {
      if (this._unSelected.includes(id)) {
        this.checkItem = false;
      }
      else {
        this.checkItem = true;
      }
    }
    else {
      this.checkItem = this._selected.includes(id);
    }
    return this.checkItem;
  }

  fetchSelectedItems(event, name) {
    
    if (!this.allChecked) {
      if (!this._selected.includes(event.id)) {
        this._selected.push(event.id);
      }
      else {
        const index: number = this._selected.indexOf(event.id);
        if (index !== -1) {
          this._selected.splice(index, 1);
        }
        // this.dataID = this._selected;
      }
      this.dataID = this._selected;
    }
    else {
      if (!this._unSelected.includes(event.id)) {
        this._unSelected.push(event.id);
        this.allCheckedRemoved = true;
      }
      else {
        const index: number = this._unSelected.indexOf(event.id);
        if (index !== -1) {
          this._unSelected.splice(index, 1);
        }
      }
      this.dataID = this._unSelected;
      if (this._unSelected.length > 0) {
        this.all = false;
      }
    }
  }

  getNotification(){
    this.sharedService.post('v1/web/get_all_notifcation',{}).subscribe((res) => {
      if (res.replyCode == 'success') {
      } else {
        this.flag = true;
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['replyMsg'], 'Error');
    });
  }


  loaddata(bodydata) {
    this.sharedService.post('v1/users/patner_users_list', bodydata).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.userDatarole = res.data;

        if (res.data.length > 0) {
          this.userDatarole = res.data;
          this.keys = Object.keys(this.userDatarole[0]);
          this.TotalCount = res.totalRecords;
        } else {
          this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
        }
      } else {
        this.flag = true;
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['replyMsg'], 'Error');
    });
  }


  changeStatus(val){
    if (val == 'client') {
      this.return_client_status = true;  
      this.return_agency_status = false;  
    } else {
      this.return_client_status = false;  
      this.return_agency_status = true;  
    }
    
  }

  // msgNotification() {
  //   this.submitted=true;
  //   console.log('selected ids ::::::::::::::::::::::::: ', this.dataID);
    
  //   if (this.notificationForm.valid) {
  //     var Object = {
  //       "user_id": this.dataID,
  //       "title": this.notificationForm.value.title,
  //       "msg_body": this.notificationForm.value.message,
  //       "select_type": this.allChecked ? "all" : "",
  //       "unchecked_user_id": this._unSelected
  //     }
  //     this.sharedService.post('v1/users/send_custom_notification', Object).subscribe((res) => {
  //       if (res.replyCode == 'success') {
  //         this.dataMsg = res;
  //         this.toastr.successToastr(res.replyMsg, 'Success')
  //       }
  //       else {
  //         this.toastr.errorToastr(res.replyMsg, 'Error');
  //       }
  //     },err=>{
  //       this.toastr.errorToastr(err.error.replyMsg, 'Error')
  //     })
  //   }
  // }
  msgNotification() {
    this.submitted=true;
    
    if (this.notificationForm.valid) {
      var Object = {
        // "user_role": 2,
        // "title": this.notificationForm.value.title,
        // "description": this.notificationForm.value.message,
        "title":  this.notificationForm.value.title,
        "msg_body": this.notificationForm.value.msg_body,
        "agency": this.return_agency_status.toString(),
        "client": this.return_client_status.toString()
      }
      console.log(Object,"----Object----217---***-");
      
      this.sharedService.post('v1/notification/send_notification', Object).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.dataMsg = res;
          this.toastr.successToastr(res.replyMsg, 'Success')
          this.submitted=false;
          this.notificationForm.reset();
        }
        else {
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      },err=>{
        this.toastr.errorToastr(err.error.replyMsg, 'Error')
      })
    }
  }



  onSearch() {
    this.searchFlagReset = true;
    this.bodydata['search'] = String(this.inputSearch.nativeElement.value);
    this.loaddata(this.bodydata);
  }
  onReset() {
    this.inputSearch.nativeElement.value = '';
    this.searchFlagReset = false;
    this.bodydata['search'] = '';
    this.loaddata(this.bodydata);
  }

  onPageChange(event) {
    this.bodydata['page_no'] = event;
    this.loaddata(this.bodydata);
  }


}
