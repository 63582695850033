import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from 'src/app/services';
declare var $: any;


@Component({
  selector: 'app-sendmail',
  templateUrl: './sendmail.component.html',
  styleUrls: ['./sendmail.component.scss']
})
export class SendmailComponent implements OnInit {
  public addMail: FormGroup;
  public editMailform:FormGroup;
  submitted: boolean = false;
  data: any;
  editMail: any;
  mailId: any;
  mailDelete: any;
  deleteMailform: FormGroup;

  constructor(private fb: FormBuilder,  public sharedService: SharedserviceService,  private toastr: ToastrManager,private router: Router) { }

  ngOnInit() {
    this.mailList();
    this.addMail =  this.fb.group({
    name:['',Validators.required],
    email:['',Validators.required],
    pass:['',Validators.required],
    host:['',Validators.required],
    port:['',Validators.required]
    })
    this.editMailform = this.fb.group({
      id:[''],
      name:['', Validators.required],
      email:['',Validators.required],
      pass:['',Validators.required],
      host:['',Validators.required],
      port:['',Validators.required],
      status:['1']
    })
    this.deleteMailform = this.fb.group({
      id:[''],
      name:['', Validators.required],
      email:['',Validators.required],
      pass:['',Validators.required],
      host:['',Validators.required],
      port:['',Validators.required],
      status:['0']
    })
  }
  get add() { return this.addMail.controls; };
  get edit() { return this.editMailform.controls; }


  add_mail(){
    $('#add_mail').modal('show');
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {  return false;  }
    return true;
    }

    mailList(){
      var self = this;
      self.sharedService.get('v1/mail/list').subscribe((res)=>{
        if (res.replyCode == 'success') {
          this.data = res.data.rows;

        }
      });
    }
    closeAddMail(){
      $('#add_mail').modal('hide');
    }
  save_mail(){
    var self = this;
    self.submitted = true
    // console.log("add mail::::",self.addMail.value);
    if(self.addMail.invalid){
      return
    }else{
      var request= self.addMail.value;
      this.sharedService.post('v1/mail/add', request).subscribe((res) => {
        if (res.replyCode == 'success') {
          self.submitted = false;
          self.mailList();
          this.toastr.successToastr(res.replyMsg);
         self.addMail.reset();
         $('#add_mail').modal('hide');
        }else{
          self.submitted = false;

          self.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, error => {
        self.submitted = false;
        $('#addBalance').modal('hide');

        self.toastr.errorToastr(error.error.replyMsg);

      });
    }

  }
  mail_delete(delete_id){
    this.mailDelete = delete_id;
    this.deleteMailform.patchValue({
      id:this.mailDelete.id,
      name:this.mailDelete.name,
      email:this.mailDelete.email,
      pass:this.mailDelete.pass,
      host:this.mailDelete.host,
      port:this.mailDelete.port
    }) ;
    $('#delete_mail').modal('show');
  }
  deleteData(){
    var self = this;
    var delete_Data = self.deleteMailform.value;
    self.sharedService.post('v1/mail/edit',delete_Data).subscribe((res:any)=>{
      if (!res.error) {
        if(res.replyCode == 'success'){
          self.submitted = false;
          self.mailList();
          $('#delete_mail').modal('hide')
           this.toastr.successToastr(res.replyMsg,'Success')
        }
      }
    }
    ,err =>{
      this.toastr.errorToastr(err.error.replyMsg,'Error')
    })
  }
  edit_mail(data){
    this.editMail=data;
    // console.log("edit mail::",this.editMail)
    this.editMailform.patchValue({
      id:this.editMail.id,
      name:this.editMail.name,
      email:this.editMail.email,
      pass:this.editMail.pass,
      host:this.editMail.host,
      port:this.editMail.port
    })
    $('#edit_mail').modal('show');
  }
  closeEditMail(){
    $('#edit_mail').modal('hide');
  }
  editDetail(){
    var self = this;
    self.submitted = true;
    var editData = self.editMailform.value;
    self.sharedService.post('v1/mail/edit',editData).subscribe((res:any)=>{
      if (!res.error) {
        if(res.replyCode == 'success'){
          self.submitted = false;
          self.mailList();
          $('#edit_mail').modal('hide')
           this.toastr.successToastr(res.replyMsg,'Success')
        }
      }
    }
    ,err =>{
      this.toastr.errorToastr(err.error.replyMsg,'Error')
    })

}
send_mail(){
  $('#send_mail').modal('show')
}
closeSendMail(){
  $('#send_mail').modal('hide');
}
mail_id(data){
  // [routerLink]="['/dashboard/mail-list']"
  this.mailId = data;
  sessionStorage.setItem("mail_list",JSON.stringify(this.mailId))
  this.router.navigateByUrl('/dashboard/mail-list');
}


}
