import * as RedirectionPath from '../../common/redirect';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService, MustMatch, SharedserviceService } from 'src/app/services';
import { DatePipe} from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DateAdapter } from '@angular/material';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Sort } from '@angular/material/sort';
import { ToastrManager } from 'ng6-toastr-notifications';

declare var $: any;
@Component({
  selector: 'app-partner-company',
  templateUrl: './partner-company.component.html',
  styleUrls: ['./partner-company.component.scss'],
  providers: [DatePipe, NgxUiLoaderService,Ng2SearchPipeModule]
})
export class PartnerCompanyComponent implements OnInit {
  public environment = environment;
  public dm = 'in';
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
  todate: any = "";  fromdate: any = "";  todepdate: any = "";  fromdepdate: any = "";  depcity: any = "";  arrcity: any = "";
  public imagePath;  image:any;  File: File=null;  public message: string=''; searchreportbool:boolean=false; totalpage: any;
  searchText:any;  summaryDtl:boolean = false;  bgBannerPath: any;  headBner: boolean = false;  sellerDtl: boolean = false;  searchFlagReset: boolean = false;
  cancelticket: any;  return_d_date: any;  rreturn_d_date: any;  agentReport: any;  btnsubmitted: boolean = false;  submitted: boolean = false;  Id: any;
  bodydata: any = {}; data: any; keys: any; TotalCount: any; msg: any; ccData: any;  TData: any;
  flag: boolean = false;  clientView: any;  nodatafound: boolean = false;  page: any = {};
  venderprofile: any;  sortedBook: any[];  userdata: any;  sectordata: any;  userid: any = "";  sector: any = "";  type: any = "";  filterstatus: any = "";
  imgPath: any;  bookData: any;  statusform: FormGroup;  userlist: any;  flightData: any;  returnFlight: any; flt_bokg_dtl: any;
  today_date: Date;   venderdetail:any=JSON.parse(sessionStorage.getItem('partner_api_booking_name'))
  profileImg: any;  d1: any;  p: number = 1;  type1: any;  imgUrl: any;
  cDataCount:any;
  constructor(
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    public sharedService: SharedserviceService,
    public router: Router,
    public commonService: CommonService,
    private toastr: ToastrManager,
    public activatedRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
  ) {
    this.activatedRoute.params.forEach((urlParams) => {
      this.Id = urlParams['id'];
      this.type1 = urlParams['type'];
      if (this.Id) {
        this.userid = this.Id;
        this.filterstatus = ""
      }
      this.dateAdapter.setLocale('en-GB');
      this.sortedBook = this.ccData;
      this.userdata = JSON.parse(localStorage.getItem("user"));
      this.imgUrl = this.sharedService.serviceBase
      this.profileImg = this.imgUrl + 'uploads/userprofile';

    });
    this.today_date = new Date()

  }
  sortData(sort: Sort) {
    const data = this.ccData;
    if (!sort.active || sort.direction === '') {
      this.sortedBook = data;
      return;
    }
    this.sortedBook = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'departure_date': return compare(a.departure_date, b.departure_date, isAsc);
        case 'booking_date': return compare(a.booking_date, b.booking_date, isAsc);
        case 'reference_id': return compare(a.reference_id, b.reference_id, isAsc);
        case 'seller_name': return compare(a.seller_name, b.seller_name, isAsc);
        case 'buyer_name': return compare(a.buyer_name, b.buyer_name, isAsc);
        case 'sector': return compare(a.sector, b.sector, isAsc);
        case 'total_book_seats': return compare(a.total_book_seats, b.total_book_seats, isAsc);
        case 'total_amount': return compare(a.total_amount, b.total_amount, isAsc);
        default: return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  applyFilter(event: Event) {
    $("#myInput").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#myTable1 tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });
  }
  ngOnInit() {
    this.bodydata['role_id'] = '3';
    this.clientView = this.fb.group({
      role_id: ['3', Validators.required],
      name: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.maxLength(10)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.statusform = this.fb.group({
      status: ['', Validators.required],
      share_inventory: ['', Validators.required]
    });
    this.bodydata = {
      page_limit: 10,
      page_no: 1,
      search: "",
      display_type: this.type1,
      user_id: Number(this.userid),
      status: this.filterstatus,
      flight_type: this.type,
      sector: this.sector,
      start_date: "",
      end_date: "",
      order_key: "booking_date",
      order_value: "desc",
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB",
    }
    this.bodydata['page_no'] = '1';
    this.bookinglist(this.bodydata);
  }

  sellerInfo(event) {
    if (event.checked == true) {
      this.sellerDtl = true;
    } else {
      this.sellerDtl = false;
    }
  }

  clBalance() {
    $('#addBalance').modal('hide');
  }

  addAccount() { }

  statuschange() {
    var status;
    if (this.venderdetail.status == 1) {
      status = 0;
    } else {
      status = 1;
    }
    this.submitted = true;
    var data = {
      share_inventory_id: this.venderdetail.inv_id,
      status: Number(status),
      patner: "yes"
    }

    this.sharedService.patch('v1/share_inventory/change_share_inventory_status', data).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.d1 = data;

        this.toastr.successToastr(res.replyMsg, 'Success');
        $("#apistatus").modal('hide');
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  vender_detail() {
    var data = {
      id: this.Id
    }
    this.sharedService.post('v1/users/user_details', data).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.venderprofile = res.data;
        if (res.data.credit_limit == null || res.data.credit_limit == "") {
          this.venderprofile.credit_limit = 0;
        }

        if (res.data.contact_person_name != null || res.data.name != null) {
          if (res.data.contact_person_name) {
            this.venderprofile.ownername = res.data.contact_person_name;
          } else {
            this.venderprofile.ownername = res.data.name;
          }
        }

        if (res.data.gst_no == null || res.data.gst_no == "") {
          this.venderprofile.gst_no = "NA";
        }
        $('#contact').modal('show');
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });

  }

  loadList() {
    var sendBookingData = {
      user_id: this.userid,
      display_type: this.type1
    }
    this.ngxLoader.start();
    this.sharedService.post('v1/booking/my_booking_count', sendBookingData).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.data = res.data;
        this.ngxLoader.stop();

      } else {
        this.ngxLoader.stop();
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.ngxLoader.stop();
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  bookinglist(bodydata) {
    this.sharedService.post('v1/booking/partner_api_booking_list', bodydata).subscribe((res) => {
    // this.sharedService.post('v1/booking/my_booking_list', bodydata).subscribe((res) => {

      if (res.replyCode == 'success') {
        this.ccData = res.data;
        this.cDataCount = res.count
        if ('vendor_Details' in res) {
          // this.venderdetail = res.vendor_Details[0];
          // this.statusform.value.share_inventory = res.vendor_Details[0].inventory_share_id;
          // this.statusform.value.status = res.vendor_Details[0].inventory_share_status;
        }
        if ((res.data).length > 0) {
          this.data = res.data;
          this.keys = Object.keys(this.data[0]);
          this.TotalCount = res.count;
        } else {
          this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
        }
        this.loadList()
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }


  // viewDetail(element) {

  //   var self = this;
  //   var Objects = {
  //     user_id: this.userdata.id,
  //     flight_booking_id: element.flight_booking_id
  //   }
  //   this.sharedService.post('v1/flight/patnerapi_flight_booking_details', Objects).subscribe((res) => {
  //     if (res.replyCode == 'success') {
  //       var counter = 0;
  //       this.bgBannerPath = res.bannerimgBasePath;
  //       setTimeout(() => {
  //         this.imgPath = res.imgBasePath;
  //         res.data.flight_booking_details.forEach(element => {

  //           if (element.ticket_cancel_at == null) {
  //             element.t_status = 'booked'
  //           } else {
  //             counter += 1;
  //             element.t_status = 'cancelled'
  //           }
  //         });
  //         if (counter == res.data.total_book_seats) {
  //           res.data.ticket_status = "cancelled"
  //         } else {
  //           res.data.ticket_status = "booked"
  //         }
  //         this.bookData = res.data;
  //         this.bookData.cal_base_fare = ((this.bookData.flightData.flight_fare_taxes[0].base_fare * (this.bookData.adult + this.bookData.children)) + (this.bookData.flightData.infant_base_price * this.bookData.infant));
  //         this.bookData.cal_fees_and_taxes = (this.bookData.flightData.flight_fare_taxes[0].fee_taxes * (this.bookData.adult + this.bookData.children));
  //         this.bookData.servicecharge = (this.bookData.amount - (this.bookData.cal_base_fare) - (this.bookData.cal_fees_and_taxes));
  //         this.bookData.ticket_id = element.flight_booking_id;

  //         this.flightData = res.data;
  //         self.returnFlight = res.data.flightData.return_flight;
  //         self.flt_bokg_dtl = res.data.flight_booking_details;


  //       }, 100);
  //       $('#genrepo').modal('show');
  //     }
  //   });

  // }

  viewDetail(element){
    sessionStorage.setItem("booking_id", JSON.stringify(element.id));
    window.open(RedirectionPath.processUrl('booking-summary'), '_blank');
  }

  // download() {
  //   var fromdate;
  //   var todate;
  //   if (this.fromdate != '') {
  //     fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');
  //   } else {
  //     fromdate = ''
  //   }
  //   if (this.todate != '') {
  //     todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
  //   } else {
  //     todate = ''
  //   }
  //   this.searchFlagReset = true;
  //   var DataSearch = {
  //     page_limit: (this.TotalCount).toString(),
  //     page_no: "1",
  //     search: String(this.inputSearch.nativeElement.value),
  //     display_type: this.type1,
  //     status: "",
  //     flight_type: "",
  //     sector: "",
  //     start_date: fromdate,
  //     end_date: todate,
  //     order_key: "departure_date",
  //     order_value: "desc",
  //     flight_tbl_alias: "FT",
  //     flight_booking_tbl_alias: "FB",
  //     user_id:Number(this.userid),
  //   }
  //   // this.bookinglist(DataSearch);
  //   this.sharedService.post('v1/booking/my_booking_list', DataSearch).subscribe((res) => {
  //     if (res.replyCode == 'success') {  this.TData = res.data  } else {
  //       this.toastr.errorToastr(res.replyMsg, 'Error');
  //     }
  //     setTimeout(() => {
  //       // $('#Exportmodal').modal('show');
  //       this.exportdata();
  //     }, 1000);
  //   }, err => {
  //     this.toastr.errorToastr(err.error.replyMsg, 'Error');
  //   });
  // }

  exportdata(): void {
    /* table id is passed over here */
    let element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element,{dateNF:'dd/MMM/yyyy;@',cellDates:true, raw: true});

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.ngxLoader.stop();
    /* save to file */
    XLSX.writeFile(wb, 'Excel-Report.xlsx');

  }



  durationdiff(dep_time, arr_time, dep_date, type) {
    var dep_hr = parseInt(dep_time.split(':')[0]);
    var arr_hr = parseInt(arr_time.split(':')[0]);
    if (arr_hr < dep_hr) {
      var nwdate;
      if (!dep_date) {
        nwdate = new Date();
      } else {
        nwdate = new Date(dep_date);
      }

      var newdate = nwdate.setDate(nwdate.getDate() + 1);
      if (type == 'oneway') {
        this.return_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
      } else {
        this.rreturn_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
      }

    } else {
      this.return_d_date = dep_date;
      this.rreturn_d_date = dep_date;
    }
    var start_date = dep_date + " " + dep_time;
    var end_date = this.return_d_date + " " + arr_time;
    return this.getDataDiff(new Date(start_date.toString()), new Date(end_date.toString()));

  }

  //------------Download PDF---------------
  // generatePDF1() {
  //   this.ngxLoader.start();
  //   var data = document.getElementById('contentToConvert1');
  //     html2canvas(data).then(canvas => {
  //       var imgWidth = 208;
  //       var imgHeight = canvas.height * imgWidth / canvas.width;
  //       const contentDataURL = canvas.toDataURL('image/png')
  //       let pdf = new jspdf('p', 'mm', 'a4');
  //       var position = 5;
  //       pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //       pdf.save('download.pdf');
  //     });
  //   this.ngxLoader.stop();
  // }

  printpreview() {
    var printW = window.open("");
    var html = document.getElementById('contentToConvert1').innerHTML;

    printW.document.write('<html><head><title>Booking Details</title>');
    printW.document.write(`<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" type="text/css" />`);
    printW.document.write(`<style>
     .col-3 {-webkit-box-flex: 0;flex: 0 0 25%;max-width: 25%;}
     .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
     table td{border-bottom:1px solid#ddd;padding:5px;border-collapse: collapse;} table th {border-collapse: collapse;border-bottom:1px solid#ddd;padding:5px;font-weight: 600;color: #000;} table{ width: 100%; border-spacing:1px;border-collapse: separate;}.row{display: flex;flex-wrap: wrap;width:100%;}.col-md-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}.img-box {width: 50px;height: 50px;border: 1px solid #ddd;object-fit:cover;}
     .border {border: 1px solid #dee2e6!important;}
     table{margin-bottom:5px !important;}
     .ph-text{height:220px !important;font-size:10px !important;line-height:13px !important;}
     .row {display: -webkit-box;display: flex;flex-wrap: wrap;}
      .text-left {text-align: left!important;}
      .img-profile {width: 100%;height: auto;-o-object-fit: contain;object-fit: contain;max-height: 70px;}
      .text-center {text-align: center!important;}
      .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
      .head-clr {color: #ff6a00;}.fw-500 {font-weight: 500;}
      .m-0 {margin: 0!important;}.w-100 {width: 100%!important;}.text-center {text-align: center!important;}
      .bg-white {background-color: #fff!important;}
      table {border-collapse: collapse;}
      .table {width: 100%;color: #212529;}
      .col-lg-4 {-webkit-box-flex: 0;flex: 0 0 33.333333%;max-width: 33.333333%;}
      .wp-100{width:100%;} .wp-30{width:30% !important;}
      .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        margin-bottom:.5rem !important;font-weight: 500;line-height: 1.2;}
      .mytbl td {padding: 0.5rem;margin-bottom: 0rem !important;}
      .mytbl td {padding: 0.5rem !important;vertical-align: middle;padding-left: 0.75rem !important;}
      .table td, .table th {padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;}
      .border-bottom {border-bottom: 1px solid #dee2e6!important;}
      .img-box-fix {width: 50px;height: 30px;-o-object-fit: contain;object-fit: contain;}
      img {vertical-align: middle;border-style: none;}
      .textclr {color: #828282;}
      .ft-12 {font-size: 12px !important;}
      .align-self-center {align-self: center!important;}
      .table-responsive {display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
      .d-none {display: none!important;}.text-dark {color: #343a40!important;}.h6, h6 {font-size: 1rem;}
      .form-control:disabled, .form-control[readonly] {background-color: #e9ecef !important;opacity: 1;}
      .form-control {display: block;width: 100%;height: calc(1.5em + .75rem + 2px);padding: .375rem .75rem;font-size: 1rem;
        font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: .25rem;-webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
      .prnt-h{line-height:32px !important;}
      .form-control-1{border:1px solid#ddd;border-radius:5px;background-color:#e9ecef;color:#303030;padding:8px;}
      .w-10pr{width:10%;}.w-14pr{width:14%;}.w-25pr{width:25%;}
      .pmt-10{margin-top:10px;}
      .mytbl tr td{padding:2px;}
      .p-Size{font-size:12px;}.pmb-1{margin-bottom:10px !important;}.pmb-2{margin-bottom:15px !important; }
      .mytbl tr td{padding:2px;}.pmt-1{margin-bottom:10px !important;}.pmt-2{margin-bottom:15px !important; }
      .pr-dnone{display:none !important;}
      </style>`);
    printW.document.write('</head><body >');
    printW.document.write(html);
    printW.document.write('</body></html>');



    printW.document.close();
    printW.focus();
    printW.print();
    // if(this.commonService.osname != "Mac OS"){
    //   printW.close();
    // }else{
    //   console.log("not Windows os")
    // }
  }

  getDataDiff(s_date, e_date) {
    var diff = e_date.getTime() - s_date.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return hours + " hrs " + ": " + minutes + " min";
  }

  searchreport(){
    // console.log("from date and to date ------->>",this.fromDate,this.toDate)
    if(this.fromdate != '' && this.todate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.fromdepdate != '' && this.todepdate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.depcity != '' && this.arrcity != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else{
      this.searchreportbool=true;
    }
  }

  onSearch() {
    var element;
    if (this.fromdate != '') {
      this.fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');
    } else {
      this.fromdate = ''
    }
    if (this.todate != '') {
      this.todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
    } else {
      this.todate = ''
    }
    if (this.fromdepdate != '') {
      this.fromdepdate = this.datePipe.transform(new Date(this.fromdepdate), 'yyyy-MM-dd');
    } else {
      this.fromdepdate = ''
    }
    if (this.todepdate != '') {
      this.todepdate = this.datePipe.transform(new Date(this.todepdate), 'yyyy-MM-dd');
    } else {
      this.todepdate = ''
    }

    var DataSearch = {
      page_limit: this.TotalCount,  page_no: "",
      fromBookingDate:this.fromdate,  toBookingDate:this.todate,  fromDepDate:this.fromdepdate,  toDepDate:this.todepdate,
      depCity:this.depcity.toUpperCase(),  arrCity:this.arrcity.toUpperCase(),
      search: '',
      // search: String(this.inputSearch.nativeElement.value),
      display_type: this.type1, inventoryOwner:'',  partnerId:'',
      pnr:'', bookingStatus:'',  status: "",  flight_type: "",
      sector: "",  start_date: this.fromdate,  end_date: this.todate,
      order_key: "departure_date",
      order_value: "desc",
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB",
      user_id:Number(this.userid),
    }
    this.sharedService.post('v1/booking/partner_api_booking_list', DataSearch).subscribe((res) => {
        if (res.replyCode == "success") {
          this.ccData = res.data;  this.totalpage = (res.count)/(DataSearch.page_limit);  this.TotalCount = res.count;
        }
      }, err => {
      })
  }

  SearchDep(){
    if(this.depcity.length == 3){
      this.searchreport()
    }
  }

  SearchArr(){
    if(this.arrcity.length == 3){
      this.searchreport()
    }
  }

  download() {
    this.ngxLoader.start();
    // var data = {  todate: this.todate,  fromdate: this.fromdate  }
    var fromdate;  var todate; var fromdepdate;  var todepdate; var depcity; var arrcity;
    if (this.fromdate != '') {  fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');  }
    else {  fromdate = ''  }
    if (this.todate != '') {  todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');  }
    else {  todate = ''  }

    if (this.fromdepdate != '') {  fromdepdate = this.datePipe.transform(new Date(this.fromdepdate), 'yyyy-MM-dd');  }
    else {  fromdepdate = ''  }
    if (this.todepdate != '') {  todepdate = this.datePipe.transform(new Date(this.todepdate), 'yyyy-MM-dd');  }
    else {  todepdate = ''  }

    if (this.depcity != '') {  depcity = this.depcity;  }
    else {  depcity = ''  }
    if (this.arrcity != '') {  arrcity = this.todate;  }
    else {  arrcity = ''  }

    // var requestdata = {
    //     page_limit: this.TotalCount,  page_no: "",
    //     fromBookingDate:fromdate,  toBookingDate: todate,
    //     fromDepDate: fromdepdate,  toDepDate: todepdate,
    //     depCity: depcity,  arrCity:arrcity
    // }
    var DataSearch = {
      page_limit: this.TotalCount,  page_no: "",
      fromBookingDate:fromdate,  toBookingDate: todate,
      fromDepDate: fromdepdate,  toDepDate: todepdate,
      depCity: depcity,  arrCity:arrcity,
      search: '',
      display_type: this.type1, inventoryOwner:'',  partnerId:'',
      pnr:'', bookingStatus:'',  status: "",  flight_type: "",
      sector: "",  start_date: fromdate,  end_date: todate,
      order_key: "departure_date",
      order_value: "desc",
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB",
      user_id:Number(this.userid),
    }

    this.searchFlagReset = true;
    // this.filtersearch(requestdata);
    this.sharedService.post('v1/booking/partner_api_booking_list', DataSearch).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.nodatafound = false;
        this.TData = res.data;
        // $('#Exportmodal').modal('show');
        setTimeout(() => {
          this.exportdata();
        }, 1000);
      } else {
        this.ngxLoader.stop();  this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.ngxLoader.stop();  this.nodatafound = true;  this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }

  onReset() {
    this.inputSearch = null;
    this.searchFlagReset = false;
    this.bodydata['search'] = '';
    this.fromdate = '';
    this.todate = '';
    this.bookinglist(this.bodydata);
  }

  onPageChange(event) {
    if (this.fromdate != '') {
      this.fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');
    } else {
      this.fromdate = ''
    }
    if (this.todate != '') {
      this.todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
    } else {
      this.todate = ''
    }
    if (this.fromdepdate != '') {
      this.fromdepdate = this.datePipe.transform(new Date(this.fromdepdate), 'yyyy-MM-dd');
    } else {
      this.fromdepdate = ''
    }
    if (this.todepdate != '') {
      this.todepdate = this.datePipe.transform(new Date(this.todepdate), 'yyyy-MM-dd');
    } else {
      this.todepdate = ''
    }

    var DataSearch = {
      page_limit: this.TotalCount,  page_no: "",
      fromBookingDate:this.fromdate,  toBookingDate:this.todate,  fromDepDate:this.fromdepdate,  toDepDate:this.todepdate,
      depCity:this.depcity.toUpperCase(),  arrCity:this.arrcity.toUpperCase(),
      search: '',
      display_type: this.type1, inventoryOwner:'',  partnerId:'',
      pnr:'', bookingStatus:'',  status: "",  flight_type: "",
      sector: "",  start_date: this.fromdate,  end_date: this.todate,
      order_key: "departure_date",
      order_value: "desc",
      flight_tbl_alias: "FT",
      flight_booking_tbl_alias: "FB",
      user_id:Number(this.userid),
    }
    this.bookinglist(DataSearch);
  }

  summaryShow(event) {
    if (event.checked == true) {
      this.summaryDtl = true;
    } else {
      this.summaryDtl = false;
    }
  }


}
