import { Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router} from '@angular/router';
import { SharedserviceService } from 'src/app/services/sharedservice.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiRequestService } from 'src/app/services/api.service';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;


@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
  providers:[DatePipe,NgxUiLoaderService]
})
export class SocialMediaComponent implements OnInit {
  socialpost:any;
  Socialpost=[
    { id: '2', name: "instagram" },
    { id: "1", name: "facebook" },
  ];
  public socialPostForm: FormGroup|any;
  public message: string;
  public imagePath;
  socialimage: any='';
  mediaUrl: any;
  selectedFile: File = null;
  postData: any;
  typeListData: any;
  myData: any;
  keys: string[];
  TotalCount: any;
  msg: string;
  flag: boolean;
  bodydata: any;
  hidediv: boolean = false;
  listData:any=[];

  submitted: boolean = false;
  submitted2: boolean = false;
  submitted3: boolean = false;
  submitted4: boolean = false;
  public whatsappSettingForm: FormGroup;
  public whatsappForm: FormGroup;
  public customMessageForm: FormGroup;
  getMssgData: any;
  postName: any = false;
  socialPlatform: any;
  accountName: any;

  scheduleDate: any ='';

  toDate: any ='';
  fromDate: any ='';
  searchByFilter: any="";
  searchByStatus: any="";
  searchBySocialmedia:any= "";
  resetButton: boolean = false;
  deletePostid: any;
  accType: any;
  today_date = new Date();
  TDate: any;
  FDate: any;
  getTime: any;
  Sdate: any;
  postId: any;
  showImage:any = '';
  socialAccountName: any;
  instaID: any;
  fbID: any;
  smoSettingID: any;

  constructor(public ngxloader:NgxUiLoaderService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public router: Router,public apiService: ApiRequestService,
    private toastr: ToastrManager
  ) { }

  ngOnInit() {
    this.socialPostForm = this.fb.group({
      caption: ['',Validators.required],
      post_type: ['',Validators.required],  //check account type insta aur facebook
      schedule_date: [''],
      schedule_time: [''],
      page_id: [''],
      ig_user_id: [''],
      smo_setting_id: [''],
    });

    this.getSocialPostData();
  }
  get validate() { return this.socialPostForm.controls; }
  get customValidate() { return this.customMessageForm.controls; }


  getSocialPostData(){
    const apiUrl = 'v1/user_smo/get_filtered_post?status='+this.searchByStatus+'&post_type='+this.searchBySocialmedia+'&from=&to=';
    this.sharedService.get(apiUrl).subscribe((res) => {
      if (res.replyCode == 'success') {
        if (res.data) {
          this.postData = res.data.filteredData;
          this.mediaUrl = res.data.mediaUrl;
          this.TotalCount = res.data.count;

        } else {
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  // ---------------Delete
    onGetDeleteData(event){
      this.deletePostid =  event.post_id,
      // this.accType = event.post_type,
      $('#delete').modal('show');
    }

    onDeletePost(){
      var post_id= this.deletePostid;
      var requestData = {
      //   post_id: this.deletePostid,
      //   acc_type: this.accType,
      }
      this.sharedService.post('v1/user_smo/delete_post_from_db/'+post_id,requestData).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.getSocialPostData();
          $('#delete').modal('hide');
          this.toastr.successToastr(res.replyMsg, 'Success');
        } else {
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, err => {
        this.toastr.errorToastr(err['error']['message'], 'Error');
      });
    }

    onCloseDeleteModal(){
      $('#delete').modal('hide');
    }
  //-----------end


  closeModal(){
    this.socialPostForm.reset();
    this.showImage = "";
    this.postName = false;
    $('#file2').val();
    $('#socialMediaModal').modal('hide');
  }

  onFileChanged1(event) {
    this.selectedFile = event.target.files[0];
  }

  preview(files) {
    if (files.length === 0){
      return;
    }

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.socialimage = reader.result;
      this.message = '';
    }
  }

  onCheckPostType(event){
    if(event==1){
      this.postName = false;
    }else{
      this.postName = true;
    }
  }

  preview2(files) {
    if (files.length === 0){
      return;
    }

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.showImage = reader.result;
      this.message = '';
    }
  }

  selectTime(event:any){
    this.getTime = event;
  }

  selectScheduleDate(event){
    if(event!= ''){
      this.Sdate = this.datePipe.transform(new Date(event), 'yyyy-MM-dd');
    }
  }

  onChangeSocialPlatform(data){
    // this.socialPlatform = data;
  }

  selectSocialPlatform(event){
    this.socialPlatform = event;
    var requestData = {
      acc_type : event,
    }
    this.sharedService.postRequest('v1/user_smo/get_user_smo_account', requestData).subscribe((res: any) => {
      if (!res.error) {
        this.socialAccountName = res.data;

      } else {
        this.toastr.errorToastr(res.error.replyMsg, 'Error Message', { timeOut: 200 });
      }
    }, error => {
      this.toastr.errorToastr(error.error.replyMsg, 'Error Message', { timeOut: 200 });
    });

  }

  onSelectAccountName(event){
    this.instaID = "";
    this.fbID="";
    this.accountName = event
    if((event != '') || (event != null)){
      var skinName = this.socialAccountName.find(x=>x.id == event);

      if(this.socialPlatform == 2){
        this.instaID = skinName.ig_user_id;
        this.smoSettingID = event;
      }else{
        this.fbID = skinName.page_id;
        this.smoSettingID = event;
      }
    }
  }

  onUploadPost(){
    this.submitted = true;
    if (this.socialPostForm.valid) {
      const uploadData = new FormData();
      if (this.selectedFile == null) {
        uploadData.append('file', '');
      } else {
        uploadData.append('file', this.selectedFile, this.selectedFile.name);
      }
      uploadData.append('caption', this.socialPostForm.value.caption);
      uploadData.append('post_type', this.socialPlatform);
      uploadData.append('ig_user_id', this.instaID);
      uploadData.append('page_id', this.fbID);
      uploadData.append('smo_setting_id', this.smoSettingID);
      // uploadData.append('post_type', (this.postName==true)?'2':'1');

      if(this.postName==true){
        uploadData.append('schedule_date', this.Sdate);
        uploadData.append('schedule_time', this.getTime);
      }

      if(this.postName==true){
        var apiUrl = 'v1/user_smo/scheduled_post_on_smo';
      }else{
        var apiUrl = 'v1/user_smo/instant_post_smo';
      }

      this.ngxloader.start();
      this.sharedService.postRequest(apiUrl, uploadData).subscribe((res: any) => {
        if (!res.error) {
          this.ngxloader.stop();
          this.submitted = false;
          this.socialPostForm.reset();
          this.showImage = "";
          $('#file2').val();
          $('#socialMediaModal').modal('hide');
          this.getSocialPostData();
          this.toastr.successToastr(res.replyMsg, 'Success', { timeOut: 200 });

        } else {
          this.ngxloader.stop();
          this.toastr.errorToastr(res.error.replyMsg, 'Error Message', { timeOut: 200 });
        }
      }, error => {
        this.ngxloader.stop();
        this.toastr.errorToastr(error.error.replyMsg, 'Error Message', { timeOut: 200 });
      });
    } else {
      this.ngxloader.stop();
      this.toastr.errorToastr(" Please Fill Form Completely", 'Error Message', { timeOut: 200 });
    }
    // this.ngxloader.stop();
  }


  // ----------Searching

    searchDate(){
      if(this.toDate!='' || this.fromDate!=''){
        this.TDate = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd');
        this.FDate = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd');
        if(this.searchByFilter!=''){
          this.onSearch();
        }
      }
    }

    onChangeFilter(event:any){
      this.searchByFilter = event;
      if(this.toDate!='' || this.fromDate!=''){
        this.onSearch();
      }
    }

    onChangeStatus(event:any){
      this.searchByStatus = event;
      this.onSearch();
    }

    onChangeSocialmedia(data:any){
      this.searchBySocialmedia = data;
      this.onSearch();
    }

    onSearch(){
      this.resetButton = true;
      const apiUrl = 'v1/user_smo/get_filtered_post?status='+this.searchByStatus+'&post_type='+this.searchBySocialmedia+'&from='+this.FDate+'&to='+this.TDate+'&filteredBy='+ this.searchByFilter+'';
      this.sharedService.get(apiUrl).subscribe((res) => {
        if (res.replyCode == 'success') {
          if (res.data) {
            this.postData = res.data.filteredData;
          } else {
            this.toastr.errorToastr(res.replyMsg, 'Error');
          }
        } else {
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, err => {
        this.toastr.errorToastr(err['error']['message'], 'Error');
      });
    }

    onResetAllFields(){
      this.searchByFilter = "";
      this.searchByStatus = "";
      this.searchBySocialmedia = "";
      this.resetButton = false;
      this.getSocialPostData();
    }
  // ----------End Searching


  onEditPostData(event:any){
    console.log(event,"----------------------382---*",event.schedule_time);
    this.postId = event.id;
    this.postName = (event.schedule_time==''||event.schedule_time==null)?false:true;
    this.getTime = event.schedule_time;
    this.scheduleDate = event.schedule_date;
    this.socialimage = this.mediaUrl+event.media_file;
    this.accountName = event.post_id;

    this.socialPostForm.patchValue({
      caption: event.caption == null ? '' : event.caption,
      post_type: event.post_type == null ? '' : event.post_type,
      ig_user_id: event.post_id == null ? '' : event.post_id,
    });

    $('#editMediaModal').modal('show');

  }

  closeEditModal(){
    this.socialPostForm.reset();
    $('#editMediaModal').modal('hide');
  }

  onUpdatePost(){
    this.submitted = true;
    if (this.socialPostForm.valid) {
      const uploadData = new FormData();
      if (this.selectedFile == null) {
        uploadData.append('file', '');
      } else {
        uploadData.append('file', this.selectedFile, this.selectedFile.name);
      }
      uploadData.append('caption', this.socialPostForm.value.caption);
      uploadData.append('post_type', this.socialPlatform);
      uploadData.append('ig_user_id', (this.socialPlatform ==2)?'17841403285826638':'');
      uploadData.append('page_id', (this.socialPlatform ==1)?'664350253617976':'');

      if(this.postName==true){
        uploadData.append('schedule_date', (this.Sdate==''||this.Sdate== undefined)?this.scheduleDate:this.Sdate);
        uploadData.append('schedule_time', this.getTime);
      }


      this.sharedService.postRequest('v1/user_smo/update_single_post/'+this.postId, uploadData).subscribe((res: any) => {
        if (!res.error) {
          this.submitted = false;
          $('#socialMediaModal').modal('hide');
          this.socialPostForm.reset();
          this.getSocialPostData();
          this.toastr.successToastr(res.replyMsg, 'Success', { timeOut: 200 });

        } else {
          this.toastr.errorToastr(res.error.replyMsg, 'Error Message', { timeOut: 200 });
        }
      }, error => {
        this.toastr.errorToastr(error.error.replyMsg, 'Error Message', { timeOut: 200 });
      });
    } else {
      this.toastr.errorToastr(" Please Fill Form Completely", 'Error Message', { timeOut: 200 });
    }
  }


}
