import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from 'src/app/services';
import { Sort } from '@angular/material/sort';
import { CommonService } from 'src/app/services/commonservice.service'
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var $: any;

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  providers: [NgxUiLoaderService]

})
export class AccountsComponent implements OnInit {
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
  bodydata: any = {};
  TotalCount: string; data: any; keys: any; public page: number = 1; start = 0; msg: any; status: any; FormId: any; flag: boolean = false;
  searchFlagReset: boolean = false;
  sortedList: any[];
  accountData: any;
  ccData: any;
  data2: any;
  old_credit_limit: any;
  public addaccountValue: FormGroup;
  public addaccountPartner: FormGroup;
  submitted: boolean = false;
  userdata: any;
  old_available_balance: any; detail_data: any;

  constructor(
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public dataservice: CommonService,
    public router: Router,
    private toastr: ToastrManager,
    private activatedRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
  ) {
    this.userdata = JSON.parse(localStorage.getItem("user"));
    this.sortedList = this.ccData;
  }


  ngOnInit() {
    this.bodydata = {
      page_limit: 10,
      page_no: "1",
      search_value: "",
      order_key: "company_name",
      order_value: "asc",
    }

    if (this.userdata.role_id == 1) {
      this.accountList(this.bodydata);
      this.addaccountValue = this.fb.group({
        share_inventorie_id: ['', Validators.required],
        share_to_user_id: ['', Validators.required],
        amount: ['', Validators.required],
        narration: ['', Validators.required],
      })
    }
    else if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      this.accountList(this.bodydata);
      this.addaccountPartner = this.fb.group({
        patner_vendor_id: ['', Validators.required],
        amount: ['', Validators.required],
        narration: ['', Validators.required],
      })
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  get ac() { return this.addaccountValue.controls; }
  get pc() { return this.addaccountPartner.controls; }

  //*********shorting Start*********** */
  sortData(sort: Sort) {
    const data = this.ccData;
    if (!sort.active || sort.direction === '') {
      this.sortedList = data;
      return;
    }
    this.sortedList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'company_name': return compare(a.company_name, b.company_name, isAsc);
        case 'credit_limit': return compare(a.credit_limit, b.credit_limit, isAsc);
        case 'available_balance': return compare(a.available_balance, b.available_balance, isAsc);
        default: return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  accountdetail(list) {
    if (this.userdata.role_id == 1) {
      var data = {
        share_inventorie_id: list.share_inventorie_id,
        share_to_user_id: list.share_to_user_id,
        amount: list.credit_limit
      }
      sessionStorage.setItem("account_detail_data", JSON.stringify(data))
      this.router.navigateByUrl("dashboard/account-balance");
    }
    else if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      var data2 = {
        patner_vendor_id: list.patner_vendor_id,
        company_name: list.company_name,
        amount: list.available_balance
      }
      sessionStorage.setItem("account_detail_data", JSON.stringify(data2))
      this.router.navigateByUrl("dashboard/account-balance");
    }

    //this.dataservice.accountDtl(list);
  }

  accountList(bodydata) {
    this.ngxLoader.start();
    if (this.userdata.role_id == 1) {
      this.sharedService.post('v1/account/listing', bodydata).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.ccData = res.data;

          if (res.data.length > 0) {
            this.flag = false;
            this.data = res.data;
            this.data2 = res.data;
            this.keys = Object.keys(this.data[0]);
            this.TotalCount = res.data['count'];
            this.ngxLoader.stop();
          } else {
            this.flag = true;
            this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            this.ngxLoader.stop();
          }
        } else {
          this.flag = true;
          this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
          this.ngxLoader.stop();
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, err => {
        this.flag = true;
        this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
        this.ngxLoader.stop();
        this.toastr.errorToastr(err.error.replyMsg, 'Error');
      });
    }
    else if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      this.sharedService.post('v1/account/listing', bodydata).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.ccData = res.data;

          if (res.data.length > 0) {
            this.flag = false;
            this.data = res.data;
            this.keys = Object.keys(this.data[0]);
            this.TotalCount = res.data['count']
            this.ngxLoader.stop();
          } else {
            this.flag = true;
            this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            this.ngxLoader.stop();
          }
        } else {
          this.flag = true;
          this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
          this.ngxLoader.stop();
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, err => {
        this.flag = true;
        this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
        this.ngxLoader.stop();
        this.toastr.errorToastr(err.error.replyMsg, 'Error');
      });
    }

  }

  accountShow(data) {
    this.FormId = data;
    this.old_credit_limit = data.credit_limit;
    this.old_available_balance = data.available_balance;

    if (this.userdata.role_id == 1) {
      this.addaccountValue.patchValue({
        share_inventorie_id: data.share_inventorie_id,
        share_to_user_id: data.share_to_user_id
      })
    }
    else if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      this.addaccountPartner.patchValue({
        patner_vendor_id: data.patner_vendor_id,
      })
    }

    $('#addBalance').modal('show');
  }

  addAccount() {
    var self = this;
    self.submitted = true;
    if (this.userdata.role_id == 1) {
      if (self.addaccountValue.invalid) {
        return;
      } else {
        var request_data = self.addaccountValue.value;
        self.sharedService.postRequest('v1/account/add_balance', request_data).subscribe((res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              this.accountData = res.data;

              self.submitted = false;
              this.accountList(this.bodydata);
              $('#addBalance').modal('hide');
              self.addaccountValue.reset();
              self.submitted = false;
              self.toastr.successToastr(res.replyMsg, 'Success');
            } else {
              self.submitted = false;
              self.addaccountValue.reset();
              self.toastr.errorToastr(res.replyMsg, 'Error');
            }
          } else {
            self.submitted = false;
            self.addaccountValue.reset();
            self.toastr.errorToastr(res.error.replyMsg, 'Error');
          }
        }, error => {
          self.submitted = false;
          $('#addBalance').modal('hide');
          self.addaccountValue.reset();
          self.toastr.errorToastr(error.error.replyMsg);
        });
      }
    }
    else if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      if (self.addaccountPartner.invalid) {
        return;
      } else {
        var request_data = self.addaccountPartner.value;
        self.sharedService.postRequest('v1/patner_account/add_balance', request_data).subscribe((res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              this.accountData = res.data;
              self.submitted = false;
              this.accountList(this.bodydata);
              $('#addBalance').modal('hide');
              self.addaccountPartner.reset();
              self.submitted = false;
              self.toastr.successToastr(res.replyMsg, 'Success');
            } else {
              self.submitted = false;
              self.addaccountPartner.reset();
              self.toastr.errorToastr(res.replyMsg, 'Error');
            }
          } else {
            self.submitted = false;
            self.addaccountValue.reset();
            self.toastr.errorToastr(res.error.replyMsg, 'Error');
          }
        }, error => {
          self.submitted = false;
          $('#addBalance').modal('hide');
          self.addaccountPartner.reset();
          self.toastr.errorToastr(error.error.replyMsg);
        });
      }
    }

  }

  clBalance() {
    $('#addBalance').modal('hide');
    this.submitted = false;
  }

  onSearch() {
    this.searchFlagReset = true;
    var bodydata = {
      page_limit: "",
      page_no: "",
      search_value: String(this.inputSearch.nativeElement.value),
      order_key: "company_name",
      order_value: "desc",
    }
    this.accountList(bodydata);
  }
  onReset() {
    this.inputSearch.nativeElement.value = '';
    this.searchFlagReset = false;
    this.bodydata['search'] = '';
    this.accountList(this.bodydata);
  }
  onPageChange(event) {
    this.bodydata['page'] = event;
    this.accountList(this.bodydata);
  }
}
