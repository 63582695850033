import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DateAdapter } from '@angular/material';
import { DatePipe } from '@angular/common';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrManager } from 'ng6-toastr-notifications';

declare var $: any;

@Component({
  selector: 'app-accounting-list',
  templateUrl: './accounting-list.component.html',
  styleUrls: ['./accounting-list.component.scss'],
  providers: [DatePipe]
})
export class AccountingListComponent implements OnInit {
  public environment = environment;
  public dm = 'in';

  @ViewChild("inputSearch", { static: true }) inputSearch: ElementRef;
  // @ViewChild('inputSearch') keywordsInput;
  CancelTicketForm: FormGroup;
  pagLimit:number= 10; searchText:any;
  chng_pnr_bookingid:any;  Tdata: any; Tedata:any;
  chng_pnr_pnr:any;  bill_id: any = '';  acc_bill_no: any = '';  bodydata: any = {};
  addNewBill: FormGroup;  public editBill: FormGroup;  sale_data: any; isSaleData: any; purchase_date: any;  keys: any;  forApi: boolean = false
  submitted: boolean = false;  minDate: any;  maxDate: any;  date: any;  dataId: any;  editBillData: any
  Type: any;  searchreportbool:boolean=false;  TotalCount: string;  TTotalCount: string;  data: any;
  public page: number = 1;  start = 0;  msg: any;  FormId: any;  flag: boolean = false;  today_date: Date;  userdata:any;
  booking_id: any;  Sectors: any=[];    fromDate: any = '';  toDate: any = '';  fromBookingDate:any='';  toBookingDate:any='';
  paymentMode:any="0";  airline_code:any=0;  depcity:any='';  arrcity:any='';  pnr:any='';  passengerfilter:boolean=false;  p_name:any='';bookingStatus:any=1;
  inventoryOwner:any='';inventoryOwnerArray=[{ id: '0', name: "Select Owner" }]; partnerId:any=''; partnerIdArray=[{ id: '0', name: "Select Partner" }]; websiteUserId:any='';
  websiteUserIdArray=[{ id: '0', name: "Select User" }];
  NoOfStopData=[
    { id: '0', name: "Select Mode" },
    { id: 'paytm', name: "PayTM" },
    { id: 'wallet', name: "Wallet" },
  ];
  BookingStatus=[
    { id: 0, name: "Pending" },
    { id: 1, name: "Success" },
    { id: 2, name: "Cancel" },
  ]
  request: { fromBookingDate: any; toBookingDate: any; fromDepDate: any; toDepDate: any; soldFrom: string; depCity: any; arrCity: any; paymentMode: any; inventoryOwner: number; partnerId: number; websiteUserId: number; page_limit: string; page_no: number; sortorder: string; pnr:any; p_name:any;withPassenger:any;bookingStatus:1,airline_code:number;pax_search:any};
  requestItly: { 
    fromBookingDate: any,
    toBookingDate: any,
    fromDepDate: any,
    toDepDate: any,
    depCity: any,
    arrCity: any,
    paymentMode: 0,
    inventoryOwner: 0,
    partnerId: 0,
    pnr: any,       // input box to search
    pax: any,      // input box to search
    reference_id: any,         // input box to search
    airline_code:  any,        
    bookingStatus: any,       // 1 booked, 2 cancelled, 0 pending
    websiteUserId: number,
    page_limit: 10,
    page_no: 1  
  };
  isLoginUserDetails:any={};
  isAirlinesList:any=[
    {'name':"Select ALL","id":0,"code":""},
    {'name':"Air India","id":15,"code":"AI"},
    {'name':"AirAsia India","id":25,"code":"I5"},
    {'name':"GoFirst","id":72,"code":"G8"},
    {'name':"IndiGo","id":80,"code":"6E"},
    {'name':"SpiceJet","id":124,"code":"SG"},
  ]
  paxData: any;
  reference_id:any='';
  constructor(public sharedService: SharedserviceService,
    private ngxLoader:NgxUiLoaderService,
    private fb: FormBuilder,
    public router: Router,
    private toastr: ToastrManager,
    public datepipe: DatePipe,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,
    public commonService: CommonService,
    public activatedRoute: ActivatedRoute,public manageService:ManagedataService) {
    this.activatedRoute.params.forEach((urlParams) => {
      this.Type = urlParams['type'];
      this.bodydata = {
        page_limit: this.pagLimit,
        page_no: 1,
        sortorder: "desc",
        fromDate: "",
        toDate: '',
        pnr: "",
        withPassenger: false,
        p_name: "",
        bookingStatus:1
      }
      this.showList(this.Type, this.bodydata);
      this.userdata=JSON.parse(localStorage.getItem("user"));
    });
    this.today_date = new Date()
  }


  public settings = {};
  public selectedItems = [];
  public onFilterChange(item: any) { // console.log(item);
  }
  public onDropDownClose(item: any) {
    // console.log(item);
  }
  public onItemSelect(event: any,type:any) {
    // console.log(item);
    if(type == 'owner'){
      this.inventoryOwner=event.id;
    }else if(type == 'partner'){
      this.partnerId=event.id;
    }else if(type == 'user'){
      this.websiteUserId=event.id;
    }
    this.onSearch()
  }
  public onItemDeSelect(event: any,type:any) {
    // console.log(item);
    if(type == 'owner'){
      this.inventoryOwner="";
    }else if(type == 'partner'){
      this.partnerId="";
    }else if(type == 'user'){
      this.websiteUserId="";
    }
    this.onSearch()
  }
  public onSelectAll(items: any) {
    // console.log(items);
  }
  public onDeSelectAll(items: any) {
    // console.log(items);
  }

  ngOnInit() {

    // console.log("environment.lg.accountingSale========>>>>>>>",environment.lg.isUrl);
    
  this.settings = { singleSelection: true, idField: 'id', textField: 'name', enableCheckAll: false, allowSearchFilter: true, limitSelection: -1, clearSearchFilter: true, itemsShowLimit: 3, searchPlaceholderText: 'Enter Name Here...', closeDropDownOnSelection: true, showSelectedItemsAtTop: false, defaultOpen: false };

    this.manageService.GetLoggedInUserDetails().subscribe((response:any)=>{
      if(Object.keys(response).length>0){
        this.isLoginUserDetails=response;
      }
    })
    this.AllUser();
    this.myApiList();
    this.partnerapilist()
    this.bodydata = {
      page_limit: 10,
      page_no: 1,
      order_value: "asc",
      fromDate: "",
      toDate: '',
      pnr: "",
      withPassenger: false,
      p_name: "",
      bookingStatus:1
    }
    // this.CancelTicketForm

    this.CancelTicketForm = this.fb.group({
      booking_id: [''],
      refund: ['',[Validators.required]],
      new_price: ['',[Validators.required]],
      new_seat_qty: ['',[Validators.required]],
      narration: ['',[Validators.required]],
    })

  }
  get cancelticketformControlErrors() { return this.CancelTicketForm.controls }


  onPageLimitPurchase(value){
    this.pagLimit = value.target.value
    this.bodydata = {
      page_limit: this.pagLimit,
      page_no: 1,
      sortorder: "desc",
      fromDate: "",
      toDate: '',
      pnr: "",
      withPassenger: false,
      p_name: "",
      bookingStatus:this.bookingStatus
    }
    this.showList(this.Type, this.bodydata);
  }
  onPageLimit(value){
    this.pagLimit = value.target.value
    this.bodydata = {
      page_limit: this.pagLimit,
      page_no: 1,
      sortorder: "desc",
      fromDate: "",
      toDate: '',
      pnr: "",
      withPassenger: this.passengerfilter,
      p_name: "",
      bookingStatus:this.bookingStatus
    }
    this.showList(this.Type, this.bodydata);
  }
  resetFunction(){
    // this.router.navigate(['dashboard/accounting/sale'])
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['dashboard/accounting/sale']);
  }
  resetFunction2(){
    // this.router.navigate(['dashboard/accounting/sale'])
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['dashboard/accounting/purchase']);
  }
  isInventoryownerArray:any=[];
  isInventorypartnerArray:any=[];
  isInventoryuserArray:any=[];
  myApiList() {
    let self = this;
    var bodydata ={
      page_limit: "",
      page_no: "",
      search: "",
      order_key: "createdAt",
      order_value: "asc"
    }
    this.sharedService.post('v1/share_inventory/my_patner_api_list', bodydata).subscribe((res:any) => {
      if (res.replyCode == 'success') {

        // let sortUser=res.data.sort((a, b) => a.name.localeCompare(b.name));
        this.isInventoryownerArray=res.data.map(val => { return {
          // id: val.share_to_user_id,
          id: val.share_by_user_id,
          name: val.user.company_name
        }});

        // (res.data).forEach(element => {
        //   this.isInventoryownerArray=res.data;
        //   // this.isInventoryownerArray.push({ id: element.share_to_user_id, name: element.user.company_name })
        // });
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  partnerapilist() {
    var bodydata = {
      page_limit: "",
      page_no: "",
      search: "",
      order_key: "accepted_status",
      order_value: "desc"
    }
    this.sharedService.post('v1/share_inventory/created_patner_api_list', bodydata).subscribe((res) => {
      if (res.replyCode == 'success') {
        var myResData =res.data;
        this.isInventorypartnerArray = [];
        this.isInventorypartnerArray = myResData.map(val => {
          const companyName = val.user ? val.user.company_name : null;
          return {
            id: val.share_to_user_id,
            name: companyName
          };
        });
        this.partnerIdArray.push({
          id: this.userdata.id,
          name: this.userdata.company_name
        });
        (res.data).forEach(element => {
          const compName = element.user ? element.user.company_name : null;
          this.partnerIdArray.push({
             id: element.share_by_user_id,
             name: compName
          })
        });
      }
    });
  }

  AllUser() {
    this.sharedService.get('v1/users/all_users').subscribe((res) => {
      if (res.replyCode == 'success') {
        // let sortUser=res.data.sort((a, b) => a.name.localeCompare(b.name));
        this.isInventoryuserArray=res.data.map(val => { return {
          id: val.id,
          name: val.company_name ? val.company_name : val.name
        }});
        (res.data).forEach(element => {
          this.websiteUserIdArray.push({
             id: element.id, name: element.company_name ? element.company_name : element.name
          })
        });
      }
    });
  }

  showList(type, request) {
    if (type == 'sale') {   
      this.sharedService.post('v1/accounting/complete_sale_list', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
          if(res.data.length>0){
            this.sale_data = res.data;
            this.keys = Object.keys(this.sale_data[0]);
          }else{
            this.sale_data=[];
          }
          this.TotalCount = res.count;
          this.Sectors = res.sectors;
          // this.sale_data.forEach(function (value) {
          // })
        }
      }, err => {
        this.toastr.errorToastr(err.replyMsg)
      })
      
    } else if (type == 'purchase') {
      this.sharedService.post('v1/accounting/complete_purchase_list', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
          if(res.data.length>0){
            this.purchase_date = res.data;
            this.keys = Object.keys(this.purchase_date[0]);
          }else{
            this.purchase_date=[];
          }
          this.Sectors = res.sectors;
          this.TotalCount = res.count;
        }
      }, err => {
        this.toastr.errorToastr(err.replyMsg)
      })
    }
  }

  bookStatusSelect(event){
    this.bookingStatus = event;
    this.onSearch()
  }

  paymentSelect(event){
    this.paymentMode=event;
    this.onSearch()
  }
  airlineSelect(event){
    this.airline_code=event;
    this.onSearch()
  }

  OwnerSelect(event){
    this.inventoryOwner=event;
    this.onSearch()
  }

  PartnerSelect(event){
    this.partnerId=event;
    this.onSearch()
  }

  WebUserSelect(event){
    this.websiteUserId=event;
    this.onSearch()
  }

  SearchDep(){
    if(this.depcity.length == 3){
      this.searchreport()
    }
  }

  SearchArr(){
    if(this.arrcity.length == 3){
      this.searchreport()
    }
  }
  Searchpnr(){
    if(this.pnr.length >= 2){
      this.searchreport()
    }else if(this.pnr.length == 0){
      this.pnr='';
      this.searchreport()
    }
  }
  Searchrefno(){
    if(this.reference_id.length >= 6){
      this.searchreport()
    }else if(this.reference_id.length == 0){
      this.reference_id='';
      this.searchreport()
    }
  }

  Searchpassenger(){
    if(this.p_name.length >= 3){
      this.searchreport()
    }else if(this.p_name.length == 0){
      this.p_name='';
      this.searchreport()
    }
  }


  onPaxSearch(data) {
    if(data.length >= 3 ){
      var myReqData = {
        pax_search:data,
        fromBookingDate:this.fromBookingDate,
        toBookingDate:this.toBookingDate,
        fromDepDate:this.fromDate,
        toDepDate:this.toDate,
        soldFrom:'',
        depCity:this.depcity.toUpperCase(),
        arrCity:this.arrcity.toUpperCase(),
        paymentMode:this.paymentMode,
        inventoryOwner:Number(this.inventoryOwner),
        partnerId:Number(this.partnerId),
        websiteUserId:Number(this.websiteUserId),
        page_limit:"",
        page_no: "",
        sortorder: "desc",
        pnr:this.pnr.toUpperCase(),
        p_name:this.p_name,
        withPassenger:this.passengerfilter,
        bookingStatus:1,
        airline_code:this.airline_code,
        // airline_id:Number(this.airline_id),
      }
      this.ngxLoader.start();
    // this.sharedService.post('v1/accounting/complete_sale_list', myReqData).subscribe((res: any) => {
    //   if (res.replyCode == "success") {
    //     this.sale_data = res.data;
    //     // this.TotalCount = res.count;
    //     // this.keys = Object.keys(this.sale_data[0]);
    //   }
    //   this.ngxLoader.stop()
    // });

    if (this.Type == 'sale') {
      this.sharedService.post('v1/accounting/complete_sale_list', myReqData).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.sale_data = res.data;
          this.TotalCount = res.count;
          this.keys = Object.keys(this.sale_data[0]);
        }this.ngxLoader.stop()
      }, err => {
      })
    } else if (this.Type == 'purchase') {
      this.sharedService.post('v1/accounting/complete_purchase_list', myReqData).subscribe((res: any) => {
        if (res.replyCode == "success") {
          // this.Tdata = res.data;
          // this.keys = Object.keys(this.Tdata[0]);
          // this.TTotalCount = res.count;
          this.purchase_date = res.data;
          this.keys = Object.keys(this.purchase_date[0]);
          this.TotalCount = res.count;
        }this.ngxLoader.stop()
      }, err => {
        this.toastr.errorToastr(err.replyMsg)
      })
    }
    }
  }

  onSearch() {
    if (this.fromDate != '') {
      this.fromDate = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd');
    } else {
      this.fromDate = ''
    }
    if (this.toDate != '') {
      this.toDate = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd');
    } else {
      this.toDate = ''
    }
    if (this.fromBookingDate != '') {
      this.fromBookingDate = this.datePipe.transform(new Date(this.fromBookingDate), 'yyyy-MM-dd');
    } else {
      this.fromBookingDate = ''
    }
    if (this.toBookingDate != '') {
      this.toBookingDate = this.datePipe.transform(new Date(this.toBookingDate), 'yyyy-MM-dd');
    } else {
      this.toBookingDate = ''
    }


    // if (this.pnr != '') {
    //   this.pnr = this.pnr;
    // } else {
    //   this.pnr = ''
    // }

    // var request = {
    //   page_limit: '10',
    //   page_no: 1,
    //   sortorder: "desc",
    //   fromDate: this.fromDate,
    //   toDate: this.toDate
    // }

    this.request = {
      pax_search:this.paxData,
      fromBookingDate:this.fromBookingDate,
      toBookingDate:this.toBookingDate,
      fromDepDate:this.fromDate,
      toDepDate:this.toDate,
      soldFrom:'',
      depCity:this.depcity.toUpperCase(),
      arrCity:this.arrcity.toUpperCase(),
      paymentMode:this.paymentMode,
      inventoryOwner:Number(this.inventoryOwner),
      partnerId:Number(this.partnerId),
      websiteUserId:Number(this.websiteUserId),
      page_limit: (this.pagLimit).toString(),
      page_no: 1,
      sortorder: "desc",
      pnr:this.pnr.toUpperCase(),
      p_name:this.p_name,
      withPassenger:this.passengerfilter,
      bookingStatus:this.bookingStatus,
      airline_code:this.airline_code,
      // airline_id:Number(this.airline_id),
    }
    if (this.Type == 'sale') {
      console.log("-------onSearch==========");

      this.sharedService.post('v1/accounting/complete_sale_list', this.request).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.sale_data = res.data;
          this.TotalCount = res.count;
          this.keys = Object.keys(this.sale_data[0]);
        }
      }, err => {
      })
    } else if (this.Type == 'purchase') {
      this.sharedService.post('v1/accounting/complete_purchase_list', this.request).subscribe((res: any) => {
        if (res.replyCode == "success") {
          // this.Tdata = res.data;
          // this.keys = Object.keys(this.Tdata[0]);
          // this.TTotalCount = res.count;
          this.purchase_date = res.data;
          this.keys = Object.keys(this.purchase_date[0]);
          this.TotalCount = res.count;
        }
      }, err => {
        this.toastr.errorToastr(err.replyMsg)
      })
    }
  }



  filterwithpass(){
    if(this.passengerfilter == false ){
      this.passengerfilter=true;
      this.onSearch()
    }else{
      this.passengerfilter=false;
      this.onSearch()
    }
  }


  onSaleData(event) {
    if(this.searchText.length >= 3){
      if (this.fromDate != '') {
        this.fromDate = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd');
      } else {
        this.fromDate = ''
      }
      if (this.toDate != '') {
        this.toDate = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd');
      } else {
        this.toDate = ''
      }
      if (this.fromBookingDate != '') {
        this.fromBookingDate = this.datePipe.transform(new Date(this.fromBookingDate), 'yyyy-MM-dd');
      } else {
        this.fromBookingDate = ''
      }
      if (this.toBookingDate != '') {
        this.toBookingDate = this.datePipe.transform(new Date(this.toBookingDate), 'yyyy-MM-dd');
      } else {
        this.toBookingDate = ''
      }

    var request = {
      fromBookingDate:this.fromBookingDate,
      toBookingDate:this.toBookingDate,
      fromDepDate:this.fromDate,
      toDepDate:this.toDate,
      soldFrom:'',
      depCity:this.depcity.toUpperCase(),
      arrCity:this.arrcity.toUpperCase(),
      paymentMode:this.paymentMode,
      inventoryOwner:Number(this.inventoryOwner),
      partnerId:Number(this.partnerId),
      websiteUserId:Number(this.websiteUserId),
      page_limit: this.pagLimit,
      page_no: event,
      sortorder: "desc",
      withPassenger:true,
      p_name: this.p_name,
      pnr:this.pnr.toUpperCase(),
      bookingStatus:this.bookingStatus,
      pax_search:String(this.inputSearch.nativeElement.value)
    }
    if (this.Type == 'sale') {
      this.sharedService.post('v1/accounting/complete_sale_list', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.sale_data = res.data;
          this.TotalCount = res.count;
          this.keys = Object.keys(this.sale_data[0]);
        }
      }, err => {

      })
    }
    }
  }


  onPageChange(event) {
    if (this.fromDate != '') {
      this.fromDate = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd');
    } else {
      this.fromDate = ''
    }
    if (this.toDate != '') {
      this.toDate = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd');
    } else {
      this.toDate = ''
    }
    if (this.fromBookingDate != '') {
      this.fromBookingDate = this.datePipe.transform(new Date(this.fromBookingDate), 'yyyy-MM-dd');
    } else {
      this.fromBookingDate = ''
    }
    if (this.toBookingDate != '') {
      this.toBookingDate = this.datePipe.transform(new Date(this.toBookingDate), 'yyyy-MM-dd');
    } else {
      this.toBookingDate = ''
    }

    var request = {
      fromBookingDate:this.fromBookingDate,
      toBookingDate:this.toBookingDate,
      fromDepDate:this.fromDate,
      toDepDate:this.toDate,
      soldFrom:'',
      depCity:this.depcity.toUpperCase(),
      arrCity:this.arrcity.toUpperCase(),
      paymentMode:this.paymentMode,
      inventoryOwner:Number(this.inventoryOwner),
      partnerId:Number(this.partnerId),
      websiteUserId:Number(this.websiteUserId),
      page_limit: this.pagLimit,
      page_no: event,
      sortorder: "desc",
      withPassenger:this.passengerfilter,
      p_name: this.p_name,
      pnr:this.pnr.toUpperCase(),
      bookingStatus:this.bookingStatus,
      pax_search:this.paxData,
      airline_code:this.airline_code,
      // airline_id:Number(this.airline_id),
    }
    if (this.Type == 'sale') {
      this.sharedService.post('v1/accounting/complete_sale_list', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.sale_data = res.data;
          this.TotalCount = res.count;
          this.keys = Object.keys(this.sale_data[0]);
        }
      }, err => {

      })
    } else if (this.Type == 'purchase') {
      this.sharedService.post('v1/accounting/complete_purchase_list', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
          // this.Tdata = res.data;
          // this.keys = Object.keys(this.Tdata[0]);
          // this.TTotalCount = res.count;

          this.purchase_date = res.data;
          this.keys = Object.keys(this.purchase_date[0]);
          this.TotalCount = res.count;
        }
      }, err => {
        this.toastr.errorToastr(err.replyMsg)
      })
    }
  }

passengerFilterOption:boolean=false;
  download() {
    var request = {
      fromBookingDate:this.fromBookingDate,
      toBookingDate:this.toBookingDate,
      fromDepDate:this.fromDate,
      toDepDate:this.toDate,
      soldFrom:'',
      depCity:this.depcity.toUpperCase(),
      arrCity:this.arrcity.toUpperCase(),
      paymentMode:this.paymentMode,
      inventoryOwner:Number(this.inventoryOwner),
      partnerId:Number(this.partnerId),
      websiteUserId:Number(this.websiteUserId),
      page_limit: this.TotalCount,
      page_no: 1,
      sortorder: "desc",
      pnr:this.pnr.toUpperCase(),
      p_name:this.p_name,
      withPassenger:this.passengerfilter,
      bookingStatus:this.bookingStatus,
      // airline_id: this.airline_id
      airline_code:this.airline_code,
    }
    if (this.Type == 'sale') {
      this.sharedService.post('v1/accounting/complete_sale_list', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.Tedata = res.data;
          this.TTotalCount = res.count;
          this.keys = Object.keys(this.Tedata[0]);
        }
        setTimeout(() => {
          this.exportdata();
        }, 1000);
      }, err => {
      })
    } else if (this.Type == 'purchase') {
      this.sharedService.post('v1/accounting/complete_purchase_list', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.Tedata = res.data;
          this.keys = Object.keys(this.Tedata[0]);
          this.TTotalCount = res.count;
        }
        setTimeout(() => {
          this.exportdata();
        }, 1000);
      }, err => {
        this.toastr.errorToastr(err.replyMsg)
      })
    }
  }


  exportdata(): void {
    /* table id is passed over here */
    let element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element,{dateNF:'dd/MMM/yyyy;@',cellDates:true, raw: true});
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1',);
    /* save to file */
    XLSX.writeFile(wb, 'Excel-Report.xlsx');
  }

  searchreport(){
    if(this.fromDate != '' && this.toDate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.fromBookingDate != '' && this.toBookingDate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.depcity != '' && this.arrcity != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.pnr != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.p_name != ''){
      this.searchreportbool=true;
      this.onSearch()
    // }else if(this.reference_id != ''){
    //   this.searchreportbool=true;
    //   this.onSearch()
    }else{
      this.searchreportbool=true;
    }
  }



  reverseAndTimeStamp(dateString) {
    const reverse = new Date(dateString.split("-").reverse().join("-"));
    return reverse.getTime();
  }


  markaccounted() {
    var self = this;
    var requestdata = {
      accounted: true,
      sale_bill: this.Type == 'sale' ? true : false,
      accounted_bill_no: this.acc_bill_no,
      id: this.bill_id
    }
    this.sharedService.post('v1/bill/mark_accounted', requestdata).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          this.acc_bill_no = '';
          this.showList(this.Type, this.bodydata);
          $('#markaccounted').modal('hide')
          this.toastr.successToastr(res.replyMsg, 'Success')
        }
      }
    }
      , err => {
        this.toastr.errorToastr(err.error.replyMsg, "Error")
      })
  }
  // addBillModal(){
  //   $('#add_new_bill').modal('show')
  // }




  //   editBillModal(sendData){
  //     this.editBillData = sendData  ;
  //     this.editBill.patchValue({
  //       id:this.editBillData.id,
  //     status:this.editBillData.status,
  //     prefix: this.editBillData.prefix,
  //     method: this.editBillData.method,
  //     from_date: this.editBillData.from_date,
  //     to_date: this.editBillData.to_date,
  //     for_api: this.editBillData.for_api,
  //     for_website: this.editBillData.for_website,
  //     share_api: this.editBillData.share_api,
  //     share_website: this.editBillData.share_website,
  //     })
  //     $('#edit_Bill').modal('show')
  //   }
  //   edit_bill(){
  //     var self = this;
  //     self.submitted = true;
  //     var request = self.editBill.value;
  //     self.sharedService.post('v1/bill_settings/edit',request).subscribe((res:any)=>{
  //       if (!res.error) {
  //         if(res.replyCode == 'success'){
  //           self.submitted = false;
  //           self.showList(this.Type,this.bodydata);
  //           $('#edit_Bill').modal('hide')
  //            this.toastr.successToastr(res.replyMsg,'Success')
  //         }
  //       }
  //   },
  //    err =>{
  //     this.toastr.errorToastr(err.error.replyMsg,'Error')
  //   })

  // }
  // deleteModal(sendData){
  //   this.dataId = sendData;
  //  $('#delete').modal('show');
  // }
  // deleteBill(){
  //   var self = this;
  //   var request_data ={
  //     id:this.dataId
  //   };
  //   self.sharedService.post('v1/bill_settings/delete',request_data).subscribe((res:any)=>{
  //     if(res.replyCode == 'success'){
  //       self.showList(this.Type,this.bodydata);
  //       this.toastr.successToastr(res.replyMsg,'Success')
  //       $('#delete').modal('hide');
  //     }
  //   },err =>{
  //     this.toastr.errorToastr(err.replyMsg,'Error')
  //   })
  // }
  isUrlData:any;
  viewDetail(listData) {
    // console.log("listData========>>>>>>>>",listData);
    // console.log("environment.lg.isUrl=='ind'=========>>>>",environment.lg.isUrl=='ind');
    // return;
    this.booking_id = listData.id;
    sessionStorage.setItem("refe_id", JSON.stringify(listData.reference_id));
    sessionStorage.setItem("booking_id", JSON.stringify(this.booking_id));
    console.log("environment.lg.isUrl=='ita'========>>>>>>",environment.lg.isUrl);
    // return;
    if(environment.lg.isUrl !='ind'){
      const url = location.origin+'/booking-summary'
      const newWindow = window.open(url, '_blank');
      // newWindow.location.href = RedirectionPath.processUrl(url);
    }else{
      if(environment.lg.isUrl !='ind'){
        const url=location.origin+'/booking-summary-ita/'+listData.reference_id
        const newWindow = window.open(url, '_blank');
      }else{
        const urls=location.origin+'/booking-summary-ind/'+listData.reference_id
        const newWindow = window.open(urls, '_blank');
      }

      
      // newWindow.location.href = RedirectionPath.processUrl(url);
    }
  }

  // viewDetail(listData){
  //   this.booking_id = listData.id;
  //   sessionStorage.setItem("booking_id", JSON.stringify(this.booking_id));
  //   window.open(RedirectionPath.processUrl('/booking-summary'), '_blank');
  // }
  isPassengerList:any=[];isPassengerRecordFlag:boolean=false;
  onGetPassengerDetails(bookingId:any){
    console.log("bookingId>>>>>>",bookingId);
    this.isPassengerList=[];
    this.sharedService.post('v1/accounting/view_booking',{"user_id":this.isLoginUserDetails.id,"booking_id":bookingId}).subscribe((res) => {
      if (res.replyCode == 'success') {
        console.log("res>>>>",res);
        this.isPassengerList = res.passenger;
        if (res.passenger.length > 0) {
          $('#passengerModal').modal('show');
          this.isPassengerRecordFlag = true;
        } else {
          this.isPassengerRecordFlag = false;
        }
      } else {
        this.isPassengerRecordFlag = false;
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.isPassengerRecordFlag = false;
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }
  onClosePopUp(){
    $('#passengerModal').modal('hide');
  }
  onCancelbooking(bookingFlightId){
    this.sharedService.postBookingCancel('make_cancel_request',{"flight_booking_details_id":String(bookingFlightId)}).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Success');
        console.log("res>>>>",res);
        $('#passengerModal').modal('hide');
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }

  isBookingId:any;
  onCancelTicket(event:any){
    this.isBookingId = event.id;
    $('#cancelTicket').modal('show');
  }

  onCancelTicketSubmit(){
    this.submitted = true;
    this.CancelTicketForm.value.booking_id = this.isBookingId;
    if (this.CancelTicketForm.invalid) {
      return;
    } else {
        this.sharedService.post('v1/flight/cancel_ticket', this.CancelTicketForm.value).subscribe((res) => {
          if (res.error == 'success') {
            this.toastr.successToastr(res.message, 'Success');
            $("#cancelTicket").modal('hide');
            this.CancelTicketForm.reset();
            this.submitted = false;
          } else {
            this.toastr.errorToastr(res.message, 'Error');
          }
        });
    }
  }

  onCloseCancelTicketModal(){
    $('#cancelTicket').modal('hide');
    this.CancelTicketForm.reset();
  }


}

