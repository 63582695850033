import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from 'src/app/services';
declare var $: any;

@Component({
  selector: 'app-mail-list',
  templateUrl: './mail-list.component.html',
  styleUrls: ['./mail-list.component.scss']
})
export class MailListComponent implements OnInit {
 sendMail:FormGroup;
 submitted: boolean = false;
 selectedFile: File=null;
 imagesizeerror:boolean=false;
 uploadimage:any='';
 all_mail:any;
 isCheckedAgency:any=false;
 isCheckedClient:any=false;
 isCheckedCustom:any=false;
  userdata: any;
  bodydata: any;
  data: any;
  TotalCount: any;
  msg: string;
  flag: boolean;
  userDatarole: any;
  allCheckedRemoved: boolean = false;
  allCheckedRemoved_client:boolean = false;
  allChecked: boolean = false;
  allChecked_client:boolean = false;
  _unSelected: any = [];
  _unSelected_client:any = [];
  _selected: any = [];
  _selected_client:any= [];
  all: boolean = false;
  all_client:boolean = false;
  checkItem: boolean = false;
  checkItem_client:boolean = false;
  client_bodydata: { role_id: string; page_limit: number; page_no: any; search: string; order_key: string; order_value: string; };
  clientDatarole: any;
  clientdata: any;
  dataID: any = [];

  constructor(private fb: FormBuilder,  public sharedService: SharedserviceService,  private toastr: ToastrManager,) {
    this.all_mail=JSON.parse(sessionStorage.getItem("mail_list"));
    this.userdata = JSON.parse(localStorage.getItem("user"));
   }

  ngOnInit() {
    

    this.sendMail =  this.fb.group({
      from:[this.all_mail.email],
      to:[''],
      mail_to_client:[this.isCheckedClient],
      mail_to_agency:[this.isCheckedAgency],
      subject:['',Validators.required],
      image:['']
      }) 
  } 
  get send() { return this.sendMail.controls };

  onFileChanged1(event) {
    if( event.srcElement.files[0].size > 2000000){  this.imagesizeerror=true; }
    else{
        this.selectedFile = event.target.files[0];  this.imagesizeerror=false;
        const reader = new FileReader();
          if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              reader.readAsDataURL(file);  reader.onload = () => { this.uploadimage = reader.result as string; };
          }
    }
  }  
  send_Mail(){
    var imageFile ;
    var self = this;
    self.submitted = true; 
    if(self.selectedFile == null){
       imageFile = null
    }else{
      imageFile = self.selectedFile
    } 
    // if(self.isCheckedCustom = false){
    //   self.sendMail.value.to = '';
    //   console.log("Custom checked",self.isCheckedCustom );
    // }
    
    
   
   if(self.sendMail.invalid){
     return
   } 
    const request = new FormData();
    if (self.selectedFile == null) {
      request.append('image', '');
    } else {
      request.append('image', self.selectedFile, self.selectedFile.name);
    }
    request.append('mail_id',self.all_mail.id);
    request.append('mail_to',(self.isCheckedCustom == false) ? '' : self.sendMail.value.to);
    request.append('subject',self.sendMail.value.subject);
    request.append('mail_to_client',self.isCheckedClient);
    request.append('mail_to_agency',self.isCheckedAgency);
    request.append('type','image');
   
      self.sharedService.postRequest('v1/mail/send_mail', request).subscribe((res:any) => {
        if (res.code == 'success') {
          self.submitted = false;
         
          self.toastr.successToastr(res.data);
       
        }else{
          self.submitted = false;
         
          self.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, error => {
        // self.submitted = false;
      
       
        self.toastr.errorToastr(error.error.replyMsg);
        
      });
    
    
    
  }
  
  onChangeAgency($event:Event){
    this.isCheckedAgency=!this.isCheckedAgency

 }  

 closeAgency(){
  $('#agency_list').modal('hide');
  $('#client_list').modal('hide');
 
 }  
 onChangeClients($event:Event){
  this.isCheckedClient=!this.isCheckedClient

 }
 onChangeCustom($event:Event){
  this.isCheckedCustom=!this.isCheckedCustom;

  this.isCheckedAgency = false;
  this.isCheckedClient = false;
  
 }

 

}
