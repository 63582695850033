import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  public environment = environment;


  displayedColumns: string[] = ['position','companyname','duebills','qty','amount','lastbill','unbillamount'];
  dataSource = new MatTableDataSource<BillingPeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}

export interface BillingPeriodicElement {
  position: number;
  companyname: string;
  duebills: number;
  qty: number;
  amount:number;
  lastbill:number;
  unbillamount: number;

}
const ELEMENT_DATA: BillingPeriodicElement[] = [
  {position: 1, companyname:'Seller Agent Name', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 2, companyname:'Hiliam', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 3, companyname:'lithiam', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 4, companyname:'Baralian', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 5, companyname:'Boran', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 6, companyname:'Karban', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 7, companyname:'Nitrogen', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 8, companyname:'Oxygen', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 9, companyname:'Seller Agent Name', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 10, companyname:'Hydrogen', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 11, companyname:'Seller Agent Name', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},
  {position: 12, companyname:'Hydrogen', duebills:2000,qty:88,amount:3000, lastbill:2500, unbillamount:800},

];
