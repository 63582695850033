import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CommonService, SharedserviceService } from 'src/app/services';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DateAdapter } from '@angular/material';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss'],
  providers:[DatePipe]
})
export class BillComponent implements OnInit{
  public environment = environment;
  public dm = 'in';
  selectedFile: File = null;
  bodydata: any = {};
  addNewBill:FormGroup;
  public editBill : FormGroup;
  list_data : any ;
  Rev_list_data : any ;
  UnRev_list_data : any ;
  UnBill_list_data : any ;
  keys:any;
  forApi:boolean = false
  submitted:boolean=false;
  btnsubmitted:boolean=false;
  minDate: any;
  maxDate: any;
  date:any;
  dataId:any
  fromDate: any;
  editBillData:any
  Type:any;


  TotalCount: string;
  data: any;
  public page: number = 1;
  start = 0;
  msg: any;
  FormId: any;
  flag: boolean = false;
  userdata:any;
  userlist:any;

  constructor(
    public sharedService: SharedserviceService,
    private fb: FormBuilder,
    public router: Router,
    private toastr: ToastrManager,
    public datepipe: DatePipe,
    private ngxLoader: NgxUiLoaderService,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,
    public commonService: CommonService,
    public activatedRoute: ActivatedRoute)
    {
      this.userdata = JSON.parse(localStorage.getItem("user"));
    }

  ngOnInit(){
    this.Userlist();
    this.bodydata = {
      page_limit: 10,
      page_no: 1,
    }
    this.showList(this.bodydata);
    this.addNewBill= this.fb.group({
      amount:['',Validators.required],
      user_id:['',Validators.required],
      narration: ['',Validators.required],
      pdf :''
    })
    this.editBill = this.fb.group({
      id:['', Validators.required],
      status:[''],
      prefix: ['',Validators.required],
      method: ['',Validators.required],
      from_date: ['',Validators.required],
      to_date: ['',Validators.required],
      for_api: [false],
      for_website: [false],
      share_api: [false],
      share_website: [false]
    })
  }

  Userlist() {
    var bodydata = {
      role_id:"3",
      page_limit:10000,
      page:'',
      search: ""
    }
    this.sharedService.post('v1/users/users_list', bodydata).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.userlist = res.data.rows;
        if (res.data['rows'].length > 0) {
          this.data = res.data['rows'];
        } else {
          this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
        }
      } else {
        this.flag = true;
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; }
    return true;
  }

  addBillModal(){
    this.addNewBill.patchValue({
      user_id:this.userdata.id,
    })
    $('#add_new_bill').modal('show')
  }

  onPageChange(event) {
    this.bodydata['page'] = event;
    this.showList(this.bodydata);
  }

  showList(request){
      this.sharedService.post('v1/admin_bills/view_admin_bills',request).subscribe((res:any)=>{
        if(res.replyCode  == "success"){
            this.list_data = res.data;
            this.TotalCount = res.totalRecords;
            this.keys = Object.keys(this.list_data[0]);
        }
      },err =>{
        this.toastr.errorToastr(err.replyMsg)
      })
  }

  get bill (){return this.addNewBill.controls}
  get edit (){return this.editBill.controls}


  setDate(dateStart){
    this.fromDate = dateStart;
  }

  onFileChanged1(event) {
    this.selectedFile = event.target.files[0];
  }
  add_Bill(){
    var self = this;
    self.submitted = true;
    if (self.addNewBill.invalid) {
      return;
    } else {
      var sendData = self.addNewBill.value;
      const uploadData = new FormData();
      if (self.selectedFile == null) {
        uploadData.append('pdf', '');
      } else {
        uploadData.append('pdf', self.selectedFile, self.selectedFile.name);
      }
      uploadData.append('amount', sendData.amount);
      uploadData.append('user_id', sendData.user_id);
      uploadData.append('narration', sendData.narration);
      this.sharedService.postRequest('v1/admin_bills/admin_bills',uploadData).subscribe((res:any)=>{
        if (!res.error) {
          if(res.replyCode == 'success'){
            self.submitted = false;
            this.addNewBill.reset();
            this.showList(this.bodydata);
            $('#add_new_bill').modal('hide')
            this.toastr.successToastr(res.replyMsg,'Success')
          }
        }
      }
      ,err =>{
        this.toastr.errorToastr(err.error.replyMsg, "Error")
      })
    }


    }
    editBillModal(sendData){
      this.editBillData = sendData  ;
      this.editBill.patchValue({
        id:this.editBillData.id,
      status:this.editBillData.status,
      prefix: this.editBillData.prefix,
      method: this.editBillData.method,
      from_date: this.editBillData.from_date,
      to_date: this.editBillData.to_date,
      for_api: this.editBillData.for_api,
      for_website: this.editBillData.for_website,
      share_api: this.editBillData.share_api,
      share_website: this.editBillData.share_website,
      })
      $('#edit_Bill').modal('show')
    }
    edit_bill(){
      var self = this;
      self.submitted = true;
      var request = self.editBill.value;
      self.sharedService.post('v1/bill_settings/edit',request).subscribe((res:any)=>{
        if (!res.error) {
          if(res.replyCode == 'success'){
            self.submitted = false;
            self.showList(this.bodydata);
            $('#edit_Bill').modal('hide')
             this.toastr.successToastr(res.replyMsg,'Success')
          }
        }
    },
     err =>{
      this.toastr.errorToastr(err.error.replyMsg,'Error')
    })

  }
  deleteModal(sendData){
    this.dataId = sendData;
    // console.log("check Delete data ID",this.dataId);
   $('#delete').modal('show');
  }
  deleteBill(){
    var self = this;
    var request_data ={
      id:this.dataId
    };
    self.sharedService.post('v1/bill_settings/delete',request_data).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        self.showList(this.bodydata);
        this.toastr.successToastr(res.replyMsg,'Success')
        $('#delete').modal('hide');
      }
    },err =>{
      this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }
}







