import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from 'src/app/services';
declare var $;

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})


export class InformationComponent implements OnInit {

  infoData: any;
  msg: any;
  data: any;
  userdata: any;
  flag: boolean = false;
  submitted: boolean = false;
  public infoForm: FormGroup;
  public AddNewInformationForm: FormGroup;
  listData: any;
  OptionData: any;
  selectedid: any;

  constructor(
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public router: Router,
    private toastr: ToastrManager
  ) {
    this.userdata = JSON.parse(localStorage.getItem("user"));
    // this.loadinfodata();
  }
  //get f() { return this.infoForm.controls; }


  ngOnInit() {
    this.infoForm = this.fb.group({
      select_id: [''],
      option_id: [''],
      option_key: [''],
      option_value: ['', Validators.required]
    });

    this.AddNewInformationForm = this.fb.group({
      name: ['', Validators.required],
      airline_code: ['', Validators.required],
      info: ['', Validators.required],
    });

    this.loadinfodata();
  }
  get informationFormValidate() { return this.AddNewInformationForm.controls; }

  onDeleteSubmit() {

  }


  loadinfodata() {
    var option = {
      option_key: "special_info",
      flight_user_id: this.userdata.id
    }
    this.sharedService.post('v1/option/special_info', option).subscribe((res) => {
      if (res.replyCode == 'success') { 
        //var data=res.data.filter(t=>t.user_id == this.userdata.id)
        if (res.data) {
          this.infoData = res.data
          this.patchformdata(this.infoData)
        }
      } else {
        this.flag = true;
        this.toastr.errorToastr(res.replyMsg, 'Error')
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    })
  }

  patchformdata(data) {
    this.infoForm.patchValue({
      option_id: data.id,
      option_key: data.option_key,
      option_value: data.option_value
    })
  }

  infosubmit() {
    var self = this;
    let formdata = this.infoForm.value;

    var Object;
    // if (this.infoData) {

      // if (formdata.select_id == "") {
        Object = {
          select_id: formdata.select_id,
          option_id: formdata.option_id,
          // option_key: formdata.option_key,
          option_key: 'special_info',
          option_value: formdata.option_value
        }
        self.sharedService.post('v1/option/update', Object).subscribe((res: any) => {
          if (!res.error) {
            self.loadinfodata();
            self.toastr.successToastr(res.replyMsg, 'Success');
          } else {
            self.toastr.errorToastr(res.error.replyMsg, 'Error');
          }
        }, error => {
          self.toastr.errorToastr("Opps something went wrong!");
        });
      // } else {
      //   Object = {
      //     select_id: formdata.select_id,
      //     info: formdata.option_value
      //   }
      //   this.sharedService.post('v1/info/set', Object).subscribe((res) => {
      //     if (res.replyCode == 'success') {
      //       this.toastr.successToastr(res.replyMsg, 'Success');
      //       $("#addInfo").modal('hide');
      //       this.AddNewInformationForm.reset();
      //       // this.loadinfodata();
            this.loadinfodata();
      //     } else {
      //       this.toastr.errorToastr(res.replyMsg, 'Error')
      //     }
      //   }, err => {
      //     this.toastr.errorToastr(err['error']['message'], 'Error');
      //   })
      // }
    // }
    // else {
    //   Object = {
    //     option_key: "special_info",
    //     option_value: formdata.option_value
    //   }
    //   self.sharedService.post('v1/option/add', Object).subscribe((res: any) => {
    //     if (!res.error) {
    //       self.loadinfodata();
    //       self.toastr.successToastr(res.replyMsg, 'Success');
    //     } else {
    //       self.toastr.errorToastr(res.error.replyMsg, 'Error');
    //     }
    //   }, error => {
    //     self.toastr.errorToastr("Opps something went wrong!");
    //   });
    // }

  }

  getInfoList() {
    this.sharedService.get('v1/info/list').subscribe((res) => {
      if (res.replyCode == 'success') {
        if (res.data) {
          this.listData = res.data;
          this.OptionData = res.data;
          this.infoForm.controls['select_id'].setValue(this.OptionData[0].id);
          this.infoForm.controls['option_value'].setValue(this.OptionData[0].info);
        } else {
          this.toastr.errorToastr(res.replyMsg, 'Error');
        }
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }

  onSelectList(event) {
    this.selectedid = event;
    if (event == 0) {
      this.loadinfodata();
    } else if (event != "") {
      this.OptionData.map(item => {
        if (item.id == event) {
          this.listData = item;
        }
      });

      this.infoForm.patchValue({
        // option_id: data.id,
        // option_key: "special_info",
        option_value: this.listData.info
      });
    }
  }

  onAddNewInformationModal() {
    this.submitted = false;
    $("#addInfo").modal('show');
  }

  addInformation() {
    console.log(this.AddNewInformationForm,"-------------201*-*-*-");
    
    this.submitted = true;
    if (this.AddNewInformationForm.invalid) {
      return;
    } else {
      var informationData = {
        "name": this.AddNewInformationForm.value.name,
        "airline_code": this.AddNewInformationForm.value.airline_code,
        "info": this.AddNewInformationForm.value.info,
      }
      this.sharedService.post('v1/info/set', informationData).subscribe((res) => {
        if (res.replyCode == 'success') {
          this.toastr.successToastr(res.replyMsg, 'Success');
          $("#addInfo").modal('hide');
          this.AddNewInformationForm.reset();
          this.loadinfodata();

          this.submitted = false;
        } else {
          this.toastr.errorToastr(res.replyMsg, 'Error')
        }
      }, err => {
        this.toastr.errorToastr(err['error']['message'], 'Error');
      })
    }
  }

  deleteSelectedName() {
    var deleteData = {
      "id": this.selectedid
    }
    this.sharedService.post('v1/info/delete', deleteData).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.toastr.successToastr(res.replyMsg, 'Success');
        this.getInfoList();
        this.loadinfodata();
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error')
      }
    }, err => {
      this.toastr.errorToastr(err['error']['message'], 'Error');
    })
  }


}

