import { Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrManager } from 'ng6-toastr-notifications';

declare var $: any;
  @Component({
    selector: 'app-enquiry',
    templateUrl: './enquiry.component.html',
    styleUrls: ['./enquiry.component.scss'],
    providers: [NgxUiLoaderService,DatePipe]
  })
  export class EnquiryComponent implements OnInit {
    @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
    @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;

  enquirystatus:any;
  viewData: any;
  sortedAgent: any[];
  viewPmt: any;
  ccData: any;
  type: any;
  public createTicket: FormGroup;
  userdata: any;
  acceptApicheck: any;
  status: any;
  Data: any;
  StatusData: any;
  CommentData: any;
  UnaccpetedmyapiData: any;
  mydata: any;
  delApi: any;
  public apiRequestForm: FormGroup;
  public AddCommentForm: FormGroup;
  public formEmail: FormGroup;
  public Statusform: FormGroup;
  submitted: boolean = false;
  pgc: boolean = false;
  disableinput: boolean = false;
  searchFlagReset: boolean = false;
  bodydata: any = {}; TotalCount: string; data: any; keys: any; public page: number = 1; start = 0; msg: any; FormId: any; flag: boolean = false;
  acceptedApi: any;
  notAcceptedApi: any;
  venderprofile: any;
  bank_list_data: any;
  msgforbank: any;
  enquirydata:any={};
  Enquiry_id:any;
  Enquiry_status:boolean;
  enquiryList: any;
  radioButton:any;
  readioSelected:any;
  status_id: any;
  selectedEnquiry: any;
  // commenttype:any='0';


  constructor(
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    public sharedService: SharedserviceService,
    public router: Router,
    public commonService: CommonService,
    private toastr: ToastrManager
  ) {
    this.sortedAgent = this.ccData;
    this.userdata = JSON.parse(localStorage.getItem("user"));
  }
statuslisupdate_status
  ngOnInit() {
    this.bodydata = {
      page_limit: 10,
      page_no: 1,
      status: null,
    }

    this.ngxLoader.start();
    this.partnerapilist(this.bodydata);
    this.statuslist();
    this.ngxLoader.stop();

    this.formEmail = this.fb.group({
      name: ['',Validators.required],
      email: ['',Validators.required],
      // fcity: [''],
      // tcity: [''],
      // ddate: [''],
      // rdate: [''],
      // adult: [''],
      // childern: [''],
      // infant: [''],
      // flexible: [''],
      company:['',Validators.required],
      subject: ['',Validators.required],
      message: ['',Validators.required],
      mobile_no: ['',Validators.required],
      send_to_email:['']
    });
    this.AddCommentForm = this.fb.group({
      id: ['',Validators.required],
      type: ['',Validators.required],
      comment: ['',Validators.required],
    });
    this.Statusform = this.fb.group({
      id: ['',Validators.required],
    });
  }

  get enq() { return this.formEmail.controls; }
  get com() { return this.AddCommentForm.controls; }

  closeENQ() {
    $('#Enq_us').modal('hide');
  }
  ENQ() {
        $('#Enq_us').modal('show');
  }

  enquiryForm(){
    this.submitted = true;
    // var commentdata={
    //   "from city": this.formEmail.value.fcity,
    //   "to city": this.formEmail.value.tcity,
    //   "departure date": this.formEmail.value.ddate,
    //   "return date": this.formEmail.value.rdate,
    //   "traveller": "Adult = "+this.formEmail.value.adult?this.formEmail.value.adult:'1' +" Childern = "+this.formEmail.value.childern?this.formEmail.value.childern:'0'+ " Inafnt = "+this.formEmail.value.infant?this.formEmail.value.infant:'0',
    //   "flexible"  : this.formEmail.value.flexible?this.formEmail.value.flexible:'false'
    // }
    var requestData ={
      "user_id": this.userdata.id,
      "name":this.formEmail.value.name,
      "email":this.formEmail.value.email,
      "subject": this.formEmail.value.subject,
      "message": this.formEmail.value.message,
      "company_name": this.formEmail.value.company,
      "mobile": Number(this.formEmail.value.mobile_no),
      "status":1
      // "send_to_email":"ankit85@gmail.com"
    }
    this.sharedService.post('v1/enquiry/add',requestData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.partnerapilist(this.bodydata);
        $('#Enq_us').modal('hide');
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
  }

  partnerapilist(bodydata) {
    this.acceptedApi = 0;
    this.notAcceptedApi = 0;
    this.sharedService.post('v1/enquiry/list', bodydata).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.Data = res.data;
        this.TotalCount = res.count;
      }
    });
  }


  statuslist() {
    this.sharedService.get('v1/enquiry/list_status').subscribe((res) => {
      if (res.replyCode == 'success') {
        this.StatusData = res.data;
        (res.data).forEach(element => {
          element.checked= true
        });
      }
    });

  }
  updatestatus(data){
    // var findindex = this.StatusData.findIndex(e => e.name == data.status);
    this.selectedEnquiry = data.status_id;
    // this.readioSelected=this.StatusData[findindex].id;
    this.readioSelected= this.selectedEnquiry ;
    this.status_id = data.id;
    $('#getStatus').modal('show')
  }
  updateStatus(){
    var requestData = {
      id:this.status_id,
      status_id:Number(this.readioSelected)
    }
    this.sharedService.post('v1/enquiry/update_status',requestData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.partnerapilist(this.bodydata);
        $('#getStatus').modal('hide');
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });

  }

  // update_status(list){
  //   // this.enquirydata=list;
  //   // var findindex = this.StatusData.findIndex(e => e.name == list.status);
  //   // this.enquirystatus=this.StatusData[findindex].id;
  //   $('#getStatus').modal('show');
  // }

  // changeStatus(){
  //   var request={
  //     id:this.enquirydata.id ,
  //     // status: 2
  //     status_id: Number(this.enquirystatus)
  //   }
  //   this.sharedService.post('v1/enquiry/update_status',request).subscribe((res) => {
  //     if (res.replyCode == 'success') {
  //       this.partnerapilist(this.bodydata)
  //       $('#edit_s').modal('hide');
  //     }
  //   });

  // }

  // Commentlist(id,status) {

  //   this.Enquiry_id=id;
  //   this.Enquiry_status=status == 0?true:false;
  //   var request={
  //     id:id
  //   }
  //   this.sharedService.post('v1/enquiry/list_comment',request).subscribe((res) => {
  //     if (res.replyCode == 'success') {
  //       if(status ==  1){
  //         this.sharedService.post('v1/enquiry/mark_seen',{id:id}).subscribe((res) => {
  //           if (res.replyCode == 'success') {
  //             this.partnerapilist(this.bodydata);
  //           }
  //         });
  //       }
  //       this.CommentData = res.data;
  //       $('#listcomment').modal('show');
  //     }
  //   });
  // }
  commentlist(data){
    this.enquiryList = data ;
    if(sessionStorage.getItem('enquiry_list')){
        sessionStorage.removeItem('enquiry_list');

        sessionStorage.setItem("enquiry_list",JSON.stringify(this.enquiryList));
    }else{
    sessionStorage.setItem("enquiry_list",JSON.stringify(this.enquiryList));
    }
    this.router.navigateByUrl('/dashboard/comments');

  }

  // AddComment(){
  //   this.submitted = true;
  //   var requestData ={
  //     "id": this.AddCommentForm.value.id,
  //     "type": this.AddCommentForm.value.type,
  //     "comment":this.AddCommentForm.value.comment
  //   }
  //   this.sharedService.post('v1/enquiry/add_comment',requestData).subscribe((res: any) => {
  //     if (res.replyCode == "success") {
  //       this.Commentlist(this.Enquiry_id,this.Enquiry_status);
  //       $('#add_comment').modal('hide');
  //       this.toastr.successToastr(res.replyMsg, 'Success');
  //     } else {
  //       this.toastr.errorToastr(res.message, 'Error');
  //     }
  //   });
  // }

  Changetype(id){
    this.AddCommentForm.patchValue({
      type:id == 1?'note':'comment'
    })
  }

  CommentAdd(){
    this.AddCommentForm.patchValue({
      id:this.Enquiry_id
    })
    $('#add_comment').modal('show');
  }

  onSearch() {
    this.searchFlagReset = true;
    this.bodydata['search'] = String(this.inputSearch.nativeElement.value);
    this.partnerapilist(this.bodydata);
  }
  onReset() {
    this.inputSearch.nativeElement.value = '';
    this.searchFlagReset = false;
    this.bodydata['search'] = '';
    this.partnerapilist(this.bodydata);
  }

  onPageChange(event) {
    this.bodydata['page_no'] = event;
    this.partnerapilist(this.bodydata);
  }
}

