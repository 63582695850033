import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ManagedataService } from 'src/app/services/manage-data.service';

@Component({
  selector: 'app-watermark',
  templateUrl: './watermark.html',
  styleUrls: ['./watermark.scss'],
  providers: [DatePipe]
})
export class WatermarkComponent implements OnInit {
  
  isLoggedInUser:any={};
  constructor(private el:ElementRef,public manageService:ManagedataService) {
    
  }
  
  public ngOnInit(): void {
    this.manageService.GetLoggedInUserDetails().subscribe((response:any)=>{
      if(Object.keys(response).length>0){
        this.isLoggedInUser=response;
        console.log("isLoggedInUserisLoggedInUser",this.isLoggedInUser)
      }
    })
    const watermarkContainer = this.el.nativeElement.querySelector('.watermark');
    for (let i = 0; i < 400; i++) {
          const div = document.createElement('div');
          div.textContent = `${this.isLoggedInUser.initial || 0} ${this.isLoggedInUser.id}`;
          watermarkContainer.appendChild(div);
    }
  }
  
}
