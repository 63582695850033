import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ManagedataService } from './manage-data.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

var baseUrl = environment.vehicle_transportation_url ;

@Injectable({
  providedIn: 'root'
})

export class VehicleTransportService {
  public serviceBase: any;
  // public serviceBaseBookingCancel: any;
  public imageBase: any;
  public webBase: any;
  public imgUrl: any;
  public auth_token: any;
  public loadingShow: boolean = false;
  public progressLoadingShow: boolean = false;
  public progressLoadingValue: number = 0;
  user: any;
  user_type: any;
  adminPath:any;
    // Dev api key
    // xkey:any="1OR8ZP1635140429051";

    // Partner api key
    // xkey:any="1WJ5CK1637060833669";

    // weekendair api key
    xkey:any="1FMQKB1639407126571";
  constructor(private router: Router, private http: HttpClient, public manageDataService:ManagedataService) {
    // this.serviceBase = 'https://devapi.===';
    // this.serviceBase = 'https://demoapi.===';
    // this.serviceBase = 'https://devapi.fareboutique.com:3005/';

    // Important Note ====== chnage admin path url before build code.
    // this.adminPath = '/trasnglobal/admin'
    /*****************************/
    this.adminPath = ''
    /*****************************/
    // this.serviceBase = 'https://devapi.fareboutique.com:3005/';
    this.serviceBase = baseUrl;
    // this.serviceBaseBookingCancel = baseUrl_book_cancel;

    this.imageBase = '';
    var userdata: any = JSON.parse(localStorage.getItem("user"));
    this.user = JSON.parse(localStorage.getItem("user"));
    // this.auth_token = JSON.parse(localStorage.getItem("token"));
    this.manageDataService.GetUserToken().subscribe((data:any)=>{
      if(data != ''){
        this.auth_token=data;
      }
    })
  }

  gettoken(url: any): Observable<any> {
    var headers: HttpHeaders;
    if (this.auth_token != undefined){
      headers = new HttpHeaders({ 'Authorization': "Bearer " +this.auth_token, 'Content-Type': 'application/json','x-api-key' : this.xkey });
    }else{
      headers = new HttpHeaders({ 'Content-Type': 'application/json','x-api-key' : this.xkey });
    }

    return this.http.get('https://devpatnerapi.fareboutique.com:4005/v1/live_api/get_token', {
      headers: headers
    });
  }

  get(url: any): Observable<any> {
    var headers: HttpHeaders;
    if (this.auth_token != undefined)
      headers = new HttpHeaders({ 'Authorization': "Bearer " + this.auth_token, 'Content-Type': 'application/json' });
    return this.http.get(this.serviceBase + url, {
      headers: headers
    });
  }

  post(url: any, data: any): Observable<any> {
    if (this.auth_token) {
      let headers = new HttpHeaders({ 'Authorization': "Bearer " + this.auth_token, 'Content-Type': 'application/json' });
      return this.http.post(this.serviceBase + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post(this.serviceBase + url, data, {
        headers: headers
      });
    }
  }

  patch(url: any, data: any): Observable<any> {
    if (this.auth_token) {
      let headers = new HttpHeaders({ 'Authorization': "Bearer " + this.auth_token, 'Content-Type': 'application/json' });
      return this.http.patch(this.serviceBase + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.patch(this.serviceBase + url, data, {
        headers: headers
      });
    }
  }
  put(url: any, data: any): Observable<any> {
    if (this.auth_token) {
      let headers = new HttpHeaders({ 'Authorization': "Bearer " + this.auth_token, 'Content-Type': 'application/json' });
      return this.http.put(this.serviceBase + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.put(this.serviceBase + url, data, {
        headers: headers
      });
    }
  }

  goTo(page: any) {
    this.router.navigate([page]);
  }

  downloadCSV(data: any, fileName: any) {
    var csvData = this.ConvertToCSV(data);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }

  ConvertToCSV(objArray: any) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
      row += index + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  getRequestdata(url: any): Observable<any> {
    let headers = new HttpHeaders({ 'Authorization': "Bearer " + this.auth_token });
    headers.append('Content-Type', 'application/json');
    headers.append('language', 'en');
    return this.http.get(this.serviceBase + url, {
      headers: headers,
    });
  }
  getRequest(url: any): Observable<any> {
    if (this.auth_token) {
      let headers = new HttpHeaders({ 'Authorization': "Bearer " + this.auth_token });
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      headers.append('id', '34');
      return this.http.get(this.serviceBase + url, {
        headers: headers,
      });
    } else {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      headers.append('id', '34');
      return this.http.get(this.serviceBase + url, {
        headers: headers,
      });
    }
  }
  postRequest(url, data) {
    if (this.auth_token) {
      let headers = new HttpHeaders({ 'Authorization': "Bearer " + this.auth_token });
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      return this.http.post(this.serviceBase + url, data, { headers: headers });
    } else {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      return this.http.post(this.serviceBase + url, data, { headers: headers });
    }

  }
  getRequestnotoken(url: any): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    headers.append('id', '34');
    return this.http.get(this.serviceBase + url, {
      headers: headers,
    });
  }
  postRequestnotoken(url: any, data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(this.serviceBase + url, data, { headers: headers });
  }



}
