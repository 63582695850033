import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from 'src/app/services';
import { CommonService } from 'src/app/services/commonservice.service'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { environment } from 'src/environments/environment';
declare var $:any;

@Component({
  selector: 'app-myapi-account',
  templateUrl: './myapi-account.component.html',
  styleUrls: ['./myapi-account.component.scss'],
  providers:[NgxUiLoaderService,DatePipe,Ng2SearchPipeModule]

})
export class MyapiAccountComponent implements OnInit {
  public environment = environment;
  @ViewChild("fileInput1", {static: false}) fileInput1: ElementRef;files = [];
  @ViewChild("inputSearch", {static: false}) inputSearch: ElementRef;
  searchText:any;
  sort_company_name:any="desc";
  // todaydate:any= this.datepipe.transform(new Date(), 'dd/MM/yyyy')
   todaydate:any = new Date();
  bodydata:any={ };
  TotalCount:string;data:any;keys:any;public page: number = 1;start = 0;msg:any;status:any;FormId:any;flag:boolean=false;
  searchFlagReset:boolean=false;
  sortedList: any[];
  accountData:any;
  ccData:any;
  date:any;
  data2:any;
  isAuto:boolean=false;
  old_credit_limit:any;
  public addaccountValue : FormGroup;
  public addaccountPartner : FormGroup;
  public addaccountPartner2 : FormGroup;
  public marginListID: FormGroup;
  submitted: boolean = false;
  userdata:any;
  creditVal:any=3;
  old_available_balance:any;detail_data:any;
  currentdate : any;
  last_Select_Date :any;
  diffTime:any='';
  crediBalance:any='';
  select_date: any;
  mySelect: any=3;
  add_b_sel_op:any=[
    { title: "Transaction Type", value: 3 },
    { title: "Give Credit", value: 0 },
    { title: "Add Balance", value: 1 },
  ]
  onSelectDate: any;


  constructor(
    public datepipe: DatePipe,
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public dataservice:CommonService,
    public router: Router,
    private toastr: ToastrManager,
    private activatedRoute:ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
  ) {
    this.userdata=JSON.parse(localStorage.getItem("user"));
    this.sortedList=this.ccData;
    this.date=new Date();
  }


    ngOnInit() {
      this.bodydata={
        page_limit:10,
        page_no:this.bodydata['page_no'],
        search_value:"",
        account_type:"",
        order_key:"",
        order_value:"",
      }
      this.bodydata['page_no']='1';
      this.accountList(this.bodydata);
      if(this.creditVal == 0){
        var accnt_type = 'credit'
      }else{
        var accnt_type = 'balance'
      }
      if(this.userdata.role_id == 1 ){
        this.addaccountPartner = this.fb.group({
          share_inventorie_id:['', Validators.required],
          share_to_user_id:['', Validators.required],
          amount:['', Validators.required],
          narration:['', Validators.required],
          // crediBalance:['', Validators.required]
        })
        this.addaccountPartner2 = this.fb.group({
          user_id:['', Validators.required],
          tbo_margin:['', Validators.required],
        })
        this.marginListID = this.fb.group({
          user_id:['', Validators.required],
        })
       }
      else{
      // else if(this.userdata.role_id == 3 || this.userdata.role_id == 5){
      this.addaccountPartner = this.fb.group({
        share_inventorie_id:['', Validators.required],
        share_to_user_id:['', Validators.required],
        amount:['', Validators.required],
        narration:['', Validators.required],
        account_type:[accnt_type],
        credit_reversal_date:[this.select_date],
        is_auto_reverse:['']
        // crediBalance:['', Validators.required]
      })
     }
  }
    applyFilter(event: Event) {

    }

sortfun(orderkey,type){
  if(orderkey == 'company_name' && type == 'asc'){this.sort_company_name='asc'; }else{this.sort_company_name='desc';}
  this.searchFlagReset = true;
  var DataSearch={
    page_limit:10,
    page_no:"",
    search_value:"",
    account_type:"",
    order_key:orderkey,
    order_value:type,
  }
  this.accountList(DataSearch)
}

// numberOnly(event): boolean {
//     const charCode = (event.which) ? event.which : event.keyCode;
//     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
//       return false;
//     }
//     return true;
// }

  get ac(){return this.addaccountValue.controls;}
  get pc(){return this.addaccountPartner.controls;}

  accountdetail(list){
    if(this.userdata.role_id == 1){
      var data2={
        share_inventorie_id:list.share_inventorie_id,
        share_to_user_id:list.share_to_user_id,
        share_by_user_id:list.share_by_user_id,
        amount:list.credit_limit
      }
      sessionStorage.setItem("account_detail_data",JSON.stringify(data2))
      this.router.navigateByUrl("myapi/balance");
    }
     else if (this.userdata.role_id == 3 || this.userdata.role_id == 5){
      var data2={
        share_inventorie_id:list.share_inventorie_id,
        share_to_user_id:list.share_to_user_id,
        share_by_user_id:list.share_by_user_id,
        amount:list.credit_limit
      }
      sessionStorage.setItem("account_detail_data",JSON.stringify(data2))
      this.router.navigateByUrl("myapi/balance");
    }
  }

  accountList(bodydata){
    this.ngxLoader.start();
    if(this.userdata.role_id == 1){
      this.sharedService.post('v1/account/listing',bodydata).subscribe((res)=> {
        if(res.replyCode == 'success'){
          this.ccData = res.data;
          if(res.data.length > 0){
            this.flag = false;
            this.detail_data = res.company_details;
            this.data = res.data;
            this.keys = Object.keys(this.data[0]);
            this.TotalCount = res.totalRecords;

            this.ngxLoader.stop();
          }else{
            this.flag = true;
            this.msg ='Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            this.ngxLoader.stop();
          }
          }else{
            this.flag = true;
            this.msg ='Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            this.ngxLoader.stop();
            this.toastr.errorToastr(res.replyMsg, 'Error');
          }
          },err=>{
            this.flag = true;
            this.msg ='Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            this.ngxLoader.stop();
            this.toastr.errorToastr(err.error.replyMsg, 'Error');
      });
    }
    else if(this.userdata.role_id == 3 || this.userdata.role_id == 5){
      this.ngxLoader.start();
      this.sharedService.post('v1/account/listing',bodydata).subscribe((res)=> {
        if(res.replyCode == 'success'){
          this.ccData = res.data;
          if(res.data.length > 0){
            this.flag = false;
            this.detail_data = res.company_details;
            this.data = res.data;
            this.keys = Object.keys(this.data[0]);
            this.TotalCount = res.totalRecords;
            this.ngxLoader.stop();
          }else{
            this.flag = true;
            this.msg ='Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            this.ngxLoader.stop();
          }
          }else{
            this.flag = true;
            this.msg ='Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            this.ngxLoader.stop();
            this.toastr.errorToastr(res.replyMsg, 'Error');
          }
          },err=>{
            this.flag = true;
            this.msg ='Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            this.ngxLoader.stop();
            this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
    }
  }

  // onOptionsSelected(value){
  // }

  myFunctionDate(val){
    this.select_date == val;
    var newdate=new Date();
    // console.log("check Date 1 is", this.last_Select_Date);
    // console.log("check Date 2 is", this.currentdate);
    var diff = val.getTime() - newdate.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    // return hours+" hrs "+": "+minutes+" min";
    this.diffTime= days+" Days, "+ hours+" Hours,"+minutes+" Minute, "+seconds+" Seconds";
    // console.log("new date:::::", newdate)

    this.onSelectDate = val ;
     this.onSelectDate.toDateString() ;
    // console.log("on Date select :::::",this.onSelectDate);
    // console.log("select date::::",this.select_date )

  }

  showMargin(data){
    this.FormId = data;
    if(this.userdata.role_id == 1){
    this.addaccountPartner2.patchValue({
      user_id:this.FormId,
    })
    this.marginListID.patchValue({
       user_id:this.FormId
    })
  }
    $('#addMargin').modal('show');
  }
  accountShow(data){
    this.FormId = data;
    this.old_credit_limit=data.credit_limit;
    this.old_available_balance=data.available_balance;

    if(this.userdata.role_id == 1){
      this.addaccountPartner.patchValue({
        share_inventorie_id:data.share_inventorie_id,
        share_to_user_id:data.share_to_user_id,
        share_by_user_id:data.share_by_user_id,
     })
    } else if(this.userdata.role_id == 3 || this.userdata.role_id == 5){
      this.addaccountPartner.patchValue({
        share_inventorie_id:data.share_inventorie_id,
        share_to_user_id:data.share_to_user_id,
        share_by_user_id:data.share_by_user_id,
      })
    }

    $('#addBalance').modal('show');

  }

  isAutoReverse(event){
    var myvalisAuto = event;
    // this.isAuto = ! this.isAuto;
    if (event.checked == true) {
      this.isAuto = true;
      // localStorage.setItem('ContactShow','true')
    } else {
      this.isAuto = false;
      // localStorage.setItem('ContactShow','false')
    }
  }

  addAccount(){
    var self = this;
    self.submitted = true;
    // if(this.userdata.role_id == 1){
    // console.log("---->" ,this.addaccountPartner);
      if (self.addaccountPartner.invalid) {
        return;
      } else {
          if(this.submitted = true && this.crediBalance == '' && this.creditVal == 3){
            return;
          }else{
            var data= self.addaccountPartner.value;
            var request_data:any={
              share_inventorie_id: data.share_inventorie_id,
              share_to_user_id: data.share_to_user_id,
              amount:data.amount ,
              narration: data.narration,
              account_type: this.creditVal == 0?'credit':'balance',  // 'balance': to add balance, 'credit': to add credit
              credit_reversal_date:this.crediBalance ==''?'':this.datepipe.transform(this.crediBalance, 'yyyy-MM-dd'),   // if is_auto_reverse is false, credit reversal date is not mandatory
              is_auto_reverse:this.isAuto
            }
           self.sharedService.postRequest('v1/account/add_balance',request_data).subscribe((res: any) => {
             if (!res.error) {
               if(res.replyCode == 'success') {
                this.diffTime=''
                this.creditVal=3;
                this.crediBalance ='';
                 this.accountData = res.data;
                 self.submitted = false;
                 this.accountList(this.bodydata);
                 $('#addBalance').modal('hide');
                 self.addaccountPartner.reset();
                 self.submitted = false;
                 self.toastr.successToastr(res.replyMsg, 'Success');
               }else {
                 self.submitted = false; self.addaccountPartner.reset();
                 self.toastr.errorToastr(res.replyMsg, 'Error');
               }
             }else {
               self.submitted =false; self.addaccountValue.reset();
               self.toastr.errorToastr(res.error.replyMsg, 'Error');
             }
             },error => {
               self.submitted =false; $('#addBalance').modal('hide');
               self.addaccountPartner.reset(); self.toastr.errorToastr(error.error.replyMsg);
             });
          }
      }
    //  }
    //   else if(this.userdata.role_id == 3 || this.userdata.role_id == 5){
    //     if (self.addaccountPartner.invalid) {
    //       return;
    //     } else {
    //       if(this.submitted = true && this.crediBalance == '' && this.creditVal == 3){
    //         return;
    //       }else{
    //         var data= self.addaccountPartner.value;
    //         var request_data:any={
    //           share_inventorie_id: data.share_inventorie_id,
    //           share_to_user_id: data.share_to_user_id,
    //           amount:data.amount ,
    //           narration: data.narration,
    //           account_type: this.creditVal == 0?'credit':'balance',  // 'balance': to add balance, 'credit': to add credit
    //           credit_reversal_date:this.crediBalance==''?'':this.datepipe.transform(this.crediBalance, 'yyyy-MM-dd')   // cannot be null if account_type = 'credit'
    //           }
    //         self.sharedService.postRequest('v1/account/add_balance',request_data).subscribe((res: any) => {
    //           if (!res.error) {
    //             if(res.replyCode == 'success') {
    //               this.diffTime='';
    //               this.creditVal=3;
    //               this.crediBalance ='';
    //               this.accountData = res.data;
    //               self.submitted = false;
    //               this.accountList(this.bodydata);
    //               $('#addBalance').modal('hide');
    //               self.addaccountPartner.reset();
    //               self.submitted = false;
    //               self.toastr.successToastr(res.replyMsg, 'Success');
    //             }else {
    //               self.submitted = false;
    //               self.addaccountPartner.reset();
    //               self.toastr.errorToastr(res.replyMsg, 'Error');
    //             }
    //           }else {
    //             self.submitted =false;
    //             self.addaccountValue.reset();
    //             self.toastr.errorToastr(res.error.replyMsg, 'Error');
    //           }
    //           },error => {
    //             self.submitted =false;
    //             $('#addBalance').modal('hide');
    //             self.addaccountPartner.reset();
    //             self.toastr.errorToastr(error.error.replyMsg);
    //           });
    //       }
    //   }
    // }

  }
  addMarginData(){
    this.submitted = true;
     if(this.userdata.role_id == 1){
    // if (this.addaccountPartner.invalid) {
    //   return;
    // } else {
     var sendRequest = this.addaccountPartner2.value;
    this.sharedService.postRequest('v1/users/update_tbo_margin',sendRequest).subscribe((res:any) => {
      if (!res.error){
      if(res.replyCode == "success"){
        this.accountData = res.data;  this.submitted = false
        this.addaccountPartner2.reset();  this.submitted = false;
        this.toastr.successToastr(res.replyMsg, 'Success');
        $('#addMargin').modal('hide');  this.accountList(this.bodydata)
      }else {
        this.submitted = false;  this.addaccountPartner.reset();  this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }   else {
        this.submitted =false;  this.addaccountValue.reset();  this.toastr.errorToastr(res.error.replyMsg, 'Error');
      }
    }, error => {
      this.submitted =false;  $('#addMargin').modal('hide');
      this.addaccountPartner.reset();  this.toastr.errorToastr(error.error.replyMsg);
    });
   }
  }

  marginList(){
    this.submitted = true;
    if(this.userdata.role_id == 1){
      var marginRequest = this.marginListID.value;
      this.sharedService.postRequest('v1/users/get_tbo_margin',marginRequest).subscribe( (res:any) => {
        if (!res.error){  if(res.replyCode == "success"){  this.accountData = res.data;  }  }
    },error => {  });
  }
}
  closeMargin(){  $('#addMargin').modal('hide');  }


  clBalance(){  $('#addBalance').modal('hide');  this.submitted = false;  }

  saerch(e:any){  if(e.code == "Enter" || e.code == "NumpadEnter"){  this.onSearch();  }  }

  onSearch(){
    this.searchFlagReset = true;
    var bodydata={
        page_limit:"",  page_no:"",  account_type:"",  search_value:String(this.inputSearch.nativeElement.value),
        order_key:"",  order_value:"",
      }
    this.accountList(bodydata);

  }

  onReset(){  this.inputSearch.nativeElement.value='';  this.searchFlagReset = false;  this.bodydata['search'] = '';  this.accountList(this.bodydata);  }

  onPageChange(event) {  this.bodydata['page_no'] = event;  this.accountList(this.bodydata);  }

}
