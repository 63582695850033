import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-dashmaster',
  templateUrl: './dashmaster.component.html',
  styleUrls: ['./dashmaster.component.scss'],
  host: {"(window:resize)": "onWindowResize($event)"},
  providers:[DatePipe]
})
export class DashmasterComponent implements OnInit {
  public environment = environment;
  public dm = 'in';
  show: boolean = false;
  submitted = false;
  resetForm:FormGroup;
  b2c_login:boolean;  book_outside:boolean;  show_outside:boolean;  scroll_msg_inside:string;  scroll_msg_outside:string;
  searchtext:any; Enable_set_var:any=false; WebSite_set_data:any;
  isSticky: boolean = false;
  sidenav: any;
  isMobile: boolean = false;
  width: number = window.innerWidth;
  mobileWidth: number = 500;isStaffLogin:any;
  public innerWidth: any;
  title = 'fareboutique';
  userdata: any;
  // New Keyword
  isLoggedInUser:any={};
  isLoggedInUserImage:any="";
  isUserImageUrl:any="";
  isHotelFlag:any=false;
  isDashboard:any=true;

  constructor(private router: Router,private toastr: ToastrManager, public cds: CommonService, public sharedService: SharedserviceService,private fb: FormBuilder,private datePipe: DatePipe,public manageService:ManagedataService) {
    if(localStorage.getItem('hotel') != undefined && localStorage.getItem('hotel') != null && localStorage.getItem('hotel') != "" ){
      this.isHotelFlag=localStorage.getItem('hotel');
    }
    this.userdata = JSON.parse(localStorage.getItem("user"));
    // if(this.router.url == '/dashboard/admin-home'){
    //   this.isDashboard = false;
    // }else{
      this.isDashboard = true;
    // }
    // Inventory List Api Start Here
    // var dep_city_list:any=[];var arr_city_list:any=[];
    // var resultdate = [];
    // for (var i = 0; i < 7; i++) {
    //   var d = new Date();
    //   d.setDate(d.getDate() + i);
    //   resultdate.push(this.datePipe.transform(new Date(d), 'yyyy-MM-dd'));
    // }
    // var idData = { show_all: false }
    // this.sharedService.post('v1/flight/inventory', idData).subscribe((res) => {
    //   // console.log("Sdfsdf",JSON.stringify(res));
    //   // var filterData=res.data.filter((x=>x.date ))
    //   if (res.replyCode == "success") {
    //     var calenderlistdata = [];
    //     const key = 'id';
    //     var arrayUniqueByKey:any = [...new Map((res.data).map(item => [item[key], item])).values()];
    //     if (res.ids.length > 0) {
    //       res.ids.forEach(ele => {
    //         var inv_data = arrayUniqueByKey.filter(x => x.flight_id == ele.id);
    //         if(inv_data.length !=0){
    //           if (dep_city_list.find((x) => x == inv_data[0].dep_city_code) != true) {
    //             dep_city_list.push(inv_data[0].dep_city_code);
    //           }
    //           if (arr_city_list.find((x) => x == inv_data[0].arr_city_code) != true) {
    //             arr_city_list.push(inv_data[0].arr_city_code);
    //           }
    //         }
    //         inv_data.sort(function (a, b) { return new Date(a.date).getTime() - new Date(b.date).getTime()})

    //         if (inv_data.length > 0) {
    //           var element = {
    //             "flight_id": inv_data[0].flight_id,
    //             "dep_city_code": inv_data[0].dep_city_code,
    //             "arr_city_code": inv_data[0].arr_city_code,
    //             "departure_date": inv_data[0].date,
    //             "date": inv_data.date,
    //             "arrival_date": inv_data[inv_data.length - 1].date,
    //             "name": inv_data[0].name,
    //             "supplier_user_name": inv_data[0].supplier_user_name,
    //             "return_flight": inv_data[0].return_flight,
    //             "status": inv_data[0].status,
    //             "flight_pnrs": inv_data,
    //             "id": "",
    //             "sevenlist": [],
    //             "mindate": "",
    //             "maxdate": "",
    //             "disablecal": false,
    //             "event": []
    //           }
    //           calenderlistdata.push(element);
    //           calenderlistdata = calenderlistdata.sort((a, b) => (a.name < b.name) ? -1 : 1)

    //           var cal_event = element;
    //           var calender_list = cal_event.flight_pnrs;
    //           var events = [];
    //           ////////////////////////////////////
    //           var sevendaydata = cal_event.flight_pnrs;
    //           let s_day = [];
    //           resultdate.forEach(elementDate => {
    //             var key = elementDate;
    //             let svnDaat = sevendaydata.find(el => el.date === key);
    //             console
    //             if (svnDaat == undefined) {
    //               s_day.push({ seat: 'N/A' });
    //             } else {
    //               s_day.push({
    //                 seat: svnDaat.available_seats,
    //                 blockseat: svnDaat.block_seat,
    //                 date: svnDaat.date,
    //                 pnr: svnDaat.pnr_number,
    //                 price: svnDaat.price,

    //               });
    //             }
    //           });
    //           element.sevenlist = s_day;
    //           var startdate: any = element.departure_date; var enddate: any = element.arrival_date;
    //           var currentdate: any = new Date();
    //           if (new Date(startdate) > currentdate) {
    //             element.mindate = startdate;
    //             element.maxdate = enddate; element.disablecal = false;
    //           } else {
    //             if ((new Date(startdate) < currentdate) && (new Date(enddate) < currentdate)) {
    //               element.mindate = startdate; element.maxdate = enddate;
    //               element.disablecal = true;
    //             } else {
    //               element.mindate = this.transformDate(currentdate)
    //               element.maxdate = enddate; element.disablecal = false;
    //             }
    //           }
    //           ///////////////////////////////////
    //           calender_list.forEach(element => {
    //             if (element.block_seat != 0 && element.block_seat != null) {
    //               events.push(
    //                 { id: element.date, title: '' + element.available_seats, start: element.date, end: element.date, name: element.date, editable: false },
    //                 { id: element.date, title: 'PNR: ' + element.pnr_number, start: element.date, end: element.date, name: element.date, className: 'myOption', editable: false },
    //                 { id: element.date, title: 'Rs.: ' + element.price, start: element.date, end: element.date, name: element.date, className: 'myOptionDown', editable: false },
    //                 {
    //                   id: element.date, title: '' + element.block_seat, start: element.date, end: element.date,
    //                   name: element.date, className: 'box-size', editable: false
    //                 }
    //               )
    //             }
    //             else if (element.available_seats == 0) {
    //               events.push(
    //                 {
    //                   id: element.date, title: ' ' + element.available_seats, start: element.date, end: element.date,
    //                   name: element.date, className: 'fc-day-grid-event1 ', editable: false
    //                 },
    //                 { id: element.date, title: 'PNR: ' + element.pnr_number, start: element.date, end: element.date, name: element.date, className: 'myOption', editable: false },
    //                 { id: element.date, title: 'Rs.: ' + element.price, start: element.date, end: element.date, name: element.date, className: 'myOptionDown', editable: false },
    //               )
    //             }
    //             else {
    //               events.push({ id: element.date, title: element.available_seats, start: element.date, end: element.date, name: element.date, },
    //                 { id: element.date, title: 'PNR: ' + element.pnr_number, start: element.date, end: element.date, name: element.date, className: 'myOption', editable: false },
    //                 { id: element.date, title: 'Rs.: ' + element.price, start: element.date, end: element.date, name: element.date, className: 'myOptionDown', editable: false },
    //               )
    //             }
    //             var startdate: any = cal_event.departure_date; var enddate: any = cal_event.arrival_date; var currentdate: any = new Date();
    //             if (new Date(startdate) > currentdate) { cal_event.mindate = startdate; cal_event.maxdate = enddate; cal_event.disablecal = false; }
    //             else {
    //               if (new Date(enddate) > currentdate) { cal_event.mindate = this.transformDate(currentdate); cal_event.maxdate = enddate; cal_event.disablecal = false; }
    //               else { cal_event.mindate = startdate; cal_event.maxdate = enddate; cal_event.disablecal = true; }
    //             }
    //           });
    //           cal_event.event = events;
    //         }
    //       });
    //       calenderlistdata = calenderlistdata.sort((a, b) => (a.name < b.name) ? -1 : 1);
    //       var calenderlistdata1=calenderlistdata;
    //       this.manageService.SetInventoryCalenderData({
    //         calenderlistdata:calenderlistdata,
    //         calenderlistdata1:calenderlistdata1,
    //         dep_city_list:dep_city_list,
    //         arr_city_list:arr_city_list,
    //       })
    //       console.log("calenderlistdata>>>>>",calenderlistdata)
    //       console.log("calenderlistdata1>>>>>",calenderlistdata1)
    //     }
    //   }
    // })
    // Inventory List End
    this.manageService.GetLoggedInUserDetails().subscribe((response:any)=>{
      if(Object.keys(response).length>0){
        this.isLoggedInUser=response;
      }
    })
    this.manageService.GetLoggedInUserImage().subscribe((response:any)=>{
      if(response != ""){
        this.isLoggedInUserImage=response;
      }
    })
    this.manageService.GetLoggedInUserImageUrl().subscribe((response:any)=>{
      if(response != ""){
        this.isUserImageUrl=response;
      }
    })
  }

  transformDate(date) { return this.datePipe.transform(date, 'yyyy-MM-dd'); }
  ngOnInit():void {
    this.manageService.GetLoggedInUserDetails().subscribe((data:any)=>{ this.isStaffLogin=data;})

    this.innerWidth = window.innerWidth;
    if(this.innerWidth < 769){
      this.isExpanded = false;
    }
    else{
      this.isExpanded = true
    }
    this.isMobile = this.width < this.mobileWidth;
    window.addEventListener('scroll', this.scroll, true);
    this.resetForm = this.fb.group({
      old_password:['', Validators.required],
      new_password:['', [Validators.required, Validators.minLength(6)]]
    });

  }
  get f() { return this.resetForm.controls; }
  clearstorage(){

    this.router.navigate(['/dashboard'])
    // this.router.navigate(['/dashboard/admin-home'])
    // .then(() => {
    //   window.location.reload();
    // });
  }
  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.isMobile = this.width < this.mobileWidth;
    if (this.isMobile = this.width < this.mobileWidth) {
      this.isMobile = true;
      this.isExpanded = false;
    }
    else {
      this.isMobile = false;
      this.isExpanded = true;
    }
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.router.navigateByUrl('/auth/login')
  }

  isExpanded = true;
  showSubmenu: boolean = false;
  showSubmenu1: boolean = false;
  showSubmenu2: boolean = false;
  showSubmenu3: boolean = false;
  showSubmenu4: boolean = false;
  showSubmenu5: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  showHotelSubmenu: boolean = false;
  showHotelVendorSubmenu: boolean = false;

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  golink(){
    // console.log("line 109 :::::::::",this.searchtext)
    if(this.searchtext != ""){
      // console.log("if undefined ")
      if(this.searchtext == undefined){
        this.searchtext="";
      }
      sessionStorage.setItem("sidebar_searchtext", JSON.stringify(this.searchtext))
      this.router.navigateByUrl('/dashboard/search-result');
    }
  }

  show_Website_setting(){
    $('#web_set').modal('show');
    var self = this;
    self.sharedService.get('v1/website_setting/view').subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.Enable_set_var=false;
        // console.log("res.data",res.data)
        this.WebSite_set_data=res.data;
        this.b2c_login=res.data.b2c_login;
        this.book_outside=res.data.book_outside;
        this.show_outside=res.data.show_outside;
        this.scroll_msg_inside=res.data.scroll_msg_inside;
        this.scroll_msg_outside=res.data.scroll_msg_outside;
        this.toastr.successToastr(res.replyMsg,'Success')
      }
    },err =>{
      this.Enable_set_var=true;
      // this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }

  show_reset_password(){
    $('#reset_pwd').modal('show');
    // var self = this;
    // self.sharedService.get('/v1/flight/reset_password').subscribe((res:any)=>{
    //   if(res.replyCode == 'success'){
    //     this.toastr.successToastr(res.replyMsg,'Success')
    //   }
    // },err =>{
    //   this.Enable_set_var=true;
    // })
  }

  b2c_onOff(event){ this.b2c_login=event }

  book_outside_onOff(event){  this.book_outside=event  }

  show_onOff(event){ this.show_outside=event }

  Edit_setting(){
    var self = this;
    var request={
      scroll_msg_outside: this.scroll_msg_outside,
      scroll_msg_inside: this.scroll_msg_inside,
      show_outside: this.show_outside,
      book_outside: this.book_outside,
      b2c_login: this.b2c_login
    }
    self.sharedService.post('v1/website_setting/edit',request).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg,'Success')
        $('#web_set').modal('hide');
      }
    },err =>{
      this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }

  Enable_set(){
    var self = this;
    self.sharedService.get('v1/website_setting/insertData').subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg,'Success')
      }
    },err =>{
      this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    this.isSticky = window.pageYOffset >= 20;
  };

  reset_password(){
    console.log(this.resetForm.invalid,"-----this.resetForm---364-*-*-");

    this.submitted = true;
    if (this.resetForm.invalid) {
        return;
    }else{
      // console.log(this.resetForm.value,"----this.resetForm.value-----367-*-*-*-*");
      // this.sharedService.post('v1/users/reset_password',this.resetForm.value).subscribe((res)=> {
      this.sharedService.post('v1/users/change_password',this.resetForm.value).subscribe((res)=> {
        if(res.replyCode == 'success'){
          this.resetForm.reset();
          this.submitted = false;
          $('#reset_pwd').modal('hide');
          this.toastr.successToastr(res.replyMsg, 'Success');
        }else{
          this.toastr.errorToastr(res.message, 'Error');
        }
      },err=>{
        if(err['error'].replyMsg[0].message){
          this.toastr.errorToastr(err['error'].replyMsg[0].message, 'Error');
        }else{  this.toastr.errorToastr(err['error'].replyMsg, 'Error');  }
      });
    }
  }


  checkPwd(){ this.show = !this.show; }



}

